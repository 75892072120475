import moment from 'moment';
import Formato from "./db/Formato.js";
import FormatoOffline from "./db/FormatoOffline.js";

var EntityUtils = {
    getMoment: function (fecha) {
        return moment(fecha, 'YYYY-MM-DD');
    },
    /**
     * Vamos a indicarle a la función auditar que debe evaluar las reglas de auditoría vinculadas a la acción indicada:
     * - 'sincronizar': se evaluarán todas las reglas de auditoría con evalOnSync == true
     * - 'finalizar': se evaluarán todas las reglas de auditoría con evalOnFinish == true
     * devolveremos true si el expediente cumple todas las reclas y false en caso contrario
     */
    cumpleReglas: function (accion, entity, offline, callback = (cumple)=>{return cumple;}) {
        // si accion no es ni 'sincronizar' ni 'finalizar', no hacemos nada
        if (accion != 'sincronizar' && accion != 'finalizar') {
            // meteremos en la lista de errores un error que diga que la acción no es válida
            let msg = 'La acción ' + accion + ' no es válida para evaluar reglas de auditoría';
            entity.listErrorsForLabel.push({ valor: 'Acción', errores: [{ nombre: 'Acción', error: msg }] });
            callback(false);
            return false;
        }
        if (!(entity.formato && entity.formato.id)) {
            let msg = 'No se puede auditar un expediente sin formato';
            entity.listErrorsForLabel.push({ valor: 'Acción', errores: [{ nombre: 'Acción', error: msg }] });
            app.dialog.alert("msg").open();
            callback(false);
            return false;
        }
        EntityUtils.removeAllErrors(entity);
        var dbAdapter = offline ? FormatoOffline : Formato;
        dbAdapter.get({ itemId: entity.formato.id }).then(function (res) {
            console.log('- Formato: ', res);
            if (res.activarReglasAuditorias != true) {
                callback(true);
                return;
            }
            entity.formatoOrigen = res;
            if(!entity.formatoOrigen.listadoCondicionesAuditoria) {
                entity.formatoOrigen.listadoCondicionesAuditoria = [];
            }
            entity.formato = JSON.parse(JSON.stringify(res));
            if(!entity.formato.listadoCondicionesAuditoria) {
                entity.formato.listadoCondicionesAuditoria = [];
            }

            // recopilamos todas las reglas.
            let reglas = [...entity.formato.listadoCondicionesAuditoria.filter(c => c.regla && c.activa).map(c => { c.regla.tipoRegla = c.tipoRegla; return c.regla; })];
            if (accion == 'sincronizar') {
                reglas = reglas.filter(c => !c.evalOnSync || c.evalOnSync == true);
            } else if (accion == 'finalizar') {
                reglas = reglas.filter(c => !c.evalOnFinish || c.evalOnFinish == true);
            }
            // si no hay reglas que evaluar, terminamos con true
            if (reglas.length == 0) {
                callback(true);
            } else {
                entity.listErrorsForLabel = [];
                // delay(500);
                // vamos a evaluar las reglas
                let respuesta = EntityUtils.evaluarReglas(reglas, entity);
                callback(respuesta);
            }
        }).catch(function (err) {
            console.log(err);
            callback(true);
        });
    },
    // devuelve true si no hay errores y false en caso contrario
    evaluarReglas(reglasAfectadas, entity, caracteristica, tipo) {
        const caracts = EntityUtils.getUltimasHijas(entity.caracteristicas);
        const compros = EntityUtils.getUltimasHijas(entity.comprobaciones);
        if (caracteristica && !caracteristica.erroresAuditoria) {
            caracteristica.erroresAuditoria=[];
        }
        if (!entity.listErrorsForLabel) {
            entity.listErrorsForLabel=[];
        }
        // entity se usará para evaluar las reglas
        const expediente = JSON.parse(JSON.stringify(entity));
        expediente.caracteristicas = caracts;
        expediente.comprobaciones = compros;
        let erroresValor = { errores: [] };
        if (tipo && tipo == 'VALOR' && caracteristica) {
            erroresValor = entity.listErrorsForLabel.filter(err => err.valor == caracteristica.nombre)[0];
            if (!erroresValor) {
                entity.listErrorsForLabel.push({ valor: caracteristica.nombre, errores: [] });
                erroresValor = entity.listErrorsForLabel.filter(err => err.valor == caracteristica.nombre)[0];
            }
            if (!erroresValor.errores) {
                erroresValor.errores = [];
            }
        }
        let hayErrores = false;
        reglasAfectadas.forEach(c => {
            const caractsErrores = [
                ...caracts.filter(c2 => c2.erroresAuditoria && c2.erroresAuditoria.find(e => e.nombre == c.numeroRegla)),
                ...compros.filter(c2 => c2.erroresAuditoria && c2.erroresAuditoria.find(e => e.nombre == c.numeroRegla)),
                ...entity.listErrorsForLabel.filter(e => e.errores && e.errores.find(e2 => e2.nombre == c.numeroRegla))
            ];
            const condition = c.regla;
            let error = { nombre: c.numeroRegla };
            try {
                const res = eval(condition);
                if (typeof res != 'boolean' || !res) {
                    hayErrores = true;
                    error.error = res;
                    // vamos a añadir el error a las características afectadas y en caso de estar yá asociado, lo actualizamos
                    c.caracteristicasAfectadas.forEach(caaf => {
                        let ca2 = caracts.find(ca => ca.nombre == caaf);
                        EntityUtils.addErrorAuditoria(ca2, error, false, entity);
                    });
                    // vamos a añadir el error a las comprobaciones afectadas y en caso de estar yá asociado, lo actualizamos
                    c.comprobacionesAfectadas.forEach(coaf => {
                        let co2 = compros.find(co => co.codigo == coaf);
                        EntityUtils.addErrorAuditoria(co2, error, false, entity);
                    });
                    // vamos a añadir el error a los valores afectados y en caso de estar yá asociado, lo actualizamos
                    c.valoresAfectados.forEach(vaaf => {
                        let va2 = EntityUtils.getValoresAfectados(entity.formato).find(v => v.nombre == vaaf);
                        EntityUtils.addErrorAuditoria(va2, error, true, entity);
                    });
                } else if (caracteristica) {
                    EntityUtils.removeErrors(error, caracteristica, caractsErrores, entity);
                }
            } catch (e) {
                console.log(c.numeroRegla + ' - ' + c.regla, e);
                c.error = JSON.stringify(e.stack).replaceAll('\\n', '<br/>');
                // Actualizaremos datos de la regla actual también en  formatoOrigen
                let ro = entity.formatoOrigen.listadoCondicionesAuditoria.find(co => co.numero == c.numeroRegla);
                if (ro) {
                    try { ro.regla.error = JSON.stringify(e.stack).replaceAll('\\n', '<br/>') } catch (e1) { }
                    try { ro.regla.error = e } catch (e1) { }
                }
                if (caracteristica) {
                    EntityUtils.removeErrors(error, caracteristica, caractsErrores);
                }
            }
        });
        return !hayErrores;
    },
    addErrorAuditoria(ca2, error, esValor = false, entity) {
        if (ca2) {
            let tipoRegla = (error && error.nombre && entity.formato.listadoCondicionesAuditoria.find(c=>c.numero == error.nombre)) ? entity.formato.listadoCondicionesAuditoria.find(c=>c.numero == error.nombre).tipoRegla : '';
            let errObjeto = { nombre: error.nombre, error: error.error, tipoRegla: tipoRegla };
            if (esValor) {
                let errV = entity.listErrorsForLabel.filter(e => e.valor == ca2.nombre)[0];
                if (!errV) {
                    entity.listErrorsForLabel.push({ valor: ca2.nombre, errores: [] });
                    errV = entity.listErrorsForLabel.filter(e => e.valor == ca2.nombre)[0];
                }
                const error2 = errV.errores.filter(e => e.nombre == error.nombre)[0];
                if (!error2) {
                    errV.errores.push(errObjeto);
                }
            } else {
                if (!ca2.erroresAuditoria) {
                    ca2.erroresAuditoria=[];
                }
                if (!ca2.listErrorsForLabel) {
                    ca2.listErrorsForLabel=[];
                }
                const error2 = ca2.erroresAuditoria.filter(e => e.nombre == error.nombre)[0];
                if (!error2) {
                    ca2.erroresAuditoria.push(errObjeto);
                }
                const error2_1 = ca2.listErrorsForLabel.filter(e => e.nombre == error.nombre)[0];
                if (!error2_1) {
                    ca2.listErrorsForLabel.push(errObjeto);
                }
            }
        }
    },
    removeErrors(error, caracteristica, caractsErrores, entity) {
        if (error) {
            let idx = caracteristica.erroresAuditoria.map(e => e.nombre).indexOf(error.nombre);
            if (idx > -1) {
                caracteristica.erroresAuditoria.splice(idx, 1);
            } else {
                let error2 = entity.erroresAuditoria.filter(e => e.valor == error.nombre).forEach(e => {
                    let idxE = e.errores.indexOf(error);
                    if (idxE > -1) {
                        e.errores.splice(idxE, 1);
                    }
                });
            }
            idx = caracteristica.listErrorsForLabel.map(e => e.nombre).indexOf(error.nombre);
            if (idx > -1) {
                caracteristica.listErrorsForLabel.splice(idx, 1);
            } else {
                let error2 = entity.listErrorsForLabel.filter(e => e.valor == error.nombre).forEach(e => {
                    let idxE = e.errores.indexOf(error);
                    if (idxE > -1) {
                        e.errores.splice(idxE, 1);
                    }
                });
            }
        }
        (caractsErrores || []).forEach(cError => {
            if (cError.erroresAuditoria) {
                const err = cError.erroresAuditoria.find(e => e.nombre == (error ? error.nombre : error.nombre));
                if (err) {
                    cError.erroresAuditoria.splice(cError.erroresAuditoria.indexOf(err), 1);
                }
                const err1 = cError.listErrorsForLabel.find(e => e.nombre == (error ? error.nombre : error.nombre));
                if (err1) {
                    cError.listErrorsForLabel.splice(cError.listErrorsForLabel.indexOf(err1), 1);
                }
            } else if (cError.errores) {
                const err = cError.errores.find(e => e.nombre == (error ? error.nombre : error.nombre));
                if (err) {
                    cError.errores.splice(cError.errores.indexOf(err), 1);
                }
            }
        });
    },
    removeAllErrors(entity) {
        // borramos la lista de errores de los valores
        entity.listErrorsForLabel = [];
        // borramos la lista de errores de las características
        EntityUtils.getUltimasHijas(entity.caracteristicas).forEach(c => {
            c.erroresAuditoria = [];
            c.listErrorsForLabel = [];
        });
        // borramos la lista de errores de las comprobaciones
        EntityUtils.getUltimasHijas(entity.comprobaciones).forEach(c => {
            c.erroresAuditoria = [];
            c.listErrorsForLabel = [];
        });
    },
    getUltimasHijas: function (coleccion) {
        let nodosHoja = [];
        coleccion.forEach(c => {
            if (c.hijas && c.hijas.length) {
                const hijas = EntityUtils.getUltimasHijas(c.hijas);
                nodosHoja = nodosHoja.concat(hijas);
            } else {
                nodosHoja.push(c);
            }
        })
        return nodosHoja;
    },
    getCaracteristicasAfectadas: function (formato) {
        let caracteristicas = new Set(formato.listadoCondicionesAuditoria.filter(c => c.regla).flatMap(c => c.regla.caracteristicasAfectadas));
        // Convwertir a objeto
        caracteristicas = [...caracteristicas].map(v => {
            return { nombre: v };
        });
        return caracteristicas;
    },
    getComprobacionesAfectadas: function (formato) {
        let comprobaciones = new Set(formato.listadoCondicionesAuditoria.filter(c => c.regla).flatMap(c => c.regla.comprobacionesAfectadas));
        // Convwertir a objeto
        comprobaciones = [...comprobaciones].map(v => {
            return { nombre: v };
        });
        return comprobaciones;
    },
    getValoresAfectados: function (formato) {
        let valores = new Set(formato.listadoCondicionesAuditoria.filter(c => c.regla).flatMap(c => c.regla.valoresAfectados));
        // Convwertir a objeto
        valores = [...valores].map(v => {
            return { nombre: v };
        });
        return valores;
    },
    formateoError(entity, nombreCampo) {
        let errorHTML;
        if(entity.listErrorsForLabel) {
            errorHTML = entity.listErrorsForLabel.filter(e => e.valor == nombreCampo)
                .flatMap(e => e.errores)
                .map(e => '<span class="regla-' + e.tipoRegla + '">' + e.nombre + '.- ' + e.error + '</span>').join(' ');
        }
        return errorHTML;
    },
    tooltipError(entity, nombreCampo) {
        let errorTxt = '';
        if(entity.listErrorsForLabel) {
            errorTxt = entity.listErrorsForLabel.filter(e => e.valor == nombreCampo)
                .flatMap(e => e.errores)
                .map(e => e.nombre + '.- ' + e.error).join('\n');
        }
    }
}

export default EntityUtils;