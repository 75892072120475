<template>
    <f7-popup ref="popupprobarauditoria" tablet-fullscreen :opened="true" @popup:closed="onClose">
        <f7-page>
            <f7-navbar>
                <f7-nav-left>
                    <f7-link @click="predecir">{{ $t('Predecir') }}</f7-link>
                </f7-nav-left>
                <f7-nav-title>
                    {{$t('Probar auditoría')}}
                </f7-nav-title>
                <f7-nav-right>
                    <f7-link popup-close>{{$t('cerrar')}}</f7-link>
                </f7-nav-right>
                <f7-subnavbar>
                    <f7-segmented raised class="elevation-6">
                        <f7-button fill tab-link="#tab-instalacion" tab-link-active @click="tabActive = 'Instalacion';">Instalación</f7-button>
                        <f7-button fill tab-link="#tab-comprobaciones" @click="tabActive = 'TomaDatos';">Toma de datos</f7-button>
                        <f7-button fill tab-link="#tab-valores" @click="tabActive = 'Valores';">Valores</f7-button>
                    </f7-segmented>
                </f7-subnavbar>
            </f7-navbar>

            <r-form
                v-if="formato"
                ref="rform"
                :readonly="false"
                class="page"
                classContent="full-height page-content"
                :initialData="formato"
            >
                <template v-slot:default="{ rform }">
                    <f7-tab  v-if="tabActive == 'Instalacion'" id="tab-instalacion" tab-active class="page-content">
                        <!-- Características -->
                        <div
                            class="textoAzul w-99"
                            v-for="(caracteristica, ic) in formato.caracteristicas"
                            :key="'caracteristica-' + caracteristica.nombre + '-' + ic"
                        >
                            <caracteristica
                                :key="'caract-' + caracteristica.nombre + '-' + ic"
                                :tipo="caracteristica.tipo"
                                :caracteristica="caracteristica"
                                :form="rform"
                                :label="caracteristica.label"
                                :name="'caracteristicas.' + ic"
                                :allCaracteristicas="formato.caracteristicas"
                                @change="caracteristicaModificada($event, 'CARACTERISTICA')"
                            ></caracteristica>
                        </div>
                    </f7-tab>
                    <f7-tab v-if="tabActive == 'TomaDatos'" tab-active id="tab-comprobaciones" class="page-content">
                        <!-- Comprobaciones -->
                        <f7-list accordion-list>
                            <comprobacion
                                v-for="(comprobacion, ic) in formato.comprobaciones"
                                :key="'comprobaciones.' + comprobacion.codigo"
                                :comprobacion="comprobacion"
                                :name="'comprobaciones.' + ic"
                                :referencia="'comprobaciones_' + ic"
                                :form="rform"
                                :resultadoDefecto="comprobacion.valorInicial"
                                @changeChild="caracteristicaModificada($event, 'COMPROBACION')"
                            ></comprobacion>
                        </f7-list>
                    </f7-tab>
                    <f7-tab v-if="tabActive == 'Valores'" tab-active id="tab-valores" class="page-content">
                        <!-- Valores -->
                        <f7-list accordion-list>
                            <div v-for="(valor, ic) in getValoresAfectados(formato)" :key="'valores.' + ic">
                                <r-input
                                    outline
                                    :form="rform"
                                    :name="'valores.' + valor.nombre"
                                    @change="caracteristicaModificada({ nombre: valor.nombre, valor: $event}, 'VALOR')"
                                >
                                <slot name="label" slot="label">
                                    {{ 'Valor ' + valor.nombre }}
                                    <!-- {{ formato.erroresAuditoria }} -->
                                    <div v-tooltip="formato.erroresAuditoria.filter(e => e.valor == valor.nombre).flatMap(e => e.errores).map(e =>e.nombre + '.- ' + e.error).join('<br/>')" v-if="formato.erroresAuditoria && formato.erroresAuditoria.filter(e => e.valor == valor.nombre) && formato.erroresAuditoria.filter(e => e.valor == valor.nombre).length" v-html="formateoError(formato, valor)"></div>
                                </slot>
                                </r-input>
                            </div>
                        </f7-list>
                    </f7-tab>
                </template>
            </r-form>
        </f7-page>
    </f7-popup>
</template>

<style scoped>
</style>
<style>
</style>

<script>
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import Caracteristica from "./../inspecciones/Caracteristica.vue";
import Comprobacion from "./../inspecciones/Comprobacion.vue";
import Formato from "./../../js/db/Formato.js";
import moment from 'moment';

export default {
    components: {
        rForm,
        rInput,
        Caracteristica,
        Comprobacion
    },
    data: function () {
        var self = this;
        return {
            Formato,
            tabActive: 'Instalacion',
            formato: null,
            moment
        };
    },
    props: {
        formatoOrigen: Object
    },
    computed: {},
    mounted() {
        const self = this;
        self.formato = JSON.parse(JSON.stringify(self.formatoOrigen));
        self.getUltimasHijas(self.formato.caracteristicas).forEach(c => {
            if (!c.erroresAuditoria) {
                self.$set(c, 'erroresAuditoria', []);
            }
            if (c.valor == null || c.valor == undefined) {
                self.$set(c, 'valor', '');
            }
        })
        // recopilamos todas las reglas.
        let reglas = [ ...self.formato.listadoCondicionesAuditoria.filter(c=> c.regla && c.activa).map(c => {c.regla.tipoRegla = c.tipoRegla; return c.regla;})];
        self.evaluarReglas(reglas);
    },
    beforeDestroy() { },
    methods: {
        predecir: function () {
            const self = this;
            const caracts = self.getUltimasHijas(self.formato.caracteristicas);
            caracts.forEach(c => self.$set(c, 'valorPredicho', ''));
            let datos = {
                "formato": self.formato.id
            }
            caracts.filter(c => c.valor).forEach(c => {
                datos[c.nombre] = c.valor;
            });
            Formato.predecir(self.formato.id, datos)
                .then(res => {
                    app.dialog.alert("predicho");
                    console.log("predicho", res);
                    Object.keys(res).forEach(k => {
                        caracts.filter(c => !datos[k] && c.nombre == k).forEach(c => c.valorPredicho = res[k]);
                    });
                })
                .catch(function (error) {
                    if (error.message) {
                        app.dialog.alert(error.message);
                    } else {
                        app.dialog.alert(error);
                    }
                })
                .then(_ => app.preloader.hide());
        },
        onClose: function () {
            const self = this;
            self.$emit('close');
        },
        caracteristicaModificada(caracteristica, tipo) {
            const self = this;
            console.log(tipo + " modif", caracteristica);
            if (self.formato.listadoCondicionesAuditoria) {
                let reglasAfectadas = [];
                if (tipo == 'CARACTERISTICA') {
                    reglasAfectadas = [
                        ...self.formato.listadoCondicionesAuditoria.filter(c=> c.regla && c.activa && c.regla.caracteristicasAfectadas && c.regla.caracteristicasAfectadas.includes(caracteristica.nombre)).map(c => c.regla),
                    ];
                } else if (tipo == 'COMPROBACION') {
                    reglasAfectadas = [
                        ...self.formato.listadoCondicionesAuditoria.filter(c=> c.regla && c.activa && c.regla.comprobacionesAfectadas && c.regla.comprobacionesAfectadas.includes(caracteristica.codigo)).map(c => c.regla),
                    ];
                } else if (tipo == 'VALOR') {
                    reglasAfectadas = [
                        ...self.formato.listadoCondicionesAuditoria.filter(c=> c.regla && c.activa && c.regla.valoresAfectados && c.regla.valoresAfectados.includes(caracteristica.nombre)).map(c => c.regla),
                    ];
                }
               self.evaluarReglas(reglasAfectadas, caracteristica, tipo);
            }
        },
        evaluarReglas(reglasAfectadas, caracteristica, tipo) {
            const self = this;
            const caracts = self.getUltimasHijas(self.formato.caracteristicas);
            const compros = self.getUltimasHijas(self.formato.comprobaciones);
            if (caracteristica && !caracteristica.erroresAuditoria) {
                self.$set(caracteristica, 'erroresAuditoria', []);
            }
            if (!self.formato.erroresAuditoria) {
                self.$set(self.formato, 'erroresAuditoria', []);
            }
            // expediente se usará para evaluar las reglas
            const expediente = {
                ...(self.$refs.rform?.formData?.valores || self.getValoresAfectados(self.formato)),
                caracteristicas: caracts,
                comprobaciones: compros
            };
            let erroresValor = { errores: [] };
            if (tipo && tipo == 'VALOR' && caracteristica) {
                erroresValor = self.formato.erroresAuditoria.filter(err => err.valor == caracteristica.nombre)[0];
                if (!erroresValor) {
                    self.formato.erroresAuditoria.push({ valor: caracteristica.nombre, errores: [] });
                    erroresValor = self.formato.erroresAuditoria.filter(err => err.valor == caracteristica.nombre)[0];
                }
                if (!erroresValor.errores) {
                    erroresValor.errores = [];
                }
            }
            reglasAfectadas.forEach(c => {
                const caractsErrores = [
                    ...caracts.filter(c2 => c2.erroresAuditoria && c2.erroresAuditoria.find(e => e.nombre == c.numeroRegla)),
                    ...compros.filter(c2 => c2.erroresAuditoria && c2.erroresAuditoria.find(e => e.nombre == c.numeroRegla)),
                    ...self.formato.erroresAuditoria.filter(e => e.errores && e.errores.find(e2 => e2.nombre == c.numeroRegla))
                ];
                const condition = c.regla;
                let error = {nombre: c.numeroRegla };
                try {
                    const res = eval(condition);
                    if (typeof res != 'boolean' || !res) {
                        error.error = res;
                        // vamos a añadir el error a las características afectadas y en caso de estar yá asociado, lo actualizamos
                        c.caracteristicasAfectadas.forEach(caaf => {
                            let ca2 = caracts.find(ca => ca.nombre == caaf);
                            self.addErrorAuditoria(ca2, error, false);
                        });
                        // vamos a añadir el error a las comprobaciones afectadas y en caso de estar yá asociado, lo actualizamos
                        c.comprobacionesAfectadas.forEach(coaf => {
                            let co2 = compros.find(co => co.codigo == coaf);
                            self.addErrorAuditoria(co2, error, false);
                        });
                        // vamos a añadir el error a los valores afectados y en caso de estar yá asociado, lo actualizamos
                        c.valoresAfectados.forEach(vaaf => {
                            let va2 = self.getValoresAfectados(self.formato).find(v => v.nombre == vaaf);
                            self.addErrorAuditoria(va2, error, true);
                        });
                    } else if (caracteristica){
                        self.removeErrors(error, caracteristica, caractsErrores);
                    }
                } catch (e) {
                    console.log(c.numeroRegla + ' - ' + c.regla, e);
                    c.error = JSON.stringify(e.stack).replaceAll('\\n','<br/>');
                    // Actualizaremos datos de la regla actual también en  formatoOrigen
                    let ro = self.formatoOrigen.listadoCondicionesAuditoria.find(co => co.numero == c.numeroRegla);
                    if (ro) {
                        try{ro.regla.error = JSON.stringify(e.stack).replaceAll('\\n','<br/>')}catch(e1){}
                        try{ro.regla.error = e}catch(e1){}
                    }
                    if (caracteristica) {
                        self.removeErrors(error, caracteristica, caractsErrores);
                    }
                }
            });
        },
        addErrorAuditoria(ca2, error, esValor = false) {
            const self = this;
            if (ca2) {
                let tipoRegla = (error && error.nombre && self.formato.listadoCondicionesAuditoria.find(c=>c.numero == error.nombre))?self.formato.listadoCondicionesAuditoria.find(c=>c.numero == error.nombre).tipoRegla:'';
                if (esValor) {
                    let errV = self.formato.erroresAuditoria.filter(e => e.valor == ca2.nombre)[0];
                    if (!errV) {
                        self.formato.erroresAuditoria.push({ valor: ca2.nombre, errores: [] });
                        errV = self.formato.erroresAuditoria.filter(e => e.valor == ca2.nombre)[0];
                    }
                    const error2 = errV.errores.filter(e => e.nombre == error.nombre)[0];
                    if (!error2) {
                        errV.errores.push({ nombre: error.nombre, error: error.error, tipoRegla: tipoRegla });
                    }

                } else {
                    if (!ca2.erroresAuditoria) {
                        self.$set(ca2, 'erroresAuditoria', []);
                    }
                    const error2 = ca2.erroresAuditoria.filter(e => e.nombre == error.nombre)[0];
                    if (!error2) {
                        ca2.erroresAuditoria.push({ nombre: error.nombre, error: error.error, tipoRegla: tipoRegla });
                    }
                }
            }
        },
        removeErrors(error, caracteristica, caractsErrores) {
            const self = this;
            if (error) {
                let idx = caracteristica.erroresAuditoria.map(e => e.nombre).indexOf(error.nombre);
                if (idx > -1) {
                    caracteristica.erroresAuditoria.splice(idx, 1);
                } else {
                    let error2 = self.formato.erroresAuditoria.filter(e => e.valor == error.nombre).forEach(e => {
                        let idxE = e.errores.indexOf(error);
                        if (idxE > -1) {
                            e.errores.splice(idxE, 1);
                        }
                    });
                }
            }
            (caractsErrores || []).forEach(cError => {
                if (cError.erroresAuditoria) {
                    const err = cError.erroresAuditoria.find(e => e.nombre == (error ? error.nombre : error.nombre));
                    if (err) {
                        cError.erroresAuditoria.splice(cError.erroresAuditoria.indexOf(err), 1);
                    }
                } else if (cError.errores) {
                    const err = cError.errores.find(e => e.nombre == (error ? error.nombre : error.nombre));
                    if (err) {
                        cError.errores.splice(cError.errores.indexOf(err), 1);
                    }
                }
            });
        },
        getUltimasHijas: function (coleccion) {
            const self = this;
            let caracts = [];
            coleccion.forEach(c => {
                if (c.hijas && c.hijas.length) {
                    const hijas = self.getUltimasHijas(c.hijas);
                    caracts = caracts.concat(hijas);
                } else {
                    caracts.push(c);
                }
            })
            return caracts;
        },
        getCaracteristicasAfectadas: function (formato) {
            const self = this;
            let caracteristicas = new Set(formato.listadoCondicionesAuditoria.filter(c => c.regla).flatMap(c => c.regla.caracteristicasAfectadas));
            // Convwertir a objeto
            caracteristicas = [...caracteristicas].map(v => {
                return { nombre: v };
            });
            return caracteristicas;
        },
        getComprobacionesAfectadas: function (formato) {
            const self = this;
            let comprobaciones = new Set(formato.listadoCondicionesAuditoria.filter(c => c.regla).flatMap(c => c.regla.comprobacionesAfectadas));
            // Convwertir a objeto
            comprobaciones = [...comprobaciones].map(v => {
                return { nombre: v };
            });
            return comprobaciones;
        },
        getValoresAfectados: function (formato) {
            const self = this;
            let valores = new Set(formato.listadoCondicionesAuditoria.filter(c => c.regla).flatMap(c => c.regla.valoresAfectados));
            // Convwertir a objeto
            valores = [...valores].map(v => {
                return { nombre: v };
            });
            return valores;
        },
        formateoError(formato, valor) {
            let errorHTML = formato.erroresAuditoria.filter(e => e.valor == valor.nombre)
                                .flatMap(e => e.errores)
                                .map(e => '<span class="regla-'+e.tipoRegla+'">'+e.nombre + '.- ' + e.error+'</span>').join(' ');
            return errorHTML;
        }
    }
}
</script>
