<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('common.editar')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>Fichajes</f7-nav-title>
      <f7-nav-title v-else sliding>{{ $t('common.nuevo')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
        <template slot="right"  v-else>
          <f7-button v-if="itemCargado && !itemCargado.eliminado && Utils.getUser() && Utils.getUser().username == itemCargado.username" color="blue" @click="readonly=false">{{ $t('common.editar') }}</f7-button>
          <f7-button v-if="itemCargado && !itemCargado.eliminado && Utils.getUser() && Utils.getUser().username == itemCargado.username" color="red" @click="deleteItem">{{ $t('common.eliminar') }}</f7-button>
        </template>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      :readonly="readonly"
      :dbAdapter="Fichaje"
      @loadItem="loadItem"
    >
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="50">
              <r-input
                class="col-100"
                floating-label
                label="Empleado"
                :form="rform"
                type="text"
                name="username"
                :readonly="true"
                :overrideReadonly="true"
              ></r-input>
              <r-input
                class="col-100"
                floating-label
                label="Tipo"
                :form="rform"
                type="text"
                name="tipoFichaje"
                :readonly="true"
                :overrideReadonly="true"
              ></r-input>
              <r-input
                class="col-100"
                floating-label
                label="Fecha hora"
                :form="rform"
                :name="'fechaHora'"
                type="datetime-local"
                :calendar-params="{ timePickerPlaceholder: 'Seleccionar hora', toolbarCloseText: 'Hecho', closeOnSelect: true, timePicker: true, routableModals: false, dateFormat: { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' } }"
                :ignorePreviousVal="true"
                :formatDate="'DD/MM/YYYY HH:mm'"
              ></r-input>
              <r-input
                class="col-100"
                floating-label
                label="Localización"
                :form="rform"
                type="text"
                name="location.direccion"
                :readonly="true"
                :overrideReadonly="true"
              ></r-input>
            </f7-col>
            <f7-col width="50">
              <iframe
                v-if="rform.formData.location && rform.formData.location.latitud && rform.formData.location.longitud"
                :src="'https://maps.google.com/maps?q=' + rform.formData.location.latitud + ',' + rform.formData.location.longitud + '&z=15&output=embed'"
                width="95%"
                height="300"
                frameborder="0"
                style="border:0; margin: 20px;"
                allowfullscreen
              ></iframe>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
    </r-form>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }

</style>
<script>
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import Fichaje from "./../../js/db/Fichaje.js";
import validator from "validator";
import Utils from "../../js/Utils.js";

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rFiles,
    rAutocomplete
  },
  data: function() {
    var self = this;
    return {
      Fichaje,
      Utils,
      readonly: !this.editable,
      validator,
      itemCargado: false
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    loadItem: function(res) {
      const self = this;
      self.itemCargado = res;
    },
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        'Si elimina un registro, se eliminará el rango completo de Entrada y Salida, ¿esta seguro?',
        "Eliminar",
        function() {
          Fichaje.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          });
        }
      );
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("Fichaje actualizado correctamente"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    recalcularNombre: function(){
      const self = this,
        formData = self.$refs.rform.formData,
        cuenta = formData.cuenta,
        plazoDias = formData.plazoDias,
        viaPagoCodigo = (formData.viaPago) ? formData.viaPago.codigo : null,
        viasPagoNombreArr = viaPagoCodigo ? self.$refs.viasPago.items.filter(via => via.codigo == viaPagoCodigo).map(via => via.nombre) : [],
        viaPagoNombre = viasPagoNombreArr.length ? viasPagoNombreArr[0] : null;
      var nombre = (viaPagoNombre) ? viaPagoNombre : "";
      if (cuenta) {
        nombre = `${nombre} en ${cuenta}`;
      }
      if (plazoDias) {
        nombre = `${nombre} a ${plazoDias} días`;
      }
      self.$set(formData, "nombre", nombre);
    }
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>