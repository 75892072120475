<template>
  <f7-app :params="f7params" :class="{ 'bg-transparent': cameraOpened, 'bg-white': !cameraOpened}">
    <f7-appbar>
      <div class="left">
        <f7-button small panel-toggle="left" class="display-flex" icon-f7="bars"></f7-button>
        <a href="/">
          <img src="static/img/logo.png" style="max-height: 1.6rem;"  />
        </a>
        <f7-button
            v-if="rutaCalendario"
            style="margin-left: 20px;color: #22355b;"
            fill
            icon-f7="clock"
            color="orange"
            popup-open=".popup-fichaje"
            @click="fichajeRango = false"
          > Fichar </f7-button>
        <f7-button
            v-if="rutaCalendario"
            style="margin-left: 20px;color: #22355b;"
            fill
            icon-f7="clock"
            color="orange"
            popup-open=".popup-fichaje"
            @click="fichajeRango = true"
          > Fichar Rango</f7-button>
      </div>

      <div class="right">
        <f7-button v-if="target != 'cordova'" icon-f7="search" @click="$refs.inputBusquedaGlobal.focus()"></f7-button>
        <input v-if="target != 'cordova'" ref="inputBusquedaGlobal" class="input-busqueda-global" v-model="textoBusquedaGlobal" @keyup.enter="search" type="text"/>
        <f7-button
          small
          class="display-flex"
          icon-f7="lightbulb"
          popup-open=".popup-sugerencia"
        >Sugerencia de Mejora</f7-button>
        <f7-button
          small
          class="display-flex"
          icon-f7="question_circle_fill"
          @click="popupAyudaOpened=true"
        ></f7-button>
      </div>
      <f7-popup ref="popupBusquedaGlobal" class="popup-sugerencia" swipe-to-close>
        <f7-page>
          <f7-navbar title="Búsqueda global">
            <f7-nav-right>
              <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <div>
                <h3 class="global-search-title">Clientes</h3>
                <ul class="search-result-list">
                  <li v-for="(cliente,i) in busquedaGlobal.clientes" :key="'cliente'+i" @click="navega('cliente', cliente)">[{{cliente.codigo}}] {{cliente.razonSocial}} - {{cliente.activo ? 'Activo' : 'No activo'}}</li>
                </ul>
              </div>
              <div>
                <h3 class="global-search-title">Ofertas</h3>
                <ul class="search-result-list">
                  <li v-for="(oferta,i) in busquedaGlobal.ofertas" :key="'oferta'+i" @click="navega('oferta', oferta)">{{oferta.numero}} - [{{oferta.cliente.codigo}}] ({{oferta.cliente.razonSocial}}) - {{oferta.fechaOferta | dateParse | dateFormat('DD/MM/YYYY') }} - {{oferta.totalSinIVASinTasasConDescuentoConTerceros | numberParse | numberFormat(2)}} € </li>
                </ul>
              </div>
              <div>
                <h3 class="global-search-title">Pedidos</h3>
                <ul class="search-result-list">
                  <li v-for="(pedido,i) in busquedaGlobal.pedidos" :key="'oferta'+i" @click="navega('pedido', pedido)">{{pedido.numero}} - [{{pedido.cliente.codigo}}] ({{pedido.cliente.razonSocial}}) - {{pedido.fechaContratacion | dateParse | dateFormat('DD/MM/YYYY') }} - {{pedido.totalSinIVASinTasasConDescuentoConTerceros | numberParse | numberFormat(2)}} €</li>
                </ul>
              </div>
            </f7-card-content>
          </f7-card>
        </f7-page>
      </f7-popup>
      <f7-popup v-if="popupAyudaOpened" :opened="true" @popup:closed="popupAyudaOpened = false">
        <f7-page>
          <f7-navbar title="Ayuda">
            <f7-nav-right>
              <f7-link popup-close icon-f7="multiply"></f7-link>
            </f7-nav-right>
          </f7-navbar>
          <div class="list no-hairlines no-margin full-height">
            <ul class="full-height">
              <li class="item-content item-input full-height">
                <div class="item-inner full-height">
                  <div class="item-input-wrap full-height">
                    Si necesita ayuda o tiene cualquier problema con el uso de la aplicación puede contactar con:
                    <br />
                    <br />
                    <f7-link
                      href="mailto:soporte@simecal.com"
                      class="justify-left"
                      external
                    >soporte@simecal.com</f7-link>
                    <!-- <f7-link href="tel:+34918326123" class="justify-left" external>918326123</f7-link> -->
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </f7-page>
      </f7-popup>
      <f7-popup ref="popupSugerencia" class="popup-sugerencia" swipe-to-close>
        <f7-page>
          <f7-navbar title="Sugerencia de Mejora">
            <f7-nav-right>
              <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <textarea
                floating-label
                placeholder="Sugerencia"
                name="sugerenciaMejora"
                ref="sugerenciaMejora"
                class="padding-left margin-right"
              ></textarea>
            </f7-card-content>
            <f7-button
              fill
              icon-f7="paperplane"
              text="Enviar"
              class="margin-left display-flex"
              color="blue"
              @click="sendSugerencia"
            ></f7-button>
          </f7-card>
          <!-- </div> -->
        </f7-page>
      </f7-popup>

      <f7-popup :close-by-backdrop-click="false" ref="popupFichaje" class="popup-fichaje" @popup:opened="prepararFichaje" @popup:closed="newFichaje = undefined; lastFichaje = undefined">
        <f7-page>
          <f7-navbar>
            <f7-nav-left v-if="newFichaje && newFichaje.fechaHora">
              <f7-link @click="guardarFichaje">Guardar</f7-link>
            </f7-nav-left>
            <f7-nav-title>Fichaje</f7-nav-title>
            <f7-nav-right>
              <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <table width="100%">
                <tr v-if="lastFichaje">
                  <template v-if="!fichajeRango">
                    <td><h3 class="global-search-title">Anterior</h3></td>
                    <td>{{lastFichaje.tipoFichaje}}</td>
                    <td>{{lastFichaje.fechaHora | dateParse | dateFormat('DD/MM/YYYY HH:mm')}}</td>
                    <td>{{lastFichaje.comentario}}</td>
                    <td style="text-align: right;">{{lastFichaje.tipoFichaje=='SALIDA'?DateUtils.getDurationInHoursAndMinutes(lastFichaje.duracion):''}}</td>
                  </template>
                  <template v-else>
                    <td><h3 class="global-search-title">Entrada</h3></td>
                    <td>{{lastFichaje.tipoFichaje}}</td>
                    <td>
                      <!-- :value="newFichaje.fechaHora" -->
                      <f7-list-input
                        v-if="lastFichaje && lastFichaje.fechaHora"
                        v-bind="{ ...$attrs, ...$props }"
                        v-on="$listeners"
                        style="list-style-type: none;"
                        type="datetime-local"
                        :value="lastFichaje.fechaHora"
                        @input="changeDate($event, 'lastFichaje')"
                        :min="getMinDate()"
                      ></f7-list-input>
                    </td>
                    <td>
                      <input class="input-fichaje" v-model="lastFichaje.comentario" type="text"/>
                    </td>
                  </template>
                </tr>
                <tr v-if="newFichaje">
                  <td v-if="!fichajeRango" class="global-search-title"><h3>Nuevo</h3></td>
                  <td v-else class="global-search-title"><h3>Salida</h3></td>
                  <td>{{newFichaje.tipoFichaje}}</td>
                  <td>
                    <!-- :value="newFichaje.fechaHora" -->
                    <f7-list-input
                      v-if="newFichaje && newFichaje.fechaHora"
                      v-bind="{...$attrs,...$props}"
                      v-on="$listeners"
                      style="list-style-type: none;"
                      type="datetime-local"
                      :value="newFichaje.fechaHora"
                      @input="changeDate($event, 'newFichaje')"
                      :min="getMinDate()"
                    ></f7-list-input>
                  </td>
                  <td>
                    <input class="input-fichaje" v-model="newFichaje.comentario" type="text"/>
                  </td>
                  <td style="text-align: right;">
                    {{ newFichaje.tipoFichaje=='SALIDA'? getDuracion() : ''}}
                  </td>
                </tr>
              </table>
            </f7-card-content>
          </f7-card>
        </f7-page>
      </f7-popup>

    </f7-appbar>
    <!-- Left panel with cover effect-->
    <f7-panel left cover :visibleBreakpoint="1024">
      <f7-view class="view-left" :init="false"></f7-view>
    </f7-panel>

    <!-- Your main view, should have "view-main" class -->
    <f7-view v-show="!cameraOpened" main class="safe-areas" url="/" :init="false"></f7-view>
    <div v-show="cameraOpened" class="controlesCamara">
      <f7-link @click="cerrarCamara" icon-f7="xmark_circle_fill"></f7-link>
      <f7-link v-show="cameraPhoto" ref="botonFoto" icon-f7="camera"></f7-link>
      <f7-link v-show="!cameraRecording && !cameraPhoto"  @click="startVideo()" icon-f7="videocam"></f7-link>
      <f7-link v-show="cameraRecording && !cameraPhoto" ref="botonVideo" icon-f7="stop_circle"></f7-link>
    </div>
  </f7-app>
</template>
<style>
  @import '../css/medium-editor-tables.css';
  /* .controlesCamara {
  position: absolute;
  bottom: 20px;
  left: 250px;
  display: flex;
  z-index: 12000;
  } */
  .controlesCamara {
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 100vw;
    position: absolute;
    bottom: 15px;
    left: 0;
    background: rgba(255, 255, 255, 0.445);
  }
  .controlesCamara .link, .controlesCamara .icon{
    font-size: 40px !important;
    margin: 20px;
  }
  .width-70 {
    width: 70% !important;
  }

  .overflow-auto {
    overflow: auto;
  }
  .bg-transparent {
    background: transparent;
  }
  .bg-white {
    background: white;
  }
</style>
<style scoped>
app textarea {
  border: none;
  width: 100%;
  min-height: 200px;
}
.justify-left {
  justify-content: initial;
}
.appbar {
  border-bottom: 1px solid #aaa;
}
.global-search-title[data-v-6c0a0fc1] {
  color: lightblue;
  margin: 0;
  padding-left: 0.5em;
}
.search-result-list {
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 134px;
  overflow: auto;
}
.search-result-list li {
  padding: 0.2em 1em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-result-list li:hover {
  background: lightblue;
  border-radius: 0.5em;
  cursor: pointer;
}
.input-fichaje {
  width: 100%;
  border: 1px solid #aaa;
  border-radius: 0.5em;
  padding: 0.5em;
}
</style>
<script>
import cordovaApp from "../js/cordova-app.js";
import { routes, routesLeft } from "../js/routes.js";
import Usuario from "./../js/db/Usuario.js";
import Property from "./../js/db/Property.js";
import Fichaje from "./../js/db/Fichaje.js";
import Global from "./../js/db/Global.js";
import Sugerencia from "./../js/db/Sugerencia.js";
import Notificacion from "./../js/db/Notificacion.js";
import InspeccionOffline from "./../js/db/InspeccionOffline.js";
import DatabaseModelOffline from "./../js/db/DatabaseModelOffline";
import LokiFsAdapter from "./../js/LokiFsAdapter.js";
import FileUtils from '../js/FileUtils';
import Utils from '../js/Utils';
import DateUtils from "../js/DateUtils";
import Flash from '../js/FlashUtils';
import { EventBus } from "../js/EventBus";
import * as nominatim from 'nominatim-client';
import moment, { min } from 'moment';

export default {
  components: {
  },
  data() {
    return {
      cameraOpened: false,
      cameraPhoto: false,
      cameraRecording: false,
      target: process.env.TARGET,
      textoBusquedaGlobal: "",
      busquedaGlobal: {clientes: [], ofertas: [], pedidos: []},
      popupAyudaOpened: false,
      lastFichaje: null,
      newFichaje: null,
      DateUtils,
      nominatim,
      fichajeRango: null,
      // Framework7 Parameters
      f7params: {
        id: "com.simecal.admin", // App bundle ID
        name: "Simecal", // App name
        theme: "aurora", // Automatic theme detection
        // App root data
        data: function() {
          return {
            URL_API: "",
            user: {},
            appDataPath:
              typeof eapp != "undefined" ? eapp.getPath("userData") : (typeof cordova != "undefined" && typeof cordova.file != "undefined" ? cordova.file.dataDirectory + 'files' : "")
          };
        },
        methods: {
          checkAuth: function() {
            return this.data.session ? true : false;
          },
          checkPermission: function(pagename) {
            return true;
          },
          startSession: function() {
            var session = localStorage.getItem("session");
            if (typeof session != "undefined" && session) {
              var sessionObj = JSON.parse(session);
              this.data.session = sessionObj;
              this.request.setup({
                headers: {
                  Authorization: "Bearer " + sessionObj.token
                }
              });
              var user = localStorage.getItem("user");
              var redirect = false;
              if (typeof user == "undefined" || !user) {
                redirect = true;
                this.methods.loadGlobals(redirect);
              } else {
                var properties = localStorage.getItem("properties");
                if (typeof properties == "undefined" || !properties) {
                  this.methods.loadProperties();
                }
              }
            }
            //TODO: Load user data
          },
          saveSession: function(obj) {
            this.data.session = obj;
            localStorage.setItem("session", JSON.stringify(obj));
          },
          removeSession: function() {
            delete this.data.session;
            localStorage.removeItem("session");
            localStorage.removeItem("user");
            if (typeof cordova != 'undefined' && cordova && cordova.plugins && cordova.plugins.notification && cordova.plugins.notification.local) {
              cordova.plugins.notification.local.un('trigger', this.methods.onTriggerLocalNotification, this);
              cordova.plugins.notification.local.cancelAll((e) => console.log("CANCELALL", e) );
            }
          },
          
          onTriggerLocalNotification(event) {
            console.log(event);
            const self = this;

            if (!this.methods.checkInternetConnection()) {
              console.log("No hay conexión a internet");
              if (event) {
                cordova.plugins.notification.local.update({
                  id: event.id,
                  text: "No hay conexión a internet"
                });
                setTimeout(() => {
                  cordova.plugins.notification.local.clear({id: event.id});
                }, 5000);
              }
              return;
            }

            let loginData = localStorage.getItem('loginData');
            if (!loginData) {
              console.log("No hay datos de usuario para logarse");
              if (event) {
                cordova.plugins.notification.local.update({
                  id: event.id,
                  text: "No hay datos de usuario para logarse"
                });
                setTimeout(() => {
                  cordova.plugins.notification.local.clear({id: event.id});
                }, 5000);
              }
              return;
            }
            
            loginData = JSON.parse(loginData);

            if (!loginData || !loginData.username || !loginData.password) {
              console.log("No hay datos de usuario para logarse");
              if (event) {
                cordova.plugins.notification.local.update({
                  id: event.id,
                  text: "No hay datos de usuario para logarse"
                });
                setTimeout(() => {
                  cordova.plugins.notification.local.clear({id: event.id});
                }, 5000);
              }
              return;
            }

            console.log("Autenticamos con los datos de usuario que tenemos");
            if (event) {
              cordova.plugins.notification.local.update({
                  id: event.id,
                  text: "Autenticamos con los datos de usuario que tenemos"
              });
            }
            Usuario.auth(loginData)
            .then(function(loginres) {
              self.methods.saveSession({
                token: loginres.access_token
              });
              console.log("Autenticación OK");
              if (event) {
                cordova.plugins.notification.local.update({
                  id: event.id,
                  text: "Autenticación OK"
                });
              }
              let fechaInspeccionHasta = moment.utc(moment().add(-1,"day").endOf("day")).format('yyyy-MM-DDTHH:mm:ss.sssZZ');
              InspeccionOffline.getList({filter: {
                'estado.estado': {'$in':['GENERADO', 'PLANIFICADO']},
                'calificacion': {'$exists': true},
                'guardadoLocal': {'$exists': true},
                'fechaInsp': {$lte:fechaInspeccionHasta}
              }, projection: ['id']}).then(function(res) {
                  let inspecciones = res.list;
                  let errores = 0;
                  let oks = 0;
                  let totales = inspecciones.length;
                  var p = Promise.resolve();
                  if (inspecciones && inspecciones.length) {
                    for (let i = 0; i < inspecciones.length; i++) {
                      p = p.then(_ => new Promise(function(resolve, reject) {
                        console.log("Sincronizando " + (i + 1) + " expedientes de " + totales);
                        if (event) {
                          let porc = Math.round(((i + 1) * 100) / totales );
                          cordova.plugins.notification.local.update({
                            id: event.id,
                            title: 'Sincronización [' + porc + '%]',
                            text: "Sincronizando " + (i + 1) + " expedientes de " + totales,
                            progressBar: { value: porc}
                          });
                        }
                        DatabaseModelOffline.syncUp({ data: [inspecciones[i]] })
                        .then(function() {
                          oks++;
                          resolve();
                        })
                        .catch(function(e) {
                          errores++;
                          resolve();
                        });
                      }));
                    }
                  }
                  p.then((_) => {
                    console.log("Sincronizados " + oks + " expedientes. Errores: " + errores);
                    if (event) {
                      cordova.plugins.notification.local.update({
                        id: event.id,
                        title: 'Sincronización [100%]',
                        text: "Sincronizados " + oks + " expedientes. Errores: " + errores,
                        progressBar: { value: 100 }
                      });
                      setTimeout(() => {
                        cordova.plugins.notification.local.clear({id: event.id});
                      }, 5000);
                    }
                  }).catch(e => reject(e));
                  
              });
            })
            .catch(function(e) {
              console.log("Error al hacer login con los datos de usuario guardados");
              cordova.plugins.notification.local.update({
                id: event.id,
                text: "Error al hacer login con los datos de usuario guardados"
              });
              setTimeout(() => {
                cordova.plugins.notification.local.clear({id: event.id});
              }, 5000);
            });
          },

          checkInternetConnection() {
            return !(!navigator || !navigator.connection || !navigator.connection.type || navigator.connection.type == Connection.NONE);
          },

          getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min) + min);
          },

          loadProperties: function () {
            Property.getList({ filter: {} }).then(function (res) {
              if (res && res.list) {
                localStorage.setItem('properties', JSON.stringify(res.list));
              } else {
                localStorage.setItem('properties', JSON.stringify([]));
              }
            }).catch(function (error) {
              console.log("Error cargando proerties", error);
            });
          },

          loadGlobals: function(redirect) {
            var app = this;

            if(typeof cordova != "undefined" && cordova.platformId && cordova.platformId != "electron") {
              FCM.getToken().then(token => {
                console.log("Get Token: " + token);
                Notificacion.registrarToken({data: {token: token}}).catch(e => {
                  console.log("Ha habido un error registrando token de las notificaciones, comuníquelo con el administrador, esto no tiene que ver para el funcionamiento de la aplicación.");
                });
              }).catch(e => {
                console.log("Ha habido un error conectando con el servidor de notificaciones, comuníquelo con el administrador, esto no tiene que ver para el funcionamiento de la aplicación.");
              });

              if (cordova.plugins && cordova.plugins.notification && cordova.plugins.notification.local) {
                cordova.plugins.notification.local.cancelAll((e) => console.log("CANCELALL", e) );
                let hour = this.methods.getRandomInt(1,6);
                let minute = this.methods.getRandomInt(0,59);
                cordova.plugins.notification.local.un('trigger', this.methods.onTriggerLocalNotification, this);
                cordova.plugins.notification.local.schedule({
                  title: 'Sincronización [0%]',
                  text: 'Sincronización automática de expedientes',
                  progressBar: { value: 0 },
                  trigger: { every: {hour: hour, minute: minute}, count: 365 },
                  autoClear: true
                });
                cordova.plugins.notification.local.on('trigger', this.methods.onTriggerLocalNotification, this);

              }
            } else {
              this.methods.loadProperties();
            }
            Usuario.me()
              .then(function(res) {
                var user = res;
                app.data.user = user;
                localStorage.setItem("user", JSON.stringify(user));
                if (redirect) {
                  app.methods.redirect();
                }
              })
              .catch(function(e) {
                console.log(e);
                app.toast
                  .create({
                    text: e.error_description,
                    position: "center",
                    closeTimeout: 2000
                  })
                  .open();
                if (e.error == "invalid_token") {
                  app.methods.removeSession();
                  var currentURL = app.views.main.router.currentRoute.path;
                  Flash.set("redirectTo", currentURL);
                  app.views.main.router.navigate("/login/");
                }
              });
          },
          redirect: function(){
            var app = this;
            var pathToGo = "/";
            var redirectTo = Flash.get("redirectTo");
            var directURL = Flash.get("directURL");
            app.views.left.router.navigate("/main-menu/");
            if(redirectTo){
              pathToGo = redirectTo;
            } else if (directURL) {
              var path = directURL.split("#!")[1];
              if (path) {
                pathToGo = path;
              } 
            }
            app.views.main.router.navigate(pathToGo);
          }
        },

        // App routes
        routes: routes,

        // Input settings
        input: {
          scrollIntoViewOnFocus:
            this.$device && this.$device.cordova && !this.$device.electron,
          scrollIntoViewCentered:
            this.$device && this.$device.cordova && !this.$device.electron
        },
        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false
        },

        toast: {
          destroyOnClose: true
        },
      },
      Sugerencia,
      Notificacion,
      Global,
      currentRoute: ''
    };
  },
  methods: {
    startVideo() {
      var self = this;
      let options = {
                    cameraDirection: CameraPreview.CAMERA_DIRECTION.BACK,
                    width: (window.screen.width/2),
                    height: (window.screen.height/2),
                    quality: 50,
                    withFlash: false
                  };
      CameraPreview.startRecordVideo(options,
                        (filePath) => {
                          console.log(filePath);
                          self.cameraRecording = true;    
                        },
                        (error) => {
                          console.log(error) ;
                          self.cameraRecording = false;   
                        },
      );
    },
    hacerFoto(callbackFn,extraParams) {
      var self = this;
      CameraPreview.takePicture(function(mediafiles) {
        callbackFn(mediafiles,extraParams);
        CameraPreview.stopCamera(_ => { self.cameraOpened = false; EventBus.$emit("cameraOpened", false); self.cameraRecording = false; self.cameraPhoto = false});
      });
    },
    hacerVideo(callbackFn,extraParams) {
      var self = this;
      CameraPreview.stopRecordVideo((mediafiles) => {
        callbackFn([mediafiles],extraParams);
        CameraPreview.stopCamera(_ => { self.cameraOpened = false; EventBus.$emit("cameraOpened", false); self.cameraRecording = false; self.cameraPhoto = false});
      }, error => console.log(error));
    },
    cerrarCamara() {
      var self = this;
      CameraPreview.stopCamera(_ => { self.cameraOpened = false; EventBus.$emit("cameraOpened", false); self.cameraRecording = false; self.cameraPhoto = false });
      document.removeEventListener("backbutton", self.cerrarCamara, false);
    },
    capturarFoto(callbackFn,extraParams) {
      var self = this;
      const storeToFile = extraParams && typeof extraParams.storeToFile != 'undefined' ? extraParams.storeToFile : true;
      CameraPreview.startCamera({camera: "back", tapFocus: true, storeToFile: storeToFile, tapPhoto: false, disableExifHeaderStripping: true, toBack: true}, 
        _ => {
          EventBus.$emit("cameraOpened", true);
          self.cameraOpened = true;
          self.cameraPhoto = true;
          self.cameraRecording = false;
          self.$refs.botonFoto.$el.onclick = _ => {self.hacerFoto(callbackFn,extraParams)};
        },
        _ => {
          CameraPreview.stopCamera(_ => { self.cameraOpened = false; EventBus.$emit("cameraOpened", false); self.cameraRecording = false});
        }
      );
      document.addEventListener("backbutton", self.cerrarCamara, false);
      // CameraPreview.onBackButton(function () {
      //   self.cerrarCamara();
      // });

    },
    grabarVideo(callbackFn,extraParams) {
      var self = this;
      var app = self.$f7;
      const storeToFile = extraParams && typeof extraParams.storeToFile != 'undefined' ? extraParams.storeToFile : true;
      CameraPreview.startCamera({ camera: "back", tapFocus: true, storeToFile: storeToFile, toBack: true }, 
        _ => {
          EventBus.$emit("cameraOpened", true);
          self.cameraOpened = true;
          self.cameraPhoto = false;
          self.cameraRecording = false;
          self.$refs.botonVideo.$el.onclick = _ => {self.hacerVideo(callbackFn,extraParams)};
        },
        _ => {
          CameraPreview.stopCamera(_ =>{self.cameraOpened = false; EventBus.$emit("cameraOpened", false); self.cameraRecording = false});
        }
      );
    },
    processNotification(data, app) {
      if (data.redirectUrl) {
        app.views.main.router.navigate(data.redirectUrl);
      } else if (data.redirectExternalUrl) {
        cordova.InAppBrowser.open(data.redirectExternalUrl, "_blank");
      }
    },
    sendSugerencia: function() {
      var self = this;
      var app = self.$f7;
      Sugerencia.post({
        data: { sugerencia: self.$refs.sugerenciaMejora.value }
      })
        .then(function(res) {
          self.$refs.sugerenciaMejora.value = "";
          if (self.$refs.popupSugerencia) {
            self.$refs.popupSugerencia.close(true);
          }
          app.dialog.alert("Gracias por su sugerencia, se ha realizado el envío correctamente.");
        })
        .catch(function(error) {
          var app = self.$f7;
          app.dialog.alert(error.message);
        });
    },
    search: function(){
      var self = this,
        app = self.$f7;
        app.preloader.show();
        Global.search(self.textoBusquedaGlobal).then(res=>{
          if (res.resultados && res.resultados.clientes){
            self.busquedaGlobal.clientes = res.resultados.clientes;
          }
          if (res.resultados && res.resultados.ofertas){
            self.busquedaGlobal.ofertas = res.resultados.ofertas;
          }
          if (res.resultados && res.resultados.pedidos){
            self.busquedaGlobal.pedidos = res.resultados.pedidos;
          }
          app.preloader.hide();
          app.popup.open(self.$refs.popupBusquedaGlobal.$el);
          return res;
        }).catch(_=>app.preloader.hide());
    },
    navega: function(tipo, elemento){
      const self = this,
        app = self.$f7;

      switch(tipo){
        case 'cliente':
          window.open("#!/clientes/view/" + elemento.id+"/", "_blank");
          break;
        case 'oferta':
          window.open("#!/ofertas/view/" + elemento.id+"/", "_blank");
          break;
        case 'pedido':
          window.open("#!/pedidos/view/" + elemento.id+"/", "_blank");
          break;
      }
      // app.popup.close(self.$refs.popupBusquedaGlobal.$el);
      self.textoBusquedaGlobal = "";
    },
    _getLokiops: function (f7, app) {
      const self = this;
      var lokiopts = {
        autoload: true,
        autoloadCallback: function() {
          self._loadPages(f7, app);
        } //Lokijs loaded
      };
      return lokiopts;
    },
    _loadPages: function (f7, app) {
      // TODO Ver como hacerlo mejor
      const actualPath = window.location.hash.replace("#!", "");
      if (!actualPath.startsWith("/public")) {
        app.methods.startSession();
      }
      var leftView = app.views.create(".view-left", {
        name: "left",
        routes: routesLeft,
        url: "/main-menu/"
      });
      var mainView = app.views.create(".view-main", {
        url: "/",
        preloadPreviousPage: false,
        pushState: f7.device.cordova ? false : true,
        xhrCache: false
      });
    },
    prepararFichaje: function() {
      self = this;
      // Buscar el fichaje anterior para mostrarlo y saber si el actual es de entrada o de salida.
      if (self.fichajeRango) {
        self.lastFichaje = { tipoFichaje: 'ENTRADA', fechaHora: DateUtils.dateFormat(new Date(), 'YYYY-MM-DDTHH:mm') };
        self.newFichaje = { tipoFichaje: 'SALIDA', fechaHora: DateUtils.dateFormat(new Date(), 'YYYY-MM-DDTHH:mm') };
      } else {
        self.lastFichaje = Fichaje.findLast().then(function (res) {
          self.lastFichaje = res;
          // si no hay fichaje anterior o lastFichaje.tipoFichaje es SALIDA, el actual es de entrada
          if (!self.lastFichaje || self.lastFichaje.tipoFichaje === 'SALIDA') {
            self.newFichaje = { tipoFichaje: 'ENTRADA', fechaHora: DateUtils.dateFormat(new Date(), 'YYYY-MM-DDTHH:mm') };
            return;
          }
          // En otro caso el actual es de salida
          else {
            self.newFichaje = { tipoFichaje: 'SALIDA', fechaHora: DateUtils.dateFormat(new Date(), 'YYYY-MM-DDTHH:mm') };
          }
        }).catch(function (e) {
          console.log(e);
        });
      }

    },
    getMinDate: function () {
      const self = this;
      if (self.fichajeRango) {
        return null;
      }
      return (self.lastFichaje && self.lastFichaje.fechaHora ? DateUtils.dateFormat(self.lastFichaje.fechaHora, 'YYYY-MM-DDTHH:mm') : undefined);
    },
    getDuracion: function() {
      const self = this;
      // calculamos la duración en minutos, la guardamos en newFichaje.duracion y la devolvemos
      if(self.newFichaje && self.newFichaje.tipoFichaje === 'SALIDA' && self.lastFichaje) {
        let diff = DateUtils.getDurationInMinutes(self.lastFichaje.fechaHora, self.newFichaje.fechaHora);
        self.newFichaje.duracion = diff;
        return DateUtils.getDurationInHoursAndMinutes(diff);
      }
    },
    changeDate: function(e, target) {
      const self = this;
      // Guardar el nuevo valor en el campo fechaHora del newFichaje
      self[target].fechaHora = e.target.value;
    },

    _guardarFichaje: async function () {
      const self = this;
      const previousFechaHoraNewFichaje = self.newFichaje.fechaHora;
      const previousFechaHoraLastFichaje = self.lastFichaje ? self.lastFichaje.fechaHora : null;
      // Convertimos la fechaHora a Date
      self.newFichaje.fechaHora = new Date(self.newFichaje.fechaHora);
      // Obtenemos la geolocalización
      let location = await Utils.executeTask(10000, self.getLocation(), null);
      self.newFichaje.location = location;

      if (self.fichajeRango) {
        // Convertimos la fechaHora a Date
        self.lastFichaje.fechaHora = new Date(self.lastFichaje.fechaHora);
        self.lastFichaje.location = location;
        Fichaje.saveRango({ data: {entrada: self.lastFichaje, salida: self.newFichaje} }).then(function (res) {
          self.lastFichaje = null;
          self.newFichaje = null;
          self.$refs.popupFichaje.close();
          EventBus.$emit("fichajeGuardado", res);
        }).catch(function (e) {
          self.$f7.dialog.alert(e.message);
        });
        if (self.lastFichaje) {
          self.lastFichaje.fechaHora = previousFechaHoraLastFichaje;
        }
      } else {
        Fichaje.save({ data: self.newFichaje }).then(function (res) {
          self.lastFichaje = null;
          self.newFichaje = null;
          self.$refs.popupFichaje.close();
          EventBus.$emit("fichajeGuardado", res);
        }).catch(function (e) {
          self.$f7.dialog.alert(e.message);
        });
      }
      if (self.newFichaje) {
        self.newFichaje.fechaHora = previousFechaHoraNewFichaje;
      }
    },
    guardarFichaje: async function() {
      const self = this;
      // Guardar el fichaje en la base de datos
      if (self.newFichaje) {
        if (self.lastFichaje && self.lastFichaje.tipoFichaje === 'ENTRADA') {
          // comprobamos si el enterior fichaje es de un día diferente para sacar un error
          if (DateUtils.dateFormat(self.lastFichaje.fechaHora, 'YYYY-MM-DD') !== DateUtils.dateFormat(self.newFichaje.fechaHora, 'YYYY-MM-DD')) {
            self.$f7.dialog.confirm(
              "El fichaje que está intentando registrar es de un día diferente de la entrada anterior, ¿Está seguro de que desea crear este fichaje?",
              "Advertencia",
              self._guardarFichaje,
            );
            return;
          }
        }
        self._guardarFichaje();
      }
    },
    async getLocation() {
      const self = this;
      let location = null;
      try {
        if (navigator && navigator.geolocation) {
          const pos = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          });
          if (pos) {
            location = {
              punto: { coordinates: [pos.coords.longitude, pos.coords.latitude], type: "Point", x: pos.coords.longitude, y: pos.coords.latitude },
              latitud: pos.coords.latitude,
              longitud: pos.coords.longitude
            };

            if (self.nominatim) {
              try {
                let nomClient = self.nominatim.default.createClient({ useragent: "SIMECAL-MOVILIDAD" });
                const res = await nomClient.reverse({lat: pos.coords.latitude, lon: pos.coords.longitude});
                location.direccion = res.display_name;
              } catch (e) {
                console.log("Error obteniendo direccion de nominatim: ", e);
              }
            }
          }
        }
      } catch (e) {
        console.log("Error obteniendo direccion de nominatim: ", e);
      } 
      return location;
    },
    updateCurrentRoute(route) {
      this.currentRoute = route;
    }
  },
  computed: {
    rutaCalendario: function() {
      if(Utils.getUser()) {
        console.log('Con usuario: '+this.currentRoute);
        return this.currentRoute === '/calendario/' || this.currentRoute === '/calendario/' + Utils.getUser().username + '/' || this.currentRoute === '/fichaje/';
      } else {
        console.log('Sin usuario: '+this.currentRoute);
        return false;
      }
    }
  },
  mounted() {
    const self = this;
    EventBus.$on("capturarFoto", this.capturarFoto);
    EventBus.$on("grabarVideo", this.grabarVideo);
    EventBus.$on("cameraOpened", (val) => {
      const elsPopup = document.getElementsByClassName("popup-tablet-fullscreen");
      const els = document.getElementsByClassName("popup-backdrop");
      [].forEach.call(els, function (el) {
        if (val) {
          el.style['z-index'] = -1;
        } else {
          delete el.style['z-index'];
        }
      });
      [].forEach.call(elsPopup, function (el) {
        if (!val) {
          self.$nextTick(() => {
            el.classList.add("modal-in");
          })
        }
      });
    });
    this.$f7ready(f7 => {
      var self = this;
      // Init cordova APIs (see cordova-app.js)
      if (f7.device.cordova) {
        cordovaApp.init(f7);
      }
      // Call F7 APIs here
      var app = this.$f7;

      if (typeof cordova == "undefined") {
        // Init Database
        var loki = require("lokijs");
        // var LokiFsMobileAdapter = require("loki-cordova-fs-adapter");
        var lokiopts = self._getLokiops(f7, app);
        lokiopts.env = "BROWSER";
        window.db = new loki(app.data.appDataPath + "/simecal.json", lokiopts);
      } else if (cordova.platformId == "electron") {
        // Init Database
        var loki = require("lokijs");
        // var LokiFsMobileAdapter = require("loki-cordova-fs-adapter");
        var lokiopts = self._getLokiops(f7, app);
        lokiopts.adapter = new LokiFsAdapter();
        window.db = new loki(app.data.appDataPath + "/simecal.json", lokiopts);
      } else {
        self._loadPages(f7, app);
        window.db = window.plugins.mongodb;
        window.db.initiate("simecal-movilidad");
        // lokiopts.adapter = new LokiFsMobileAdapter({ prefix: "loki" });
        // window.db = new loki("simecal.db", lokiopts);

        FCM.getToken().then(token => {
          console.log("Get Token: " + token);
          Notificacion.registrarToken({data: {token: token}}).catch(e => {
            app.dialog.alert("Ha habido un error registrando token de las notificaciones, comuníquelo con el administrador, esto no tiene que ver para el funcionamiento de la aplicación.");
          });
        }).catch(e => {
          app.dialog.alert("Ha habido un error conectando con el servidor de notificaciones, comuníquelo con el administrador, esto no tiene que ver para el funcionamiento de la aplicación.");
        });
        FCM.onTokenRefresh((token) => {
          console.log("Token refresh: " + token);
          Notificacion.registrarToken({data: {token: token}}).catch(e => {
            app.dialog.alert("Ha habido un error registrando token de las notificaciones, comuníquelo con el administrador, esto no tiene que ver para el funcionamiento de la aplicación.");
          });
        });

        FCM.onNotification(function(data) {
          if(data.wasTapped) {
            //Notification was received on device tray and tapped by the user.
            console.log(data);
            self.processNotification(data, app);
          } else {
            //Notification was received in foreground. Maybe the user needs to be notified.
            console.log(data);
            app.dialog.confirm(data.body, data.title, (_) => {
              self.processNotification(data, app);
            });
          }
        });

        if (cordova && cordova.plugins && cordova.plugins.notification && cordova.plugins.notification.local) {
          cordova.plugins.notification.local.un('trigger', app.methods.onTriggerLocalNotification, app);
          cordova.plugins.notification.local.on('trigger', app.methods.onTriggerLocalNotification, app);
        }

      }
      window.app = app;

      //FIX external links inAppBrowser
      this.$$(this.$el).on("click", "a", function(e) {
        if (self.$$(this).hasClass("external")) {
          if (self.$$(this).hasClass("base64")) {
            if (typeof cordova == "undefined") {
              var link =
                '<iframe src="' +
                this.href +
                '" frameborder="0" style="margin:0; padding:0; border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>';
              var win = window.open();
              win.document.write(link);
            } else {
              var base64Data = this.href.split(',')[1];
              var data = FileUtils.b64toData(base64Data);
              FileUtils.openFile(data, this.dataset["name"], function() {}, function() {});
            }
          } else {
            var link = this.href;
            window.open(link, "_blank");
          }
          e.preventDefault();
          return false;
        }
      });
      // Escuchar el evento routeChange
      app.on('routeChange', (route) => {
        self.updateCurrentRoute(route.url.includes('#!')?route.url.split('#!')[1]:route.url);
      });
  
      // Inicializar la ruta actual
      self.updateCurrentRoute(window.location.hash.replace("#!", ""));
    });

  }
};
</script>