<template>
  <div class ="rfiles">
    <!-- <div class="rfiles-container margin-horizontal"> -->
    <div v-if="preview" @drop.prevent="onDropOut" :dropZoneActive="activeDrag && !readonly && !form.readonly" :data-active="activeDrag" @dragenter.prevent="activeDrag = true" @dragover.prevent="activeDrag = true" @dragleave.prevent="activeDrag = false">
      <Container
        group-name="group-files"
        orientation="horizontal"
        :contenteditable="!readonly && !form.readonly"
        class="rfiles-container"
        :class="{'dashed': !readonly&&!form.readonly}"
        :animation-duration="1000"
        @drop="onDrop(getValue(), $event)"
        :get-child-payload="getPayload(getValue())"
        :should-accept-drop="(src, payload) => getShouldAcceptDrop(getValue(), src, payload)"
        drag-handle-selector=".column-drag-handle"
      >
        <a v-if="!getValue() || !getValue().length" :contenteditable="false" @paste.prevent="paste" @input.prevent="false" @mousedown.prevent="_ => {return false}">
        </a>
        <Draggable class="rfiles-item" v-for="(item,iaf) in getValue()" :key="'file-'+prefixKey+'-'+iaf">
          <span :contenteditable="false" class="rfiles-close" v-if="!readonly&&!form.readonly" @click="deleteItem(iaf)">
            <f7-icon v-tooltip="'Eliminar'" f7="multiply_circle_fill" />
          </span>
          <r-badge :contenteditable="false" class="rfiles-badge" color="green" :text="(iaf + 1)+''"></r-badge>
          <f7-checkbox 
            v-if="mostrarCheck(item)"
            :class="{ 'disabled': readonly || form.readonly }"
            class="rfiles-check"
            :checked="item.selected"
            @change="onChangeCheck(item, $event.target.checked)"
          ></f7-checkbox>
          <a
            v-if="target === 'cordova' && !ignorarOffline"
            @paste.prevent="paste"
            :contenteditable="false"
            @input.prevent="false"
            @mousedown.prevent="_ => {return false}"
            :class="{base64: !!item._base64}"
            :data-name="item.nombre + '.' + item.extension"
            @click.stop="see(item)"
          >
            <img :class="{'column-drag-handle': !readonly&&!form.readonly}" :alt="item.nombre" :style="'height:' + itemHeight + 'px;'" :data-name="item.nombre + '.' + item.extension" v-tooltip="item.nombre" :src="getSrc(item)"/>
          </a>
          <a v-else :contenteditable="false" @paste.prevent="paste" @input.prevent="false" @mousedown.prevent="_ => {return false}">
            <img
              :alt="item.nombre" 
              :class="{'column-drag-handle': !readonly&&!form.readonly}"
              :style="'height:' + itemHeight + 'px;'"
              :data-name="item.nombre + '.' + item.extension"
              v-tooltip="item.nombre" :src="getSrc(item)"
              @click="download(item)" />
          </a>
          <!-- vamos a poner un  r-input tipo textarea para los comentarios de los ficheros -->
          <r-input 
            v-if="mostrarComentario"
            :form="form"
            :contenteditable="false"
            class="comentario-rfile"
            outline
            style="width: 100%;"
            floating-label
            :label="'Comentario'" 
            type="textarea"
            :name="name + '.' + iaf + '.comentario'" 
          />
        </Draggable>
      </Container>
    </div>
    <div>
      <slot name="add" v-if="!readonly&&!form.readonly&&(!maxFiles||!getValue()||getValue().length<maxFiles)">
        <input :multiple="!maxFiles || maxFiles > 1" type="file" ref="input" style="display:none;" @change="loadFile" />
        <f7-block> 
          <f7-button @click="$refs.input.click()">{{label}}</f7-button>
        </f7-block>
      </slot>
    </div>
  </div>
</template>
<script>

import moment from 'moment';
import FileUtils from './../js/FileUtils';
import { Container, Draggable } from "vue-smooth-dnd";
import RInput from './rInput.vue';
import RBadge from './rBadge.vue';

const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;
  //const result = [...arr];
  if (!arr) arr = [];
  var result = arr;

  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = Object.assign({},  result.splice(removedIndex, 1)[0]);
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, Object.assign({}, itemToAdd));
  }

};

export default {
  components: {
    Container,
    Draggable,
    RInput,
    RBadge
  },
  data() {
    return {
      self: this,
      target: process.env.TARGET,
      activeDrag: true
    };
  },
  props: {
    name: {
      required: true,
      type: String
    },
    form: {
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    prefixKey: {
      default: ''
    },
    label: {
      default: 'Añadir documento'
    },
    maxFiles: {
      type: Number,
      default: undefined
    },
    documentoAdapter: {
      type: Object
    },
    ignorarOffline: {
      type: Boolean,
      default: false
    },
    itemHeight: {
      type: Number,
      default: 100
    },
    mostrarComentario: {
      type: Boolean,
      default: true
    },
    mostrarCheck: {
      type: Function,
      default: function() { return false; }
    },
    preview: {
      type: Boolean,
      default: true
    },
    conservarNombre: {
      type: Boolean,
      default: true
    },
    sobrescribir: {
      type: Boolean,
      default: true
    },
    // ruta donde poner los ficheros en el servidor
    path: {
      type: String,
      default: ""
    },
    offlineRootDir: {
      type: String,
      default: "Documents/"
    },
    offlinePath: {
      type: String,
      default: ""
    }
  },
  methods: {
    onChangeCheck(item, value) {
      var self = this;
      item.selected = !!value;
    },
    paste(event) {
      const self = this;
      if (event.clipboardData.files && event.clipboardData.files.length) {
        if (!self.readonly && !self.form.readonly) {
          self.loadFile(event.clipboardData.files);
        }
      }
    },
    getPayload(arr) { 
      console.log("getPayload");
      console.log(arr);
      return index => Object.assign({}, arr[index]);
    },
    getShouldAcceptDrop (arr, src, payload) {
      console.log("getShouldAcceptDrop");
      var self = this;
      // return src.groupName == 'group-count' || src.groupName == 'group-panel' || src.groupName == 'group-cuando' || src.groupName == 'group-entonces';
      return true;
    },
    onDrop(arr, dropResult) {
      var self = this;
      console.log("ONDROPPP");
      applyDrag(arr, dropResult);
      self.$forceUpdate();
    },
    onDropOut: function(e) {
      const self = this;
      console.log("DROPPPPP");
      console.log(e);
      if (!self.readonly && !self.form.readonly) {
        self.loadFile(e);
      }
    },
    getValue: function() {
      const self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        context = context[namespaces[i]];
      }
      if (context && !Array.isArray(context)) {
        context = [context];
      }
      return context;
    },
    append: function(val) {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          if (
            typeof context[namespaces[i]] == "undefined" ||
            !context[namespaces[i]]
          )
            self.$set(context, namespaces[i], []);
          context[namespaces[i]].push(val);
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
          } else {
            self.$set(context, namespaces[i], {});
          }
        }
        context = context[namespaces[i]];
      }
    },
    deleteItem: function(index) {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      var doc = null;
 
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          if (
            typeof context[namespaces[i]] == "undefined" ||
            !context[namespaces[i]]
          ) {
            self.$set(context, namespaces[i], []);
          }
          if (context[namespaces[i]] && !Array.isArray(context[namespaces[i]])) {
              self.$delete(context, namespaces[i]);
          }  else {
              if (self.target == 'cordova') {
                if (context[namespaces]) {
                  doc = context[namespaces][index];
                } else if (context[namespaces[i]]) {
                  doc = context[namespaces[i]][index];
                }
              }
            self.$delete(context[namespaces[i]], index);
          }
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
          } else {
            self.$set(context, namespaces[i], {});
          }
        }
        context = context[namespaces[i]];
      }
      if (doc && doc.offlinePath && self.target == 'cordova') {
        FileUtils.deleteFile(doc.offlinePath,
          (a) => {
            console.log(a);
            },
          (e) => {
            console.log(e);
            }
          );
      }
    },
    loadFile: function(event) {
      var self = this;
      var app = self.$f7;
      let files = [];
      if (event instanceof FileList || Array.isArray(event)) {
        files = event;
      } else {
        files = event.target && event.target.files ? event.target.files : (event.dataTransfer && event.dataTransfer.files ? event.dataTransfer.files : [])
      }
      var value = self.getValue();
      if (self.maxFiles && (files.length + (value && value != null ? value.length : 0)) > self.maxFiles) {
        app.dialog.alert("El número máximo de ficheros a añadir es " + self.maxFiles);
        self.$refs.input.value = "";
        return false;
      }
      var filesLoaded = [];
      var inputFilesLength = files.length;
      Array.from(files).forEach(file => {
        console.log("fileloaded", file);
        if (self.target == 'cordova') {
          // const numFileName = (!value || !value.length ? 0 : Math.max(...value.map(d => d.nombre.indexOf("[") < 0 ? 0 : parseInt(d.nombre.split(']')[0].split('[').slice(-1).join() || "0", 10) || 0))) + 1;
          const timestamp = moment().format("YYYYMMDDHHmmss");
          self.documentoAdapter.saveBlob(file, self.offlineRootDir, `${self.offlinePath}/${timestamp}_${file.name}`)
            .then(({filePath,localURL}) => {
              var fileLoaded = {
                nombre: file.name.split(".").slice(0, -1).join("."),
                extension: /(?:\.([^.]+))?$/.exec(file.name)[1], //file.type.split("/")[1],
                _localURL: localURL,
                offlinePath: filePath
              };
              self.append(fileLoaded);
              filesLoaded.push(fileLoaded);
              app.preloader.hide();
              self.$emit("loadFile", fileLoaded);
              if (filesLoaded.length == inputFilesLength) {
                self.$emit("loadFiles", filesLoaded);
              }

            }).catch(e => {
              app.dialog.alert("Error guardando documento " + e);
              app.preloader.hide();
            })
        } else {
          var reader = new FileReader();
          reader.onload = function(e) {
            var fileLoaded = {
              nombre: file.name.split(".").slice(0, -1).join("."),
              extension: /(?:\.([^.]+))?$/.exec(file.name)[1], //file.type.split("/")[1],
              path: self.path,
              conservarNombre: self.conservarNombre,
              sobrescribir: self.sobrescribir,
              _base64: e.target.result
            };
            self.append(fileLoaded);
            filesLoaded.push(fileLoaded);
            app.preloader.hide();
            self.$emit("loadFile", fileLoaded);
            if (filesLoaded.length == inputFilesLength) {
              self.$emit("loadFiles", filesLoaded);
            }
          };
          reader.readAsDataURL(file);
        }
        app.preloader.show();
      });
      self.$refs.input.value = "";
    },
    getSrc(item) {
      var self = this;
      var app = self.$f7;
      switch (item.extension.toLowerCase()) {
        case "pdf":
          return "static/rfiles/pdf.png";
          break;
        case "doc":
        case "docx":
          return "static/rfiles/doc.png";
          break;
        case "xls":
        case "xlsx":
          return "static/rfiles/xls.png";
          break;
        case "png":
        case "jpg":
        case "jpeg":
        case "gif":
        case "webp":
          if (!item._base64 && self.target != 'cordova') {
            return self.documentoAdapter.getDocumentoPublicUrl(item);
            // self.documentoAdapter.getDocumento(item).then(function(data){
            //   console.log("RECIBIDO DOC");
            //   var blob = new Blob([data]);
            //   var reader = new FileReader();
            //   reader.readAsDataURL(blob); 
            //   reader.onloadend = function() {
            //     console.log("DOC END");
            //     var base64data = reader.result;                
            //     self.$set(item, '_base64', base64data);
            //     self.$forceUpdate();
            //   }
            // });
          }
          if (self.target == 'cordova') {
            if (!item._localURL) {
              window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, async function (fs) {
                fs.root.getFile(item.offlinePath, { create: false }, function (fileEntry) {
                  self.$set(item, '_localURL', fileEntry.nativeURL);
                });
              });
              return '';
            }
            return item._localURL;
          } else {
            return item._base64
              ? item._base64
              : "static/rfiles/attachment-icon.png" ;
          }
          break;
        case "mp4":
        case "mkv":
        case "vob":
        case "avi":
        case "mov":
          return "static/rfiles/video.png";
          break;
        case "m4a":
          return "static/rfiles/audio-icon.png";
          break;
        default:
          return "static/rfiles/attachment-icon.png";
      }
    },
    download(item) {
      var self = this;
      if (self.target == 'web' || self.ignorarOffline) {
        window.open(self.documentoAdapter.getDocumentoPublicUrl(item), "_blank");
        // self.documentoAdapter.download(item);
      }
    },
    see(item) {
      var self = this;
      if (item._localURL) {
          if (['jpg', 'jpeg', 'png', 'gif'].includes(item.extension)) {
            PhotoViewer.show(item._localURL);
          } else if (['mp4', 'mkv', 'vob', 'avi', 'mov'].includes(item.extension)) {
            VideoPlayer.play(item._localURL);
          } else if (['m4a', 'wma', 'mp3'].includes(item.extension)) {
            var media = new Media(item._localURL);
            media.play();
          } else {
            cordova.plugins.fileOpener2.open(item._localURL, item._type, {onError, success : function(){ console.log("Abierto") }});
          }
      } else if (item.offlinePath){
        var onError = function (e) {
          console.log("ERROR");
          console.log(e);
        }
        window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, async function (fs) {
          fs.root.getFile(item.offlinePath, { create: false }, function (fileEntry) {
            item._localURL = fileEntry.nativeURL || 'MALO';
            self.see(item);
          }, onError);
        }, onError);
      } 
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
  }
};
</script>
<style>
.rfiles .rfiles-container {
  min-height: 50px;
  display: flex !important;
  width: 100%;
  overflow-x: auto;
}
.rfiles .rfiles-container.dashed {
  border: 2px dashed black;
}
.rfiles-item {
  cursor: pointer;
  position: relative;
  margin: 3px;
}
.rfiles-item a {
  display: block;
}
.rfiles-close {
  position: absolute;
  right: 2px;
  top: 0;
  background: white;
  border-radius: 50%;
}

/* li.comentario-rfile {
  list-style: none;
} */

li.comentario-rfile div.item-title {
  position: relative;
  left: 6px;
  border-radius: 5px;
  font-size: 10px;
}
.rfiles-item {
  border: 1px dashed #e0e9d9;
  background-color: white;
  border-radius: 5px;
}
.rfiles-badge {
  position: absolute;
  top: 3px;
  right: 23px;
  z-index: 100;
  opacity: 0.7;
}
.rfiles-check {
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 100;
  background: #ffffff86;
}
li.comentario-rfile div.item-input-wrap, li.comentario-rfile div.item-input, li.comentario-rfile div.item-input-wrap textarea {
  width: 100%;
}
li.comentario-rfile div.item-content, li.comentario-rfile div.item-inner {
  padding: 0;
}
li.comentario-rfile div.item-inner {
  width: 100%;
}
li.comentario-rfile div.item-input-wrap textarea {
  width: 100%;
  height: 50px !important; 
  background-color: #e0e9d9;
  border: none;
} 
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
