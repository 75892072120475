import Fichajes from './fichajes.vue';
import FichajeForm from './fichaje-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/fichaje/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('fichaje', ['ADMIN', 'INSPECTOR'], Fichajes),
        keepAlive: true
},
    {
        path: '/fichaje/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('fichaje/new', ['ADMIN', 'INSPECTOR'], FichajeForm)
    },
    {
        path: '/fichaje/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('fichaje/edit', ['ADMIN', 'INSPECTOR'], FichajeForm)
    },
    {
        path: '/fichaje/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('fichaje/view', ['ADMIN', 'INSPECTOR'], FichajeForm, { editable: false })
    },
];
export default routes;