<template>
  <f7-page :page-content="false">

    <v-app class="full-height" :class="{'mobile-css': target == 'cordova'}">
      <v-sheet 
        height="64"
        class="d-flex sheetPadre"
      >
        <v-toolbar flat class="barraTareas">
          <v-btn
            outlined
            class="ma-2"
            @click="value = ''"
            width="30px"
          >
            Hoy
          </v-btn>
          <v-btn
            outlined
            @click="popupSelectDateOpened = true"
            class="btn-calendar"
          >
            <v-icon>mdi-calendar</v-icon>
          </v-btn>
          <v-btn
            icon
            class="ma-2"
            @click="$refs.calendar.prev()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            icon
            class="ma-2"
            @click="$refs.calendar.next()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            <template v-if="type == 'month'">
              {{ $refs.calendar.title }}
            </template>
            <template v-else>
              S {{moment($refs.calendar.lastStart.date, 'YYYY-MM-DD', 'es').week()}} - {{ $refs.calendar.title }}
            </template>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div class="badges-totales" v-if="target != 'cordova'">
            <r-badge v-if="horasFichajeDia() != '0h 0m'" :color="getColorEvento({tipo:'FICHAJE'}) || 'green'" :text="horasFichajeDia()" v-tooltip="'Horas imputadas: ' + horasFichajeDia()"></r-badge>
            <r-badge 
              class="importeTotal"
              v-if="totalTotal" 
              color="teal"
              :text="'<small>' + totalTotal + '</small>'"
              v-tooltip="'Importe total: ' + totalTotal"
            ></r-badge>
            <r-badge 
              class="importeTotal"
              v-if="tiempoTotal" 
              color="black"
              :text="'<small>' + tiempoTotal + '</small>'"
              v-tooltip="'Tiempo total: ' + tiempoTotal"
            ></r-badge>
            <r-badge 
              class="importeTotal kmTotal"
              v-if="totalRealizado" 
              color="green" 
              :text="'<small>OEB R: ' + totalRealizado + '</small>'"
              v-tooltip="'Importe total Realizado: ' + totalRealizado"
            ></r-badge>
            <r-badge 
              class="importeTotal kmTotal"
              v-if="totalPlanif" 
              color="blue" 
              :text="'<small>OEB P: ' + totalPlanif + '</small>'"
              v-tooltip="'Importe total Planificado: ' + totalPlanif"
            ></r-badge>
            <r-badge 
              class="importeTotal kmTotal"
              v-if="totalTotalKm" 
              color="purple" 
              :text="'<small>' + totalTotalKm + '</small>'"
              v-tooltip="'Km totales: ' + totalTotalKm"
            ></r-badge>
            <r-badge 
              class="importeTotal kmTotal"
              v-if="totalTiempoDesplazamiento" 
              color="purple" 
              :text="'<small>TD: ' + totalTiempoDesplazamiento + '</small>'"
              v-tooltip="'Tiempo desplazamiento: ' + totalTiempoDesplazamiento"
            ></r-badge>
            <r-badge 
              class="importeTotal kmTotal"
              v-if="totalTotalConsumo" 
              color="deeppurple" 
              :text="'<small>Cons: ' + totalTotalConsumo + ' L</small>'"
              v-tooltip="'Consumo: ' + totalTotalConsumo + ' L'"
            ></r-badge>
            <r-badge 
              class="importeTotal kmTotal"
              v-if="totalTotalKmParticular"
              color="purple" 
              borderColor="red"
              :text="'<small>Part: ' + totalTotalKmParticular + '</small>'"
              v-tooltip="'Km totales (particular): ' + totalTotalKmParticular"
            ></r-badge>
            <r-badge 
              class="importeTotal kmTotal"
              v-if="totalTotalConsumoParticular" 
              color="deeppurple" 
              borderColor="red"
              :text="'<small>Cons P: ' + totalTotalConsumoParticular + ' L</small>'"
              v-tooltip="'Consumo: ' + totalTotalConsumoParticular + ' L'"
            ></r-badge>
            <!-- <r-badge 
              class="importeTotal kmTotal"
              v-if="totalTiempoDesplazamientoParticular" 
              color="purple" 
              borderColor="black"
              :text="'<small>TDP: ' + totalTiempoDesplazamientoParticular + '</small>'"
              v-tooltip="'Tiempo desplazamiento  (particular): ' + totalTiempoDesplazamientoParticular"
            ></r-badge> -->
            <r-badge 
              class="importeTotal kmTotal"
              v-if="totalTiempoTPC" 
              color="orange" 
              :text="'<small>TPC: ' + totalTiempoTPC + '</small>'"
              v-tooltip="'Tiempo TPC: ' + totalTiempoTPC"
            ></r-badge>
            <r-badge 
              class="importeTotal kmTotal"
              v-if="totalTiempoTPIR" 
              borderColor="teal"
              color="lime" 
              :text="'<small>TPIR: ' + totalTiempoTPIR + '</small>'"
              v-tooltip="'Tiempo TPIR: ' + totalTiempoTPIR"
            ></r-badge>
            <r-badge 
              class="importeTotal kmTotal"
              v-if="totalTiempoTPIP" 
              borderColor="blue"
              color="lime" 
              :text="'<small>TPIP: ' + totalTiempoTPIP + '</small>'"
              v-tooltip="'Tiempo TPIP: ' + totalTiempoTPIP"
            ></r-badge>
            <r-badge 
              class="importeTotal kmTotal"
              v-if="totalImporteTPIR" 
              borderColor="lime"
              color="teal" 
              :text="'<small>TPIR€: ' + totalImporteTPIR + '</small>'"
              v-tooltip="'Importe TPIR: ' + totalImporteTPIR"
            ></r-badge>
            <r-badge 
              class="importeTotal kmTotal"
              v-if="totalImporteTPIP" 
              borderColor="blue"
              color="teal" 
              :text="'<small>TPIP€: ' + totalImporteTPIP + '</small>'"
              v-tooltip="'Importe TPIP: ' + totalImporteTPIP"
            ></r-badge>
            <r-badge 
              class="importeTotal kmTotal"
              v-if="totalContratado"
              color="lime" 
              :text="'<small>OEA: </small> ' + totalContratado"
              v-tooltip="'Total contratado: ' + totalContratado"
            ></r-badge>
          </div>
          <v-spacer></v-spacer>
          <div class="selectoresCalendario"> 
            <v-dialog
              v-model="dialogFilter"
              width="600"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="Object.keys(filterData).length ? '#23355b' : '#23355b77'"
                  v-bind="attrs"
                  v-on="on"
                  text
                  icon
                >
                  <v-icon>mdi-filter-variant</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-toolbar
                  dark
                  color="#23355bdd"
                >
                  <!-- <v-btn
                    icon
                    dark
                    @click="dialogFilter = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn> -->
                  <v-btn
                    dark
                    @click="filterData = {}"
                    color="red"
                    width="150px"
                  >
                    Limpiar filtro
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-toolbar-title>Filtro</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn
                      dark
                      text
                      color="green"
                      @click="dialogFilter = false; getPlanificaciones();"
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-list
                  three-line
                  subheader
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-select
                        v-model="filterData.tipo"
                        :items="filterOptions.tipos"
                        item-text="text"
                        item-value="value"
                        dense
                        outlined
                        hide-details
                        multiple
                        label="Tipo"
                      ></v-select>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="filterData.tipo && filterData.tipo.indexOf('TAREA') > -1">
                    <v-list-item-content>
                      <v-select
                        v-model="filterData.tipoTarea"
                        :items="filterOptions.tiposTareas"
                        item-text="text"
                        item-value="value"
                        dense
                        outlined
                        hide-details
                        multiple
                        label="Tipo Tarea"
                      ></v-select>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
              </v-card>
            </v-dialog>
            <v-text-field
              v-if="Utils.getUser() && (Utils.getUser().roles.indexOf('ADMIN') > -1 || Utils.getUser().roles.indexOf('DTYCAL') > -1 || Utils.getUser().roles.indexOf('FVYFP') > -1 || Utils.getUser().roles.indexOf('DIRECTOR') > -1 || Utils.getUser().roles.indexOf('PLANIFICADOR') > -1 || Utils.getUser().roles.indexOf('TRAMITACION') > -1)"
              dense
              outlined
              hide-details
              label="Empleado"
              v-model="inspectorFiltro"
              :rules="rules"
              @change="getPlanificaciones()"
            ></v-text-field>

            <v-select
              v-if="!target || target != 'cordova'"
              v-model="type"
              :items="types"
              item-text="nombre"
              item-value="valor"
              dense
              outlined
              hide-details
              label="Tipo"
            ></v-select>
          </div>
        </v-toolbar>
      </v-sheet>
      <v-row class="full-height">
        <v-col class="full-height">
          <v-sheet class="full-height">
            <v-calendar
              ref="calendar"
              v-model="value"
              color="primary"
              :type="type"
              :events="events"
              :event-color="getEventColor"
              event-overlap-mode="column"
              :event-overlap-threshold="30"
              :event-ripple="false"
              :event-height="25"
              :weekdays="weekday"
              @change="getEvents"
              :interval-minutes="15"
              :interval-count="96"
              :short-intervals="false"
              :show-week="true"
              @mousedown:event="startDrag"
              @mousedown:time="startTime"
              @mousemove:time="mouseMove"
              @mouseup:time="endDrag"
              @mouseleave.native="cancelDrag"
              @click:event="showEvent"
              @click:more="viewDay"
              locale="es-ES"
              event-more-text="{0} más"
            >
              <template v-slot:event="{ event, timed, eventSummary }">
                <div :class="{'borde-amarillo' : event && event.planificacion && event.planificacion.pendienteConfirmar, 'borde-negro' : event && event.planificacion && event.planificacion.tipo && event.planificacion.tipo == 'DESPLAZAMIENTO' && !event.planificacion.particular, 'borde-rojo' : event && event.planificacion && event.planificacion.tipo && event.planificacion.tipo == 'DESPLAZAMIENTO' && event.planificacion.particular, 'borde-naranja' : event && event.planificacion && event.planificacion.tipo != 'TAREA' && event.planificacion.tipo != 'FICHAJE' && !event.planificacion.idPedido, 'borde-verde' : event && event.planificacion && event.planificacion.estado && event.planificacion.estado == 'REALIZADA' , 'borde-azul' : event && event.planificacion && event.planificacion.estado && event.planificacion.estado == 'PLANIFICADA'}">
                  <div
                    v-if="!timed"
                    class="v-event-draggable"
                    v-html="event.name"
                  ></div>
                  <!-- Este v-else se muestra cuando es semana o dia asi que tendremos que poner para que se vean mas datos -->
                  <div
                    v-else
                    class="v-event-draggable"
                  >
                    <span class="v-event-summary" :class="{'letrasNegras' : event.colorLetras == 'black'}">
                      <span v-if="event.planificacion.direccionInspeccion">
                          {{event.planificacion.direccionInspeccion.cp}}{{event.planificacion.direccionInspeccion.poblacion ? ' ' + event.planificacion.direccionInspeccion.poblacion : ''}}{{event.planificacion.direccionInspeccion.provinciaStr ? ' - ' + event.planificacion.direccionInspeccion.provinciaStr : (event.planificacion.direccionInspeccion.provincia ? ' - ' + event.planificacion.direccionInspeccion.provincia : '' ) }}
                        <br>
                      </span>
                      <strong>{{event.name}}</strong>
                      <span v-if="event.planificacion.codigoExpediente">
                        <br>
                        <strong>{{event.planificacion.codigoExpediente}}</strong>
                      </span>
                      <span v-if="event.planificacion.tipo == 'DESPLAZAMIENTO' && (event.planificacion.desplazamiento || event.planificacion.moviloc)">
                        <br>
                        <strong>Desde:</strong> {{event.planificacion.desplazamiento ? event.planificacion.desplazamiento.startAddress : event.planificacion.moviloc.origen}}<br>
                        <strong>Hasta:</strong> {{event.planificacion.desplazamiento ? event.planificacion.desplazamiento.endAddress : event.planificacion.moviloc.destino}}<br>
                        <template v-if="event.planificacion.moviloc && event.planificacion.desplazamiento">
                          <strong>Distancia según google:</strong> {{event.planificacion.desplazamiento.distance.humanReadable}}<br>
                          <strong>Tiempo según google:</strong> {{event.planificacion.desplazamiento.duration.humanReadable}}<br>
                        </template>
                        <template v-if="event.planificacion.moviloc && event.planificacion.moviloc.consumo">
                          <strong>Consumo:</strong> {{event.planificacion.moviloc.consumo}} L<br>
                        </template>
                        <template v-if="event.planificacion.moviloc && event.planificacion.moviloc.velMedia">
                          <strong>Vel. Media:</strong> {{event.planificacion.moviloc.velMedia}} km/h<br>
                        </template>
                        <template v-if="event.planificacion.moviloc && event.planificacion.moviloc.matricula">
                          <strong>Matrícula:</strong> {{event.planificacion.moviloc.matricula}}
                        </template>
                      </span>
                      <span v-if="event.planificacion.cliente && (event.planificacion.cliente.nif || event.planificacion.cliente.razonSocial)">
                        <br>
                        {{event.planificacion.cliente.nif}} - {{event.planificacion.cliente.razonSocial}}
                      </span>
                      <span v-if="event.planificacion.empleadoContratacion">
                        <br>
                        E.Cont: {{event.planificacion.empleadoContratacion}}
                      </span>
                      <span v-if="event.planificacion.codigoPedido || event.planificacion.numeroPedido">
                        <br>
                        Ped: {{event.planificacion.numeroPedido ? event.planificacion.numeroPedido : event.planificacion.codigoPedido}} - Lin: {{event.planificacion.numLinea}}
                      </span>
                      <span v-if="event.planificacion.totalSinIVASinTasasConDescuento">
                        <br>
                        {{event.planificacion.totalSinIVASinTasasConDescuento.toString().replace('.',',')}} €
                      </span>
                      <span v-if="event.planificacion.tipoTarea">
                        <br>
                        {{event.planificacion.tipoTarea.codigo}} - {{event.planificacion.tipoTarea.nombre}}
                      </span>
                      <br>
                      <template v-if="event.planificacion.tipo != 'FICHAJE'">{{(event.planificacion.horaInicio ? DateUtils.dateFormat(event.planificacion.horaInicio, 'DD/MM/YYYY') + ' ' : '')}} {{moment(event.start).format('H:mm') + ' - ' + moment(event.end).format('H:mm')}}</template>
                      <span v-if="event.planificacion.tipo != 'FICHAJE' && event.planificacion.inspectores && event.planificacion.inspectores.length">
                        <br>
                        {{event.planificacion.inspectores.map(i => i.username).join(',')}}
                      </span>
                      <!-- {{event}} -->
                    </span>
                  </div>
                  <div
                    v-if="timed && event.planificacion.tipo != 'FICHAJE' && (event.planificacion.tipo == 'TAREA' || (!event.planificacion.estado) || (event.planificacion.estado && event.planificacion.estado != 'REALIZADA'))"
                    class="v-event-drag-bottom"
                    @mousedown.stop="extendBottom(event)"
                  ></div>
                </div>

              </template>
              <template v-slot:day-label-header="{day, present, date}">
                  <div
                    :class="{'presente': present}"
                    v-html="day"
                  ></div>
                  <div class="datos-cabecera">
                    <r-badge v-if="horasFichajeDia(date) != '0h 0m'" :color="getColorEvento({tipo:'FICHAJE'})" :text="horasFichajeDia(date)" v-tooltip="'Horas imputadas: ' + horasFichajeDia(date)"></r-badge>
                    <r-badge v-if="totalImporteDia(date)" color="teal"  :text="totalImporteDia(date)" v-tooltip="'Importe día: ' + totalImporteDia(date)" ></r-badge>
                    <r-badge v-if="totalTiempoPorTipo(null, date, 'particular', false)" color="black"  :text="totalTiempoPorTipo(null, date, 'particular', false)" v-tooltip="'Tiempo día: ' + totalTiempoPorTipo(null, date, 'particular', false)" ></r-badge>
                    <r-badge v-if="totalKmDia(date)" color="purple" :text="totalKmDia(date)" v-tooltip="'Km día: ' + totalKmDia(date)"></r-badge>
                    <r-badge v-if="totalKmDia(date, true)" color="purple" borderColor="red" :text="'Part: ' + totalKmDia(date, true)" v-tooltip="'Km día (part): ' + totalKmDia(date, true)"></r-badge>
                    <r-badge v-if="totalTiempoPorTipo('DESPLAZAMIENTO', date, 'particular', false)" color="purple" :text="'TD: ' + totalTiempoPorTipo('DESPLAZAMIENTO', date, 'particular', false)" v-tooltip="'Tiempo desplazamiento: ' + totalTiempoPorTipo('DESPLAZAMIENTO', date, 'particular', false)"></r-badge>
                    <r-badge v-if="totalConsumoDia(date)" color="deeppurple" :text="'Cons: ' + totalConsumoDia(date) + ' L'" v-tooltip="'Cons. día: ' + totalConsumoDia(date) + ' L'"></r-badge>
                    <r-badge v-if="totalConsumoDia(date, true)" color="deeppurple" borderColor="red" :text="'Cons. P: ' + totalConsumoDia(date, true) + ' L'" v-tooltip="'Consumo día (part): ' + totalConsumoDia(date, true) + ' L'"></r-badge>
                    <r-badge v-if="totalTiempoPorTipo(['LINEA', 'SINPEDIDO'], date)" color="orange" :text="'TPC: ' + totalTiempoPorTipo(['LINEA', 'SINPEDIDO'], date)" v-tooltip="'Tiempo TPC: ' + totalTiempoPorTipo(['LINEA', 'SINPEDIDO'], date)"></r-badge>
                    <r-badge v-if="totalTiempoPorTipo('TAREA', date, ['tipoTarea.codigo', 'estado'], ['T0005', 'REALIZADA'])" color="lime" borderColor="teal" :text="'TPIR: ' + totalTiempoPorTipo('TAREA', date, ['tipoTarea.codigo', 'estado'], ['T0005', 'REALIZADA'])" v-tooltip="'Tiempo TPIR: ' + totalTiempoPorTipo('TAREA', date, ['tipoTarea.codigo', 'estado'], ['T0005', 'REALIZADA'])"></r-badge>
                    <r-badge v-if="totalTiempoPorTipo('TAREA', date, ['tipoTarea.codigo', 'estado'], ['T0005', 'PLANIFICADA'])" color="lime" borderColor="blue" :text="'TPIP: ' + totalTiempoPorTipo('TAREA', date, ['tipoTarea.codigo', 'estado'], ['T0005', 'PLANIFICADA'])" v-tooltip="'Tiempo TPIP: ' + totalTiempoPorTipo('TAREA', date, ['tipoTarea.codigo', 'estado'], ['T0005', 'PLANIFICADA'])"></r-badge>
                    <r-badge v-if="totalImporteDiaFirma(date, 'REALIZADA')" color="teal" borderColor="lime" :text="'TPIR€: ' + totalImporteDiaFirma(date, 'REALIZADA')" v-tooltip="'Importe TPIR: ' + totalImporteDiaFirma(date, 'REALIZADA')"></r-badge>
                    <r-badge v-if="totalTiempoPorTipo('TAREA', date, ['tipoTarea.codigo', 'estado'], ['T0005', 'PLANIFICADA'])" color="teal" borderColor="blue" :text="'TPIP€: ' + getFormatImporte(totalTiempoPorTipo('TAREA', date, ['tipoTarea.codigo', 'estado'], ['T0005', 'PLANIFICADA'], true) * precioMinuto)" @click="verTPIP(date)" v-tooltip="'Importe TPIP: ' + (totalTiempoPorTipo('TAREA', date, ['tipoTarea.codigo', 'estado'], ['T0005', 'PLANIFICADA'], true) * precioMinuto)"></r-badge>
                    <r-badge v-if="getInformesPendientes(date)" color="red" :text="'Pdtes: ' + getInformesPendientes(date)" v-tooltip="'Informes Pendientes: ' + getInformesPendientes(date)" @click="verPendientes(date)"></r-badge>
                    <v-btn v-if="numEventosDia(date)" icon :color="numEventosDia(date, 'DESPLAZAMIENTO') ? 'blue' : 'gray'" v-tooltip="'Calcular ruta'">
                      <v-icon @click="diaSelected = date;popupCalcularRutaOpened = true;">mdi-routes</v-icon>
                    </v-btn>
                  </div>
              </template>
              <template v-slot:day-label="{day, present, date}">
                  <div
                    :class="{'presente': present}"
                    v-html="day"
                  ></div>
                  <div class="datos-cabecera">
                    <r-badge v-if="horasFichajeDia(date) != '0h 0m'" :color="getColorEvento({tipo:'FICHAJE'})" :text="horasFichajeDia(date)" v-tooltip="'Horas imputadas: ' + horasFichajeDia(date)"></r-badge>
                    <r-badge v-if="totalImporteDia(date)" color="teal"  :text="totalImporteDia(date)" v-tooltip="'Importe día: ' + totalImporteDia(date)" ></r-badge>
                    <r-badge v-if="totalTiempoPorTipo(null, date, 'particular', false)" color="black"  :text="totalTiempoPorTipo(null, date, 'particular', false)" v-tooltip="'Tiempo día: ' + totalTiempoPorTipo(null, date, 'particular', false)" ></r-badge>
                    <r-badge v-if="(numEventosDia(date, 'LINEA') + numEventosDia(date, 'SINPEDIDO'))" color="green" :text="(numEventosDia(date, 'LINEA') + numEventosDia(date, 'SINPEDIDO')) + ' Insp.'" v-tooltip="'Número de inspecciones: ' + (numEventosDia(date, 'LINEA') + numEventosDia(date, 'SINPEDIDO'))"></r-badge>
                    <r-badge v-if="numEventosDia(date, 'TAREA')" color="blue" :text="numEventosDia(date, 'TAREA') + ' Tareas'" v-tooltip="'Número de tareas: ' + numEventosDia(date, 'TAREA')"></r-badge>
                    <r-badge v-if="totalKmDia(date)" color="purple" :text="totalKmDia(date)" v-tooltip="'Km día: ' + totalKmDia(date)"></r-badge>
                    <r-badge v-if="totalKmDia(date, true)" color="purple" borderColor="red" :text="'Part: ' + totalKmDia(date, true)" v-tooltip="'Km día (part): ' + totalKmDia(date, true)"></r-badge>
                    <r-badge v-if="totalTiempoPorTipo('DESPLAZAMIENTO', date, 'particular', false)" color="purple" :text="'TD: ' + totalTiempoPorTipo('DESPLAZAMIENTO', date, 'particular', false)" v-tooltip="'Tiempo desplazamiento: ' + totalTiempoPorTipo('DESPLAZAMIENTO', date, 'particular', false)"></r-badge>
                    <r-badge v-if="totalConsumoDia(date)" color="deeppurple" :text="'Cons: ' + totalConsumoDia(date) + ' L'" v-tooltip="'Cons. día: ' + totalConsumoDia(date) + ' L'"></r-badge>
                    <r-badge v-if="totalConsumoDia(date, true)" color="deeppurple" borderColor="red" :text="'Cons. P: ' + totalConsumoDia(date, true) + ' L'" v-tooltip="'Consumo día (part): ' + totalConsumoDia(date, true) + ' L'"></r-badge>
                    <r-badge v-if="totalTiempoPorTipo(['LINEA', 'SINPEDIDO'], date)" color="orange" :text="'TPC: ' + totalTiempoPorTipo(['LINEA', 'SINPEDIDO'], date)" v-tooltip="'Tiempo TPC: ' + totalTiempoPorTipo(['LINEA', 'SINPEDIDO'], date)"></r-badge>
                    <r-badge v-if="totalTiempoPorTipo('TAREA', date, ['tipoTarea.codigo', 'estado'], ['T0005', 'REALIZADA'])" color="lime" borderColor="teal" :text="'TPIR: ' + totalTiempoPorTipo('TAREA', date, ['tipoTarea.codigo', 'estado'], ['T0005', 'REALIZADA'])" v-tooltip="'Tiempo TPIR: ' + totalTiempoPorTipo('TAREA', date, ['tipoTarea.codigo', 'estado'], ['T0005', 'REALIZADA'])"></r-badge>
                    <r-badge v-if="totalTiempoPorTipo('TAREA', date, ['tipoTarea.codigo', 'estado'], ['T0005', 'PLANIFICADA'])" color="lime" borderColor="blue" :text="'TPIP: ' + totalTiempoPorTipo('TAREA', date, ['tipoTarea.codigo', 'estado'], ['T0005', 'PLANIFICADA'])" v-tooltip="'Tiempo TPIP: ' + totalTiempoPorTipo('TAREA', date, ['tipoTarea.codigo', 'estado'], ['T0005', 'PLANIFICADA'])"></r-badge>
                    <r-badge v-if="totalImporteDiaFirma(date, 'REALIZADA')" color="teal" borderColor="lime" :text="'TPIR€: ' + totalImporteDiaFirma(date, 'REALIZADA')" v-tooltip="'Importe TPIR: ' + totalImporteDiaFirma(date, 'REALIZADA')"></r-badge>
                    <r-badge v-if="totalTiempoPorTipo('TAREA', date, ['tipoTarea.codigo', 'estado'], ['T0005', 'PLANIFICADA'])" color="teal" borderColor="blue" :text="'TPIP€: ' + getFormatImporte(totalTiempoPorTipo('TAREA', date, ['tipoTarea.codigo', 'estado'], ['T0005', 'PLANIFICADA'], true) * precioMinuto)" @click="verTPIP(date)" v-tooltip="'Importe TPIP: ' + (totalTiempoPorTipo('TAREA', date, ['tipoTarea.codigo', 'estado'], ['T0005', 'PLANIFICADA'], true) * precioMinuto)"></r-badge>
                    <r-badge v-if="numEventosDia(date, 'DESPLAZAMIENTO')" color="purple" :text="numEventosDia(date, 'DESPLAZAMIENTO') + ' Despl.'" v-tooltip="'Número de desplazamientos: ' + numEventosDia(date, 'DESPLAZAMIENTO')"></r-badge>
                    <r-badge v-if="getInformesPendientes(date)" color="red" :text="'Pdtes: ' + getInformesPendientes(date)" v-tooltip="'Informes Pendientes: ' + getInformesPendientes(date)" @click="verPendientes(date)"></r-badge>
                    <v-btn v-if="numEventosDia(date)" icon :color="numEventosDia(date, 'DESPLAZAMIENTO') ? 'blue' : 'gray'" v-tooltip="'Calcular ruta'">
                      <v-icon @click="diaSelected = date;popupCalcularRutaOpened = true;">mdi-routes</v-icon>
                    </v-btn>
                  </div>
              </template>
            </v-calendar>
            <v-menu
              v-model="selectedOpen"
              v-if="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card
                v-if="selectedEvent"
                color="grey lighten-4"
                min-width="350px"
                flat
              >
                <v-toolbar
                  :color="selectedEvent && selectedEvent.color"
                  dark
                  dense
                  :class="{'letrasNegras' : selectedEvent && selectedEvent.colorLetras == 'black'}"
                >
                  <v-btn icon v-if="selectedEvent.planificacion.tipo != 'FICHAJE' && (selectedEvent.planificacion.tipo == 'TAREA' || selectedEvent.planificacion.estado != 'REALIZADA')">
                    <v-icon @click="readOnlyPlanif = !readOnlyPlanif">{{readOnlyPlanif ? 'mdi-pencil-outline' : 'mdi-pencil'}}</v-icon>
                  </v-btn>
                  <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon v-if="!selectedEvent.planificacion || selectedEvent.planificacion.tipo != 'FICHAJE'">
                    <v-icon v-if="selectedEvent.planificacion && (selectedEvent.planificacion.tipo == 'TAREA' || selectedEvent.planificacion.estado != 'REALIZADA')" @click="borrarPlanificacion(selectedEvent.planificacion)">mdi-trash-can-outline</v-icon>
                    <v-icon v-else-if="selectedEvent.planificacion && selectedEvent.planificacion.tipo == 'DESPLAZAMIENTO'" v-roles="['ADMIN']" @click="borrarPlanificacion(selectedEvent.planificacion)">mdi-trash-can-outline</v-icon>
                    <v-icon v-if="!selectedEvent.planificacion" @click="borrarEvento(selectedEvent)">mdi-trash-can-outline</v-icon>
                  </v-btn>
                  <v-btn icon v-else>
                    <v-icon @click="borrarFichaje(selectedEvent.planificacion.idFichaje)">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text v-if="selectedEvent.planificacion && selectedEvent.planificacion.id">
                  <span v-if="selectedEvent.planificacion.tipo == 'TAREA' && selectedEvent.planificacion.estado == 'PLANIFICADA'">
                    <v-btn
                      color="teal"
                      dark
                      @click="selectedEvent.planificacion.estado = 'REALIZADA'; guardarCambios(); onCerrarDetalle()"
                    >
                      Marcar como realizada
                    </v-btn>
                    <br/>
                    <br/>
                  </span>
                  <span v-if="selectedEvent.planificacion.tipo == 'DESPLAZAMIENTO'">
                    <v-btn
                      width="150px"
                      color="red"
                      dark
                      v-if="selectedEvent.planificacion.moviloc && !selectedEvent.planificacion.particular"
                      @click="selectedEvent.planificacion.particular = true; guardarCambios(); onCerrarDetalle()"
                    >
                      Es particular
                    </v-btn>
                    <v-btn
                      width="150px"
                      color="green"
                      dark
                      v-if="selectedEvent.planificacion.particular"
                      @click="selectedEvent.planificacion.particular = false; guardarCambios(); onCerrarDetalle()"
                    >
                      Es de trabajo
                    </v-btn>
                    <br/>
                  </span>
                  <span v-else>
                    <f7-link
                      v-if="selectedEvent.planificacion.tipo == 'FICHAJE'"
                      :href="getUrl('fichaje/view/' + selectedEvent.planificacion.idFichaje + '/')"
                      icon-f7="arrow_up_right_square"
                      color="green"
                      :external="target != 'cordova'"
                      v-tooltip="'Ver Tarea'"
                    > Ver Fichaje</f7-link>
                    <f7-link
                      v-if="selectedEvent.planificacion.tipo == 'TAREA'"
                      :href="getUrl('tarea/view/' + selectedEvent.planificacion.idTarea + '/')"
                      icon-f7="arrow_up_right_square"
                      color="green"
                      :external="target != 'cordova'"
                      v-tooltip="'Ver Tarea'"
                    > Ver Tarea</f7-link>
                    <f7-link
                      v-if="selectedEvent.planificacion.idPedido"
                      :href="getUrl('pedidos/view/' + selectedEvent.planificacion.idPedido + '/')"
                      icon-f7="arrow_up_right_square"
                      color="green"
                      :external="target != 'cordova'"
                      v-tooltip="'Ver Pedido' + ((selectedEvent.planificacion.numeroPedido || selectedEvent.planificacion.codigoPedido) ? ' ' + (selectedEvent.planificacion.numeroPedido || selectedEvent.planificacion.codigoPedido) : '')"
                    > Ver Pedido</f7-link>
                    <f7-link
                      v-if="selectedEvent.planificacion.idOferta"
                      :href="getUrl('ofertas/view/' + selectedEvent.planificacion.idOferta + '/')"
                      icon-f7="arrow_up_right_square"
                      color="green"
                      :external="target != 'cordova'"
                      v-tooltip="'Ver Oferta' + ((selectedEvent.planificacion.numeroOferta || selectedEvent.planificacion.codigoOferta) ? ' ' + (selectedEvent.planificacion.numeroOferta || selectedEvent.planificacion.codigoOferta) : '')"
                    > Ver Oferta</f7-link>
                    <f7-link
                      v-if="selectedEvent.planificacion.cliente && selectedEvent.planificacion.cliente.id"
                      :href="getUrl('clientes/view/' + selectedEvent.planificacion.cliente.id + '/')"
                      icon-f7="arrow_up_right_square"
                      color="green"
                      :external="target != 'cordova'"
                      v-tooltip="'Ver Cliente ' + (selectedEvent.planificacion.cliente.codigo || '') + ' - ' + (selectedEvent.planificacion.cliente.razonSocial || '')"
                    > Ver Cliente</f7-link>
                    <f7-link
                      v-if="!selectedEvent.planificacion.idPedido && selectedEvent.planificacion.idExpediente"
                      icon-f7="arrow_up_right_square"
                      color="orange"
                      @click="popupAsociarOpened = true"
                      v-tooltip="'Asociar Pedido'"
                    > Asociar Pedido</f7-link>
                    <f7-link
                      v-if="selectedEvent.planificacion.idExpediente"
                      :href="getUrl(getPathExpediente(selectedEvent.planificacion))"
                      icon-f7="arrow_up_right_square"
                      color="green"
                      class="margin-left"
                      :external="target != 'cordova'"
                      @click="selectedOpen = false"
                      v-tooltip="'Ver Expediente' + (selectedEvent.planificacion.codigoExpediente ? ' ' + selectedEvent.planificacion.codigoExpediente : '')"
                    > Ver Expediente</f7-link>
                    <br>
                  </span>
                  <span><strong>{{selectedEvent.name}}</strong><br></span>
                  <span v-if="selectedEvent.planificacion.tipo == 'DESPLAZAMIENTO' && (selectedEvent.planificacion.desplazamiento || selectedEvent.planificacion.moviloc)">
                    <strong>Desde:</strong> {{selectedEvent.planificacion.desplazamiento ? selectedEvent.planificacion.desplazamiento.startAddress : selectedEvent.planificacion.moviloc.origen}}<br>
                    <strong>Hasta:</strong> {{selectedEvent.planificacion.desplazamiento ? selectedEvent.planificacion.desplazamiento.endAddress : selectedEvent.planificacion.moviloc.destino}}<br>
                    <template v-if="selectedEvent.planificacion.moviloc && selectedEvent.planificacion.desplazamiento">
                      <strong>Distancia según google:</strong> {{selectedEvent.planificacion.desplazamiento.distance.humanReadable}}<br>
                      <strong>Tiempo según google:</strong> {{selectedEvent.planificacion.desplazamiento.duration.humanReadable}}<br>
                    </template>
                    <template v-if="selectedEvent.planificacion.moviloc && selectedEvent.planificacion.moviloc.consumo">
                      <strong>Consumo:</strong> {{selectedEvent.planificacion.moviloc.consumo}} L<br>
                    </template>
                    <template v-if="selectedEvent.planificacion.moviloc && selectedEvent.planificacion.moviloc.velMedia">
                      <strong>Vel. Media:</strong> {{selectedEvent.planificacion.moviloc.velMedia}} km/h<br>
                    </template>
                    <template v-if="selectedEvent.planificacion.moviloc && selectedEvent.planificacion.moviloc.matricula">
                      <strong>Matrícula:</strong> {{selectedEvent.planificacion.moviloc.matricula}}<br>
                    </template>
                  </span>
                  <span>
                    {{(selectedEvent.planificacion.horaInicio ? DateUtils.dateFormat(selectedEvent.planificacion.horaInicio, 'DD/MM/YYYY') + ' ' : '') + moment(selectedEvent.start).format('H:mm') + (selectedEvent.planificacion.tipo == 'FICHAJE' ? '' : (' - ' + moment(selectedEvent.end).format('H:mm')))}}
                    <strong v-if="selectedEvent.planificacion.tipo != 'FICHAJE' && selectedEvent.planificacion.tiempoEstimadoCampo"> ( {{getMinutesHumanize(selectedEvent.planificacion.tiempoEstimadoCampo)}} )</strong>
                    <br>
                  </span>
                  <span v-roles="['ADMIN', 'DTYCAL']" v-if="selectedEvent.planificacion.tiempoReal">
                    <strong>Tiempo Real:</strong> {{selectedEvent.planificacion.tiempoReal}} min.
                    <br>
                  </span>
                  <span v-if="selectedEvent.planificacion.codigoPedido || selectedEvent.planificacion.numeroPedido">
                    <strong>Pedido:</strong> {{selectedEvent.planificacion.numeroPedido ? selectedEvent.planificacion.numeroPedido : selectedEvent.planificacion.codigoPedido}} - <strong>Línea:</strong> {{selectedEvent.planificacion.numLinea}}
                    <br>
                  </span>
                  <span v-if="selectedEvent.planificacion.codigoExpediente">
                    <strong>Expediente:</strong> {{selectedEvent.planificacion.codigoExpediente}}
                    <r-badge v-if="selectedEvent.planificacion.estadoExpediente && (!selectedEvent.planificacion.tipo || selectedEvent.planificacion.tipo != 'TAREA')" :color="getColorBadgeEstadoExpediente(selectedEvent.planificacion.estadoExpediente)" :text="getTextEstadoExpediente(selectedEvent.planificacion.estadoExpediente)"></r-badge>
                    <br>
                  </span>
                  <span v-if="selectedEvent.planificacion.totalSinIVASinTasasConDescuento">
                    <strong>Importe:</strong> {{selectedEvent.planificacion.totalSinIVASinTasasConDescuento.toString().replace('.',',')}} €
                    <br>
                  </span>
                  <span v-if="selectedEvent.planificacion.cliente && (selectedEvent.planificacion.cliente.codigo || selectedEvent.planificacion.cliente.razonSocial || selectedEvent.planificacion.cliente.nif)">
                    <strong>Cliente:</strong> {{selectedEvent.planificacion.cliente.razonSocial || '' }} - {{selectedEvent.planificacion.cliente.nif || '' }} - {{selectedEvent.planificacion.cliente.codigo || '' }}
                    <br>
                  </span>
                  <span v-if="selectedEvent.planificacion.titular || selectedEvent.planificacion.nifTitular">
                    <strong>Titular:</strong> {{selectedEvent.planificacion.titular || '' }} - {{selectedEvent.planificacion.nifTitular || '' }}
                    <br>
                  </span>
                  <span v-if="selectedEvent.planificacion.empleadoContratacion">
                    <strong>Emp. Contratación:</strong> {{selectedEvent.planificacion.empleadoContratacion}}
                    <br>
                  </span>
                  <span v-if="selectedEvent.planificacion.creationUserName && selectedEvent.planificacion.tipo != 'FICHAJE'">
                    <strong>Emp. Planificador:</strong> {{selectedEvent.planificacion.empleadoPlanificador || selectedEvent.planificacion.creationUserName}}
                    <br>
                  </span>
                  <span v-if="selectedEvent.planificacion.fechaPlanificacion">
                    <strong>F. Planificacion:</strong> {{DateUtils.dateFormat(selectedEvent.planificacion.fechaPlanificacion, 'DD/MM/YYYY')}}
                    <br>
                  </span>
                  <r-badge v-if="selectedEvent.planificacion.pendienteConfirmar" :color="'red'" :text="'PDTE CONFIRMAR'"></r-badge>
                  <br v-if="selectedEvent.planificacion.pendienteConfirmar">
                  <span v-if="selectedEvent.planificacion.tipoTarea">
                    <strong>Tipo Tarea:</strong> {{selectedEvent.planificacion.tipoTarea.codigo || '' }} - {{selectedEvent.planificacion.tipoTarea.nombre || '' }}
                    <br>
                  </span>
                  <r-badge v-if="selectedEvent.planificacion.tipo != 'DESPLAZAMIENTO' && selectedEvent.planificacion.tipo != 'FICHAJE'" :color="getColorBadgeEstado(selectedEvent)" :text="selectedEvent.planificacion.estado"></r-badge>
                  <br v-if="selectedEvent.planificacion.tipo != 'DESPLAZAMIENTO'">
                  <strong v-if="selectedEvent.planificacion.direccionInspeccion">Dirección:</strong>
                  <ul v-if="selectedEvent.planificacion.direccionInspeccion">
                    <li>{{selectedEvent.planificacion.direccionInspeccion.direccion}}
                      <br>{{selectedEvent.planificacion.direccionInspeccion.cp}} - {{selectedEvent.planificacion.direccionInspeccion.poblacion}}
                      <br>{{selectedEvent.planificacion.direccionInspeccion.provinciaStr}}
                    </li>
                  </ul>
                  <span v-if="selectedEvent.planificacion.tipo != 'FICHAJE'"><strong>Empleados:</strong></span>
                  <ul v-if="selectedEvent.planificacion.tipo != 'FICHAJE'">
                    <li v-for="insp in selectedEvent.planificacion.inspectores" :key="insp.username">{{insp.username}} - {{insp.rol}}</li>
                  </ul>
                  <v-text-field v-if="!readOnlyPlanif && selectedEvent.planificacion.tipo != 'TAREA'" label="Añadir inspector" v-model="selectedEvent.anadirInspector"></v-text-field>
                  <v-select
                    v-if="!readOnlyPlanif && selectedEvent.planificacion.tipo != 'TAREA' && selectedEvent.anadirInspector"
                    :items="roles"
                    label="Asignar rol al inspector"
                    v-model="selectedEvent.rolInspector"
                  ></v-select>
                  <v-text-field v-if="!readOnlyPlanif" label="Comentarios Planificación" v-model="selectedEvent.planificacion.comentariosPlanificacion"></v-text-field>
                  <span v-else>{{selectedEvent.planificacion.comentariosPlanificacion}}</span>
                  <v-text-field type="number" v-if="!readOnlyPlanif && (selectedEvent.planificacion.tipo == 'SINPEDIDO' || selectedEvent.planificacion.tipo == 'TAREA')" label="Importe" v-model="selectedEvent.planificacion.totalSinIVASinTasasConDescuento"></v-text-field>
                  <v-checkbox v-if="!readOnlyPlanif" label="Pendiente de confirmar" v-model="selectedEvent.planificacion.pendienteConfirmar"></v-checkbox>
                  <!-- <span v-html="'modificationDate: '+ selectedEvent.planificacion.modificationDate"></span><br> -->
                  <!-- <span v-html="selectedEvent.l.direccionInspeccion.cp"></span>
                  <span v-html="selectedEvent.l.direccionInspeccion.poblacion"></span>
                  <span v-html="selectedEvent.l.direccionInspeccion.provincia"></span>
                  <span v-html="selectedEvent.l.direccionInspeccion.comunidad"></span> -->
                </v-card-text>
                <v-card-actions>
                  <f7-row>
                  <v-btn
                    class="col-50"
                    text
                    color="secondary"
                    @click="onCerrarDetalle()"
                  >
                    Cerrar
                  </v-btn>
                  <v-btn
                    class="col-50"
                    v-if="!readOnlyPlanif"
                    text
                    color="green"
                    @click="guardarCambios(), readOnlyPlanif = true"
                  >
                    Guardar
                  </v-btn>
                  </f7-row>
                </v-card-actions>
              </v-card>
          </v-menu>
          </v-sheet>
        </v-col>
      </v-row>

      <f7-popup tablet-fullscreen v-if="popupSelectDateOpened" ref="popupSelectDate" :opened="true" swipe-to-close @popup:closed="popupSelectDateOpened = false">
        <f7-page>
          <f7-navbar title="Selecciona fecha">
            <f7-nav-right>
              <f7-link popup-close>{{$t('common.cerrar')}}</f7-link>
            </f7-nav-right>
          </f7-navbar>
            <f7-card class="elevation-3 padding-vertical-half padding-right full-height">
              <f7-card-content class="full-height" >
                <!-- <f7-list class="list-form">
                  <ul>
                    <f7-row>
                      <f7-col width="100" medium="100">
                        <r-input
                            class="col-100 medium-50"
                            floating-label
                            label="Mes"
                            :form="rform"
                            type="select"
                            :readonly="false"
                            :overrideReadonly="true"
                            :name="'month'"
                          >
                            <option v-for="mes in meses" :key="'meses-'+mes.valor" :value="mes.valor">{{mes.nombre}}</option>
                          </r-input>
                        <r-input
                          :clearButton="false"
                          outline
                          floating-label
                          label="Año"
                          :form="rform"
                          :readonly="false"
                          :overrideReadonly="true"
                          type="number"
                          name="year"
                        />
                      </f7-col>
                    </f7-row>
                  </ul>
                </f7-list> -->
                <year-calendar :display-week-number="true" @click-day="onSelectDate" class="full-height" language="es" :week-start="1"></year-calendar>
              </f7-card-content>
            </f7-card>
        </f7-page>
      </f7-popup>

      <f7-popup v-if="popupCrearOpened" ref="popupCrear" :opened="true" swipe-to-close @popup:closed="popupCrearOpened = false">
        <f7-page>
          <f7-navbar title="Crear planificación">
            <f7-nav-right>
              <f7-link popup-close>{{$t('common.cerrar')}}</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <r-form 
            ref="rformCrear" 
            @save="onSaveCrear"
            :dbAdapter="null"
            classContent="full-height"
            :readonly="false"
            :defaultData="{fechaVisita: new Date(createStart)}"
          >
            <template v-slot:default="{ rform }">
              <v-expansion-panels>
                <!-- Pedido -->
                <v-expansion-panel>
                  <f7-card class="elevation-3 padding-vertical-half padding-right">
                    <v-expansion-panel-header class="expand-header-calendar">
                      Pedido
                      <template v-slot:actions>
                        <v-icon class="expand-icon-calendar" color="primary">
                          mdi-chevron-down
                        </v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <f7-card-content>
                          <f7-list class="list-form">
                            <ul>
                              <f7-row>
                                <f7-col width="100" medium="100">
                                  <r-input
                                    :clearButton="false"
                                    floating-label
                                    outline
                                    label="Número o código de pedido"
                                    :form="rform"
                                    name="numeroOCodigoPedido"
                                  />
                                  <r-input
                                    :clearButton="false"
                                    outline
                                    floating-label
                                    label="Número de línea"
                                    :form="rform"
                                    type="number"
                                    name="numeroLinea"
                                  />
                                  <r-input
                                    outline
                                    :clearButton="false"
                                    floating-label
                                    label="Fecha visita"
                                    :form="rform"
                                    type="datepicker"
                                    :calendar-params="{timePickerPlaceholder:'Selecciona hora', toolbarCloseText:'Hecho',closeOnSelect:true,timePicker: true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit', hour:'2-digit',minute:'2-digit' } }"
                                    name="fechaVisita"
                                  />
                                  <r-input
                                    outline
                                    :clearButton="false"
                                    floating-label
                                    label="Comentarios"
                                    :form="rform"
                                    name="observacionesPlanificar"
                                  />
                                </f7-col>
                              </f7-row>
                            </ul>
                          </f7-list>
                        </f7-card-content>
                    </v-expansion-panel-content>
                  </f7-card>
                </v-expansion-panel>
                <!-- Tarea -->
                <v-expansion-panel>
                  <f7-card class="elevation-3 padding-vertical-half padding-right">
                  <v-expansion-panel-header class="expand-header-calendar">
                    Tarea
                    <template v-slot:actions>
                      <v-icon class="expand-icon-calendar" color="primary">
                        mdi-chevron-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                      <f7-card-content>
                        <f7-list class="list-form">
                          <ul>
                            <f7-row>
                              <f7-col width="100" medium="100">
                                <r-input
                                  outline
                                  :clearButton="false"
                                  floating-label
                                  label="Código de tarea"
                                  :form="rform"
                                  name="codigoTarea"
                                />
                              </f7-col>
                            </f7-row>
                          </ul>
                        </f7-list>
                      </f7-card-content>
                  </v-expansion-panel-content>
                  </f7-card>
                </v-expansion-panel>
                <!-- Nueva Tarea -->
                <v-expansion-panel>
                  <f7-card class="elevation-3 padding-vertical-half padding-right">
                  <v-expansion-panel-header class="expand-header-calendar">
                    Crear Tarea
                    <template v-slot:actions>
                      <v-icon class="expand-icon-calendar" color="primary">
                        mdi-chevron-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                      <f7-card-content>
                        <f7-list class="list-form">
                          <ul>
                            <f7-row>
                              <f7-col width="100" medium="100">
                                <r-autocomplete
                                  :dbAdapter="TipoTarea"
                                  key="tipoTarea"
                                  class="col-100 medium-33"
                                  floating-label
                                  :label="$t('tarea.tipoTarea')"
                                  :form="rform"
                                  :idProp="null"
                                  name="tarea.tipo"
                                  labelProp="nombre"
                                  prefixProp="codigo"
                                  idFieldDetail="id"
                                  :shownFields="['id', 'codigo', 'nombre']"
                                  :showNavigationLink="false"
                                  :validation="{isValid: obj => {return !validator.isEmpty(obj.id, {ignore_whitespace:true})}, errorMessage: '* El tipo de tarea es obligatorio'}"
                                ></r-autocomplete>
                                <r-input
                                  floating-label
                                  :label="$t('tarea.descripcion')"
                                  :form="rform"
                                  type="text"
                                  name="tarea.descripcion"
                                  :validation="{isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* La descripción es obligatoria'}"
                                ></r-input>
                                <r-input
                                  floating-label
                                  :label="$t('tarea.tiempoEstimado')"
                                  :form="rform"
                                  type="text"
                                  name="tarea.tiempoEstimado"
                                ></r-input>
                                <r-direccion
                                  :form="rform"
                                  :name="'tarea.direccion'"
                                  :guardarCodigos="true"
                                  autocomplete="off"
                                  :showNavigationLink="false"
                                >
                                </r-direccion>
                                <r-input
                                  floating-label
                                  :label="$t('tarea.numeroPedido')"
                                  :form="rform"
                                  type="text"
                                  name="tarea.numeroPedido"
                                ></r-input>
                                <r-input
                                  floating-label
                                  :label="$t('tarea.numeroOferta')"
                                  :form="rform"
                                  type="text"
                                  name="tarea.numeroOferta"
                                ></r-input>
                                <r-input
                                  floating-label
                                  :label="$t('tarea.codigoCliente')"
                                  :form="rform"
                                  type="text"
                                  name="tarea.codigoCliente"
                                ></r-input>
                                <r-input
                                  floating-label
                                  :label="$t('tarea.codigoOportunidad')"
                                  :form="rform"
                                  type="text"
                                  name="codigoOportunidad"
                                ></r-input>
                                <r-input
                                  floating-label
                                  :label="$t('tarea.codigoCampania')"
                                  :form="rform"
                                  type="text"
                                  name="codigoCampania"
                                ></r-input>
                                <r-input
                                  floating-label
                                  :label="$t('tarea.comentarios')"
                                  :form="rform"
                                  type="text"
                                  name="tarea.comentarios"
                                ></r-input>
                                <r-input
                                  floating-label
                                  :label="$t('tarea.comentariosPlanificacion')"
                                  :form="rform"
                                  type="text"
                                  name="tarea.comentariosPlanificacion"
                                ></r-input>
                                <r-input
                                  floating-label
                                  :label="$t('tarea.importe')"
                                  :form="rform"
                                  type="number"
                                  step="0.01"
                                  name="tarea.importe"
                                ></r-input>
                              </f7-col>
                            </f7-row>
                          </ul>
                        </f7-list>
                      </f7-card-content>
                  </v-expansion-panel-content>
                  </f7-card>
                </v-expansion-panel>
                <!-- Expediente sin pedido -->
                <v-expansion-panel>
                  <f7-card class="elevation-3 padding-vertical-half padding-right">
                  <v-expansion-panel-header class="expand-header-calendar">
                    Expediente sin pedido
                    <template v-slot:actions>
                      <v-icon class="expand-icon-calendar" color="primary">
                        mdi-chevron-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                      <f7-card-content>
                        <f7-list class="list-form">
                          <ul>
                            <f7-row>
                              <f7-col width="100" medium="100">
                                <r-input
                                  :clearButton="false"
                                  floating-label
                                  :label="$t('inspeccion_sin_pedido.fechaVisita')"
                                  :form="rform"
                                  type="datepicker"
                                  :calendar-params="{timePickerPlaceholder:'Selecciona hora', toolbarCloseText:'Hecho',closeOnSelect:true,timePicker: true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit', hour:'2-digit',minute:'2-digit' } }"
                                  name="fechaVisita"
                                />
                                <r-autocomplete
                                  :dbAdapter="Cliente"
                                  floating-label
                                  :label="$t('inspeccion_sin_pedido.cliente')"
                                  :form="rform"
                                  :name="'inspeccionSinPedido.cliente'"
                                  :labelProp="'codigoNombre'"
                                  :minLength="3"
                                  :shownFields="['nif', 'razonSocial', 'codigo']"
                                  :async="true"
                                >
                                </r-autocomplete>
                                <r-autocomplete
                                  floating-label
                                  :label="$t('inspeccion_sin_pedido.servicio')"
                                  key="servicio"
                                  :dbAdapter="Servicio"
                                  class="col-100"
                                  :form="rform"
                                  :name="'inspeccionSinPedido.servicio'"
                                  labelProp="nombre"
                                  prefixProp="codigo"
                                  :shownFields="['codigo', 'precios', 'tasas', 'tasaDefecto', 'codCuentaMayor', 'tiempoEstimadoCampo', 'tiempoEstimadoOficina', 'materia', 'userText']"
                                ></r-autocomplete>
                                <r-input
                                  floating-label
                                  :label="$t('inspeccion_sin_pedido.tiempoEstimadoCampo')"
                                  :form="rform"
                                  type="text"
                                  name="inspeccionSinPedido.tiempoEstimadoCampo"
                                ></r-input>
                                <r-direccion
                                  :form="rform"
                                  :name="'inspeccionSinPedido.direccionInspeccion'"
                                  :guardarCodigos="true"
                                  autocomplete="off"
                                  :showNavigationLink="false"
                                >
                                </r-direccion>
                                <r-input
                                  floating-label
                                  :label="$t('inspeccion_sin_pedido.comentariosPlanificacion')"
                                  :form="rform"
                                  type="text"
                                  name="inspeccionSinPedido.comentariosPlanificacion"
                                ></r-input>
                                <r-input
                                  floating-label
                                  :label="'Importe'"
                                  :form="rform"
                                  type="number"
                                  step="0.01"
                                  name="inspeccionSinPedido.totalSinIVASinTasasConDescuento"
                                ></r-input>
                              </f7-col>
                            </f7-row>
                          </ul>
                        </f7-list>
                      </f7-card-content>
                  </v-expansion-panel-content>
                  </f7-card>
                </v-expansion-panel>
              </v-expansion-panels>
              <f7-row>
                <f7-col width="100" medium="100">
                  <f7-button
                      fill
                      icon-f7="disk"
                      text="Planificar"
                      class="display-flex margin-vertical margin-left"
                      color="green"
                      @click="rform.saveItem()"
                    ></f7-button>
                </f7-col>
              </f7-row>
            </template>
          </r-form>
        </f7-page>

      </f7-popup>
      <f7-popup v-if="popupAsociarOpened" ref="popupAsociar" :opened="true" swipe-to-close @popup:closed="popupAsociarOpened = false">
        <f7-page>
          <f7-navbar title="Asociar a pedido">
            <f7-nav-right>
              <f7-link popup-close>{{$t('common.cerrar')}}</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <r-form 
            ref="rformAsociar" 
            @save="onSaveAsociar"
            :dbAdapter="null"
            classContent="full-height"
            :readonly="false"
            :defaultData="{idExpediente: selectedEvent.planificacion.idExpediente}"
          >
            <template v-slot:default="{ rform }">
              <f7-card class="elevation-3 padding-vertical-half padding-right">
                <f7-card-content>
                  <f7-list class="list-form">
                    <ul>
                      <f7-row>
                        <f7-col width="100" medium="100">
                          <r-input
                            :clearButton="false"
                            floating-label
                            outline
                            label="Número o código de pedido"
                            :form="rform"
                            name="numeroOCodigoPedido"
                          />
                          <r-input
                            :clearButton="false"
                            outline
                            floating-label
                            label="Número de línea"
                            :form="rform"
                            type="number"
                            name="numeroLinea"
                          />
                        </f7-col>
                      </f7-row>
                    </ul>
                  </f7-list>
                </f7-card-content>
              </f7-card>
              <f7-row>
                <f7-col width="100" medium="100">
                  <f7-button
                      fill
                      icon-f7="disk"
                      text="Asociar"
                      class="display-flex margin-vertical margin-left"
                      color="green"
                      @click="rform.saveItem()"
                    ></f7-button>
                </f7-col>
              </f7-row>
            </template>
          </r-form>
        </f7-page>

      </f7-popup>
      <f7-popup v-if="popupCalcularRutaOpened" ref="popupCalcularRuta" :opened="true" swipe-to-close @popup:closed="popupCalcularRutaOpened = false" @popup:opened="onOpenCalcularRuta">
        <f7-page>
          <f7-navbar title="Cálculo de ruta">
            <f7-nav-right>
              <f7-link popup-close>{{$t('common.cerrar')}}</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <r-form 
            ref="rformCalucarRuta" 
            @save="onSaveCalcularRuta"
            :dbAdapter="null"
            classContent="full-height"
            :readonly="false"
            :defaultData="{dia: diaSelected, inspector: inspectorFiltro, direccionInicio: '', direccionFin: '', horaInicio: new Date()}"
          >
            <template v-slot:default="{ rform }">
              <f7-card class="elevation-3 padding-vertical-half padding-right">
                <f7-card-content>
                  <f7-list class="list-form">
                    <ul>
                      <f7-row>
                        <f7-col width="100" medium="100">
                          <!-- <r-timepicker
                            outline
                            floating-label
                            label="Hora Inicio"
                            :form="rform"
                            name="horaInicio"
                            :clearButton="false"
                          ></r-timepicker> -->
                          <r-input
                            :clearButton="false"
                            floating-label
                            outline
                            label="Dirección Inicio"
                            :form="rform"
                            name="direccionInicio"
                          />
                          <r-input
                            :clearButton="false"
                            floating-label
                            outline
                            label="Dirección Fin"
                            :form="rform"
                            name="direccionFin"
                          />
                        </f7-col>
                      </f7-row>
                    </ul>
                  </f7-list>
                </f7-card-content>
              </f7-card>
              <f7-row>
                <f7-col width="100" medium="100">
                  <f7-button
                      fill
                      icon-f7="disk"
                      text="Calcular"
                      class="display-flex margin-vertical margin-left"
                      color="green"
                      @click="rform.saveItem()"
                    ></f7-button>
                </f7-col>
              </f7-row>
            </template>
          </r-form>
        </f7-page>

      </f7-popup>
    </v-app>
  </f7-page>
</template>
<script>
import Planificacion from "./../../js/db/Planificacion.js";
import Fichaje from "./../../js/db/Fichaje.js";
import Inspeccion from "./../../js/db/Inspeccion.js";
import LineaPedido from "./../../js/db/LineaPedido.js";
import Pedido from "./../../js/db/Pedido.js";
import Empleado from "./../../js/db/Empleado.js";
import TipoTarea from "./../../js/db/TipoTarea.js";
import Servicio from "./../../js/db/Servicio.js";
import rInput from "./../../components/rInput.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rDireccion from "./../../components/rDireccion.vue";
import Cliente from "./../../js/db/Cliente.js";
import rBadge from './../../components/rBadge';
import Utils from "./../../js/Utils.js";
import rForm from "./../../components/rForm.vue";
import DateUtils from '../../js/DateUtils';
import moment from 'moment';
import validator from "validator";
import YearCalendar from 'v-year-calendar';
import { EventBus } from "../../js/EventBus";
import 'v-year-calendar/locales/v-year-calendar.es';

// import 'vuetify/dist/vuetify.min.css';


  export default {
    components: {
      rInput,
      rForm,
      rBadge,
      rAutocomplete,
      rDireccion,
      rTimepicker,
      YearCalendar
    },
    data: () => ({
      TipoTarea,
      Servicio,
      Cliente,
      Fichaje,
      validator,
      moment,
      value: '',
      type: process.env.TARGET && process.env.TARGET == 'cordova' ? 'day' : 'week',
      types: [
        {
          nombre: 'Mes',
          valor: 'month'
        },
        {
          nombre: 'Semana',
          valor: 'week'
        },
        {
          nombre: 'Día',
          valor: 'day'
        }],
      meses: [
        {
          nombre: 'Enero',
          valor: 1
        },
        {
          nombre: 'Febrero',
          valor: 2
        },
        {
          nombre: 'Marzo',
          valor: 3
        },
        {
          nombre: 'Abril',
          valor: 4
        },
        {
          nombre: 'Mayo',
          valor: 5
        },
        {
          nombre: 'Junio',
          valor: 6
        },
        {
          nombre: 'Julio',
          valor: 7
        },
        {
          nombre: 'Agosto',
          valor: 8
        },
        {
          nombre: 'Septiembre',
          valor: 9
        },
        {
          nombre: 'Octubre',
          valor: 10
        },
        {
          nombre: 'Noviembre',
          valor: 11
        },
        {
          nombre: 'Diciembre',
          valor: 12
        }],
      selectedMonth: 0,
      events: [],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      colors: ['#2196F3', '#3F51B5', '#673AB7', '#00BCD4', '#4CAF50', '#FF9800', '#757575'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      dragEvent: null,
      previousDragEvent: null,
      nextDragEvent: null,
      initialDragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      Planificacion,
      LineaPedido,
      Pedido,
      Inspeccion,
      Empleado,
      DateUtils,
      inspectorFiltro: null,
      fechaDesdeFiltro: null,
      fechaHastaFiltro: null,
      Utils,
      rules: [
        value => !!value || 'Debe introducir un inspector'
      ],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      readOnlyPlanif: true,
      popupSelectDateOpened: false,
      popupCrearOpened: false,
      popupAsociarOpened: false,
      popupCalcularRutaOpened: false,
      clickedEvent: null,
      totalTotal: null,
      tiempoTotal: null,
      totalRealizado: null,
      totalPlanif: null,
      totalContratado: null,
      totalTotalKm: null,
      totalTotalKmParticular: null,
      totalTotalConsumo: null,
      totalTotalConsumoParticular: null,
      totalTiempoDesplazamiento: null,
      totalTiempoDesplazamientoParticular: null,
      totalTiempoTPIR: null,
      totalTiempoTPIP: null,
      totalImporteTPIR: null,
      totalImporteTPIP: null,
      totalTiempoTPC: null,
      infPdtes: null,
      diaSelected: false,
      target: process.env.TARGET,
      diarios: [],
      rolesInspectorSuma: ['INSPECTOR', 'SUPERVISADO'],
      roles: ['INSPECTOR', 'OBSERVADOR', 'SUPERVISOR', 'SUPERVISADO', 'SUPERVISADO_FORMACION'],
      precioMinuto: 8.33,
      dialogFilter: false,
      filterData: {},
      filterOptions: {
        tipos: [
          {
            value: 'LINEA',
            text: 'Inspección'
          },
          {
            value: 'TAREA',
            text: 'Tarea'
          },
          {
            value: 'DESPLAZAMIENTO',
            text: 'Desplazamiento'
          },
          {
            value: 'FICHAJE',
            text: 'Fichaje'
          },
          {
            value: 'SINPEDIDO',
            text: 'Sin pedido'
          }
        ],
        tiposTareas: [
          {
            value: 'T0005',
            text: 'TPI'
          },
          {
            value: 'T0040',
            text: 'Firma de expediente'
          },
          {
            value: 'OTRAS',
            text: 'Otras'
          }
        ]
      },
    }),
    mounted: function () {
      const self = this;
      EventBus.$on("fichajeGuardado", this.getPlanificaciones);
      self.inspectorFiltro = self.username;
      self.$nextTick(() => {
        self.$refs.calendar.scrollToTime({hour:7,minute:0})
      });
      // if (self.inspectorFiltro) {
      //   self.loadInformesPdtes();
      // }
    },
    created: function() {
      var self = this;
      if (self.username == undefined || self.username == 'undefined')  {
        self.inspectorFiltro = Utils.getUser().username;
        self.username = self.inspectorFiltro;
        // app.views.current.router.navigate({url: "/calendario/" + self.inspectorFiltro + "/", ignoreCache: true});
        // window.open("#!/calendario/"+ self.inspectorFiltro + "/");
        // return;
      } else {
        self.inspectorFiltro = self.username;
      }
    },
    props: {
      username: {
        type: String,
      }

    },
    methods: {
      verTPIP: function() {

      },
      verPendientes: function(date) {
        const self = this;
        let url = '/lineaspedidos/filter/?filter={"onlyAggregations":false,"withoutAggregations":true,"activo":true,"lineas##camposUsuario##cpEj":["'+self.inspectorFiltro+'"],"lineas##camposUsuario##trabCm":["S"],"lineas##camposUsuario##trabOf":["N"],"estado##estado":["CREADO","PLANIFICADO","EN_EJECUCION","EJECUTADO","FACTURADO","CERRADO"],"fechaContratacionDesde":"2020-12-31T23:00:00.000Z","tipoReparto":"REPARTOEJECUCION"}';
        self.$f7router.navigate(url);
      },
      onSelectDate(data) {
        const self = this;
        self.value = self.moment(data.date).format('YYYY-MM-DD');
        self.$refs.popupSelectDate.close();
        // if (data) {
        //   if (!data.year || data.year.toString().length < 4) {
        //     data.year = new Date().getFullYear();
        //   }
        //   if (!data.month) {
        //     data.month = new Date().getMonth()
        //   }
        //   self.value = data.year.toString().padStart(4, '0') + '-' + data.month.toString().padStart(2, '0') + '-01';
        //   self.$refs.popupSelectDate.close();
        // }
      },
      getUrl(url) {
        const self = this;
        var currentUrl = new URL(window.location.href);
        if (self.target == 'cordova') {
          return '/' + url;
        }
        return currentUrl.pathname + ('#!/' + url);
      },
      getPathExpediente(planificacion) {
        const self = this;
        if (self.target == 'cordova'){
          if (planificacion.estado == "PLANIFICADA"){
            return 'inspecciones-offline/' + planificacion.idExpediente + '/';
          } else{
            return 'inspecciones/view-offline/' + planificacion.idExpediente + '/';
          }
        }
        return 'inspecciones/view/' + planificacion.idExpediente + '/';
      },
      getMinutesHumanize(minutes, withDays = true) {
        let duration = moment.duration(minutes, 'minutes');
        let str = "";
        if (duration.days() > 0 && withDays) {
          str += `${duration.days()} días `;
        }
        let horas = duration.hours();
        if (!withDays) {
          horas = horas + (duration.days() * 24);
        }
        if (horas > 0) {
          str += `${horas}h `;
        }
        str += `${duration.minutes()}min`;
        return str;
      },
      onCerrarDetalle() {
        const self = this;
        self.selectedOpen = false;
        self.readOnlyPlanif = true;
      },
      getColorBadgeEstado(event) {
        let color = "gray";
        if (event.planificacion && event.planificacion.estado) {
          switch (event.planificacion.estado) {
            case "PLANIFICADA":
              color = "blue";
              break;
            case "REALIZADA":
              color = "teal";
              break;
          }
        }
        return color;
      },
      getColorBadgeEstadoExpediente(estado) {
        let color = "gray";
        if (estado) {
          switch (estado) {
            case "GENERADO":
              color = "lime";
              break;
            case "INSPECCIONADO":
              color = "green";
              break;
            case "PLANIFICADO":
              color = "blue";
              break;
            case "AUDITADO":
              color = "teal";
              break;
            case "PDTE_FACTURAR":
              color = "purple";
              break;
            case "PDTE_CONFORMIDAD":
              color = "deeppurple";
              break;
            case "PDTE_ENVIO":
              color = "yellow";
              break;
            case "ENVIADO":
              color = "orange";
              break;
            case "CANCELADO":
              color = "red";
              break;
            case "PENDIENTE_REVISION_CALIDAD":
              color = "pink";
              break;
            case "CORREGIR_ERRORES_AUDITORIA_CALIDAD":
              color = "darkblue";
              break;
            case "PENDIENTE_FIRMA_INSPECTOR":
              color = "deeppurple";
              break;
            case "FIRMADO_INSPECTOR":
              color = "lightblue";
              break;
          }
        }
        return color;
      },
      getTextEstadoExpediente(estado) {
        let text = "No definido";
        if (estado) {
          switch (estado) {
            case "GENERADO":
              text = "Creado";
              break;
            case "INSPECCIONADO":
              text = "Inspeccionado";
              break;
            case "PLANIFICADO":
              text = "Planificado";
              break;
            case "AUDITADO":
              text = "Auditado";
              break;
            case "PDTE_FACTURAR":
              text = "Pdte. Facturar";
              break;
            case "PDTE_CONFORMIDAD":
              text = "Pdte. Confirmar";
              break;
            case "PDTE_ENVIO":
              text = "Pdte. Envío";
              break;
            case "ENVIADO":
              text = "Enviado";
              break;
            case "CANCELADO":
              text = "Cancelado";
              break;
            case "PENDIENTE_REVISION_CALIDAD":
              text = "Pdte. de revisión (calidad)";
              break;
            case "CORREGIR_ERRORES_AUDITORIA_CALIDAD":
              text = "Corregir errores (calidad)";
              break;
            case "PENDIENTE_FIRMA_INSPECTOR":
              text = "Pdte. de firma";
              break;
            case "FIRMADO_INSPECTOR":
              text = "Certificado firmado";
              break;
          }
        }
        return text;
      },
      guardarCambios() {
        var self = this;
        var lineaPlan = self.selectedEvent;
        if (lineaPlan.anadirInspector) {
          lineaPlan.planificacion.inspectores.push({username: lineaPlan.anadirInspector, rol:(lineaPlan.rolInspector?lineaPlan.rolInspector:'INSPECTOR') })
        }
        var data = {lineas: [lineaPlan.planificacion]};
        Planificacion.save({
          data: data,
          itemId: null
        }).then(function(res) {
  
        }).catch(function(e) {
          if (typeof e == "string") {
            e = {
              message: e
            };
          }
          app.dialog.alert(e.message);
          self.errors = e.errors || {};
        }).finally(function(res) {
          // self.selectedEvent = {};
          self.getPlanificaciones();
        });
      },
      onSaveCrear () {
        const self = this;
        const app = self.$f7;
        var crearPlani = self.$refs.rformCrear.formData;
        crearPlani.inspectores = [{username: self.inspectorFiltro, rol: 'INSPECTOR'}];
        if (crearPlani.inspeccionSinPedido) {
          crearPlani.inspeccionSinPedido.horaInicio = crearPlani.FechaVisita;
        }
        if (crearPlani.tarea) {
          crearPlani.tarea.username = self.inspectorFiltro;
        }
        app.preloader.show();

        Planificacion.save({
          data: crearPlani,
          itemId: null
        }).then(function(res) {
          self.$refs.popupCrear.close();
        }).catch(function(e) {
          if (typeof e == "string") {
            e = {
              message: e
            };
          }
          app.dialog.alert(e.message);
          self.errors = e.errors || {};
        }).finally(function(res) {
          app.preloader.hide();
          self.getPlanificaciones();
        });
      },
      onSaveAsociar () {
        const self = this;
        const app = self.$f7;
        var asociarPlani = self.$refs.rformAsociar.formData;
        app.preloader.show();
        var asociacion = {
          idExpediente: asociarPlani.idExpediente,
          numeroOCodigoPedido: asociarPlani.numeroOCodigoPedido,
          numeroLineaPedido: asociarPlani.numeroLinea
        };
        app.preloader.show();
        Inspeccion.asociarPedido(asociacion).then(res => {
          self.$refs.popupAsociar.close();
          app.dialog.alert("Asociación realizada con éxito");
          // app.$f7.views.current.router.navigate('/ofertas/view/' + rform.formData.idOferta + '/')
          // return res;
        }).catch(e => {
          if (typeof e == "string") {
            e = {
              message: e
            };
          }
          app.dialog.alert(e.message);
        }).then(function(){
          app.preloader.hide();
          self.getPlanificaciones();
        });
      },
      borrarEvento (evento) {
        var self = this;
        var i = self.events.indexOf(self.evento);
        self.events.splice(i, 1);
        self.selectedEvent = {};
        self.selectedElement= null;
        self.selectedOpen= false; 
        self.readOnlyPlanif= true;   
      },  

      borrarPlanificacion(plani) {
        var self = this;
        const app = self.$f7;
        self.$f7.dialog.confirm(
          "¿Está seguro que desea desplanificar este evento para el empleado " + self.inspectorFiltro + " ?",
          "Advertencia",
          function() {
            app.preloader.show();
            if (plani.esQuedada == null) {
              plani.esQuedada = false;
            }
            Planificacion.borrarInspector({data: {inspector: self.inspectorFiltro, idPlani: plani.id, idPedido: plani.idPedido, numeroLinea: plani.numLinea, esQuedada: plani.esQuedada}})
            .then(_ => {
              app.dialog.alert("Planificación para el empleado " + self.inspectorFiltro + " borrada correctamente.");
              self.getPlanificaciones();
            }).catch(e => {
              if (typeof e == "string") e = { error: e };
              let error = e.message || e.error || e.error_description;
              app.dialog.alert(error);
            }).then(_ => app.preloader.hide());
          } 
        )
      },
      borrarFichaje(idFichaje) {
        const self = this;
        const app = self.$f7;
        app.dialog.confirm(
          'Si elimina un registro, se eliminará el rango completo de Entrada y Salida, ¿esta seguro?',
          "Eliminar",
          function () {
            Fichaje.delete({ itemId: idFichaje }).then(function (res) {
              if (res) {
                app.dialog.alert("Fichaje borrado correctamente.");
                self.getPlanificaciones();
              }
            }).catch(function (error) {
              app.dialog.alert(error.message);
            });
          }
        );
      },
      showEvent ({ nativeEvent, event }) {
        var self = this;
        const open = () => {
          self.selectedEvent = event
          self.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => self.selectedOpen = true))
        }
        if (self.selectedOpen) {
          self.selectedOpen = false;
          self.readOnlyPlanif = true;
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }
        nativeEvent.stopPropagation()
      },
      viewDay ({ date }) {
        const self = this;
        self.value = date;
        self.type = 'day';
      },
      open(url) {
        const self = this;
        const app = self.$f7;
        if (self.target && self.target == 'cordova') {
          app.views.current.router.navigate({url: url, ignoreCache: true});
        } else {
          window.open('#!' + url);
        }
      },
      getPlanificaciones() {
        const self = this;
        const app = self.$f7;
        self.readOnlyPlanif = true;
        self.Planificacion.clearCache();
        if (self.inspectorFiltro) {
          self.inspectorFiltro = self.inspectorFiltro.toUpperCase();
        }
        if(self.inspectorFiltro != null && self.inspectorFiltro != '' && self.inspectorFiltro != self.username && self.target != 'cordova') {
          self.open("/calendario/" + self.inspectorFiltro + "/");
          self.inspectorFiltro = self.username;
          return;
        }

        if (self.inspectorFiltro == null || self.inspectorFiltro == '' || self.inspectorFiltro == undefined || self.inspectorFiltro == 'undefined') {
          if (self.username == undefined || self.username == 'undefined')  {
            self.inspectorFiltro = Utils.getUser().username;
            if (self.target != 'cordova') {
              self.open("/calendario/" + self.inspectorFiltro + "/");
              return;
            }
          } else {
            self.inspectorFiltro = location.href.split('/')[location.href.split('/').length-2];
          }
        }

        self.Pedido.getList({
          projection: "totalSinIVASinTasasConDescuento",
          filter: {
            withoutAggregations: false,
            onlyAggregations: true,
            empleadoContratacion: self.inspectorFiltro,
            fechaContratacionDesde: self.fechaDesdeFiltro,
            fechaContratacionHasta: self.fechaHastaFiltro,
            estados: ['CREADO', 'EN_EJECUCION', 'PLANIFICADO', 'EJECUTADO', 'FACTURADO', 'CERRADO'],
            funcionesAgregadas: [
              {
                activa: true,
                nombre: "OEA",
                formato: "2",
                sufijo: "€",
                field: "totalSinIVASinTasasConDescuento",
                operacion: "SUM",
                as: "totalSinIVASinTerc",
                valorVacio: "0",
                roles: [],
                includeExcludeRoles: "EXCLUDE",
                usernames: [],
                includeExcludeUsernames: "EXCLUDE",
              }
            ]
          }
        }).then(function(res) {
          if (res && res.funcionesAgregadas && res.funcionesAgregadas.length > 0) {
            const formatter = new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 2,      
              maximumFractionDigits: 2,
              useGrouping: false
            });
            self.totalContratado = formatter.format(res.funcionesAgregadas[0].valor).toString().replace('.',',') + ' €';
          } else {
            self.totalContratado = null;
          }
        }).catch(function(e) {
          if (typeof e == "string") e = { error: e };
          let error = e.message || e.error || e.error_description;
          app.dialog.alert(error);
        });

        return new Promise(function(resolve, reject) { 
          if (!self.inspectorFiltro) {
            app.dialog.alert("Debe introducir un inspector.");
          } else {
            document.title = self.inspectorFiltro.toUpperCase();
            self.Planificacion
              .getList({
                filter: {
                  inspector: self.inspectorFiltro,
                  fechaDesde: self.fechaDesdeFiltro,
                  fechaHasta: self.fechaHastaFiltro,
                }
              })
              .then(function(res) {
                self.events = [];
                if (res && res.list && res.list.length) {
                    res.list.forEach(l => {
                      let nombre = l.tipo;
                      if (l.tipo == 'TAREA') {
                        nombre = (l.codigoTarea + ' - ' + l.nombreTarea);
                      } else if (l.servicio && l.servicio.codigo) {
                          if (l.servicio.materia) {
                            nombre = l.servicio.materia.codigo + ' - ' + l.servicio.nombre;
                          } else {
                            nombre = l.servicio.codigo + ' - ' + l.servicio.nombre;
                          }
                      } else if (l.formato) {
                        nombre = (l.formato.materia ? l.formato.materia.codigo : l.formato.codigo) + " - " + (l.formato.nombre ? l.formato.nombre : (l.formato.materia ? l.formato.materia.nombre : ""));
                      } else if (l.tipo == 'SINPEDIDO') {
                        nombre = 'INSPECCIÓN SIN PEDIDO';
                      } else if (l.tipo == 'DESPLAZAMIENTO') {
                        if (l.moviloc) {
                          const formatter = new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 0,      
                            maximumFractionDigits: 0,
                            useGrouping: false
                          });
                          nombre = formatter.format(parseInt(l.moviloc.distancia ? l.moviloc.distancia : 0) / 1000).toString() + ' Km - ' + (l.tiempoEstimadoCampo ? self.getMinutesHumanize(l.tiempoEstimadoCampo) : l.moviloc.duracion);
                          if (l.moviloc.matricula) {
                            nombre = nombre + " - " + l.moviloc.matricula;
                          }
                        } else {
                          nombre = l.desplazamiento.distance.humanReadable + ' - ' + l.desplazamiento.duration.humanReadable;
                        }
                      }
                      let planifEvent = {
                        name: nombre + (l.tipo == 'FICHAJE' ? (' ' + l.tipoFichaje + ' ' + DateUtils.dateFormat(l.horaInicio, 'HH:mm')) : ''),
                        color: self.getColorEvento(l),
                        colorLetras: self.getColorLetrasEvento(self.getColorEvento(l)),
                        start: new Date(l.horaInicio).getTime(),
                        end: new Date(l.horaInicio).getTime() + (l.tiempoEstimadoCampo ?  l.tiempoEstimadoCampo * 60000 : 0),
                        timed: true,
                        planificacion: l
                      }
                      self.events.push(planifEvent);
                    });
                    if (self.filterData && Object.keys(self.filterData).length > 0) {
                      self.events = self.events.filter(e => {
                        let include = true;
                        for (let key in self.filterData) {
                          if (key == 'tipoTarea') {
                            if (self.filterData[key] == 'OTRAS') {
                              if (e.planificacion[key] && e.planificacion[key].codigo && ['T0005', 'T0040'].includes(e.planificacion[key].codigo)) {
                                include = false;
                              }
                            } else if (self.filterData[key] && e.planificacion[key] && !self.filterData[key].includes(e.planificacion[key].codigo)) {
                              include = false;
                            }
                          } else if (self.filterData[key] && !self.filterData[key].includes(e.planificacion[key])) {
                            include = false;
                          }
                        }
                        return include;
                      });
                    }
                    self.totalTotal = self.totalImporte();
                    self.tiempoTotal = self.totalTiempoPorTipo(null, null, ['particular'], [false]);
                    self.totalRealizado = self.totalImporte('REALIZADA');
                    self.totalPlanif = self.totalImporte('PLANIFICADA');
                    self.totalTotalKm = self.totalKm();
                    self.totalTotalKmParticular = self.totalKm(true);
                    self.totalTotalConsumo = self.totalConsumo();
                    self.totalTotalConsumoParticular = self.totalConsumo(true);
                    self.totalTiempoDesplazamiento = self.totalTiempoPorTipo('DESPLAZAMIENTO', null, "particular", false);
                    self.totalTiempoDesplazamientoParticular = self.totalTiempoPorTipo('DESPLAZAMIENTO', null, "particular", true);
                    self.totalTiempoTPIR = self.totalTiempoPorTipo('TAREA', null, ['tipoTarea.codigo', 'estado'], ['T0005', 'REALIZADA']);
                    self.totalTiempoTPIP = self.totalTiempoPorTipo('TAREA', null, ['tipoTarea.codigo', 'estado'], ['T0005', 'PLANIFICADA']);
                    self.totalImporteTPIR = self.totalImporteFirma('REALIZADA');
                    self.totalImporteTPIP = self.getFormatImporte(self.totalTiempoPorTipo('TAREA', null, ['tipoTarea.codigo', 'estado'], ['T0005', 'PLANIFICADA'], true) * self.precioMinuto);
                    self.totalTiempoTPC = self.totalTiempoPorTipo(['LINEA', 'SINPEDIDO']);
                    resolve();
                } else {
                  // app.dialog.alert("No se han encontrado planificaciones para el inspector " + self.inspectorFiltro + " entre los días " + self.fechaDesdeFiltro + " y " + self.fechaHastaFiltro );
                  self.totalRealizado = null;
                  self.totalPlanif = null;
                  self.totalTotal = null;
                  self.tiempoTotal = null;
                  self.totalTotalKm = null;
                  self.totalTotalKmParticular = null;
                  self.totalTotalConsumo = null;
                  self.totalTotalConsumoParticular = null;
                  self.totalTiempoDesplazamiento = null;
                  self.totalTiempoDesplazamientoParticular = null;
                  self.totalTiempoTPIR = null;
                  self.totalTiempoTPIP = null;
                  self.totalImporteTPIR = null;
                  self.totalImporteTPIP = null;
                  self.totalTiempoTPC = null;
                  resolve();
                }
              })
              .catch(function(e) {
                if (typeof e == "string") e = { error: e };
                let error = e.message || e.error || e.error_description;
                app.dialog.alert(error);
                reject();
              });
          }
        }) 
      },

      getInformesPendientes(dia) {
        const self = this;
        let diario = self.getDiario(dia);
        if (diario) {
          return diario.informesPendientes;
        } else {
          return 0;
        }
      },

      getDiario(dia) {
        const self = this;
        let desde = moment(dia).startOf("day");
        let hasta = moment(dia).endOf("day");
        return self.diarios.filter(d => moment(d.dia).isBetween(desde,hasta))[0];
      },

      getDiarios() {
        const self = this;
        const app = self.$f7;
        if (self.inspectorFiltro) {
          self.inspectorFiltro = self.inspectorFiltro.toUpperCase();
          self.Planificacion
            .getDiarios({
              data: {
                inspector: self.inspectorFiltro,
                fechaDesde: self.fechaDesdeFiltro,
                fechaHasta: self.fechaHastaFiltro,
              }
            })
            .then(function(res) {
              if (res) {
                self.diarios = res.list;
              } else {
                self.diarios = [];
              }
            })
            .catch(function(e) {
              self.diarios = [];
            });
        }
      },

      getColorEvento (l) {
        if(l.tipo == 'FICHAJE') {
          return 'lime';
        }
        var str = l.tipo == 'TAREA' ? (l.tipoTarea.codigo + l.tipoTarea.nombre) : ((l.servicio && l.servicio.codigo) ?  l.servicio.codigo : (l.formato ? (l.formato.codigo ? l.formato.codigo : (l.formato.materia ? l.formato.materia.codigo : 'SIN MATERIA')) : l.tipo == 'SINPEDIDO' ? 'INSPECCIÓN SIN PEDIDO' : ( l.tipo ? l.tipo : 'SIN TIPO')));

        var hash = 0;
        for (var i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = '#';
        for (var i = 0; i < 3; i++) {
          var value = (hash >> (i * 8)) & 0xFF;
          colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour+'99';
      },

      getColorLetrasEvento (hexcolor) {

        // If a leading # is provided, remove it
        if (hexcolor.slice(0, 1) === '#') {
          hexcolor = hexcolor.slice(1);
        }

        // Convert to RGB value
        var r = parseInt(hexcolor.substr(0,2),16);
        var g = parseInt(hexcolor.substr(2,2),16);
        var b = parseInt(hexcolor.substr(4,2),16);

        // Get YIQ ratio
        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

        // Check contrast
        return (yiq >= 128) ? 'black' : 'white';

      },

      log(str) {
        console.log(str);
      },

      startDrag ({ event, timed }) {
        var self = this;
        console.log("startdrag");
        if(event && timed) {
          self.clickedEvent = event;
        } 
        if (event && timed && ((event.planificacion && (event.planificacion.tipo == 'TAREA' || (event.planificacion.tipo == 'FICHAJE' && Utils.getUser() && event.planificacion.inspectores[0] && Utils.getUser().username == event.planificacion.inspectores[0].username))) || !(event.planificacion && event.planificacion.estado && event.planificacion.estado == 'REALIZADA'))) {
          self.dragEvent = event;
          self.previousDragEvent = null;
          self.nextDragEvent = null;
          if (self.dragEvent && self.dragEvent.planificacion && self.dragEvent.planificacion.tipo && self.dragEvent.planificacion.tipo == 'FICHAJE') {
            let fichajes = self.events.filter(e => e.planificacion && e.planificacion.tipo == 'FICHAJE');
            // Obtener en previousDragEvent el anterior fichaje de dragEvent por el campo start que es un timemillis
            self.previousDragEvent = fichajes.filter(e => e.start < self.dragEvent.start).sort((a, b) => b.start - a.start)[0];
            // Obtener en nextDragEvent el siguiente fichaje de dragEvent por el campo start que es un timemillis
            self.nextDragEvent = fichajes.filter(e => e.start > self.dragEvent.start).sort((a, b) => a.start - b.start)[0]; 
          }
          
          self.initialDragEvent = JSON.parse(JSON.stringify(event));
          self.dragTime = null;
          self.extendOriginal = null;
        }
      },
      startTime (tms) {
        var self = this;
        const mouse = self.toTime(tms)

        if (self.dragEvent && self.dragTime === null) {
          const start = self.dragEvent.start

          self.dragTime = mouse - start
        } else {
          if (self.selectedOpen) {
            self.selectedOpen = false;
          }
          else {
            self.createStart = self.roundTime(mouse);
            if (!(self.clickedEvent && self.clickedEvent.planificacion && self.clickedEvent.planificacion.estado && self.clickedEvent.planificacion.estado == 'REALIZADA')){
              self.popupCrearOpened = true;
            }
          }
        }
      },
      extendBottom (event) {
        var self = this;
        self.createEvent = event
        self.createStart = event.start
        self.extendOriginal = event.end
      },
      mouseMove (tms) {
        var self = this;
        const mouse = self.toTime(tms);
        
        
        if (self.dragEvent && self.dragTime !== null) {
          console.log("mouseMove");
          const start = self.dragEvent.start
          const end = self.dragEvent.end
          const duration = end - start
          const newStartTime = mouse - self.dragTime
          const newStart = self.roundTime(newStartTime)
          const newEnd = newStart + duration

          if (self.dragEvent.planificacion && self.dragEvent.planificacion.tipo == 'FICHAJE') {
            // si newStart es en un dia diferente hacemos return
            if (newStart < new Date(self.initialDragEvent.start).setHours(0, 0, 0, 0)) {
              return;
            }
            // comprobamos tambien si lo intentamos mover al dia siguiente
            if (newStart > new Date(self.initialDragEvent.start).setHours(0, 0, 0, 0) + 86400000) {
              return;
            }
            // Tambien hay que comprobar que no sea posterior a la hora actual
            if (newStart > new Date().getTime()) {
              return;
            }

          }

          if (!self.previousDragEvent || newStart > self.previousDragEvent.start) {
            if (!self.nextDragEvent || newEnd < self.nextDragEvent.start) {
              self.dragEvent.start = newStart
              self.dragEvent.end = newEnd
              if (self.dragEvent.planificacion) {
                self.dragEvent.planificacion.horaInicio = new Date (newStart);
                self.dragEvent.planificacion.tiempoEstimadoCampo = (newEnd - newStart)/60000;
              }
            }
          }

          // self.dragEvent.start = newStart
          // self.dragEvent.end = newEnd

          // if (self.dragEvent.planificacion) {
          //   self.dragEvent.planificacion.horaInicio = new Date (newStart);
          //   self.dragEvent.planificacion.tiempoEstimadoCampo = (newEnd - newStart)/60000;
          // }
        } else if (self.createEvent && self.createStart !== null) {
          const mouseRounded = self.roundTime(mouse, false)
          const min = Math.min(mouseRounded, self.createStart)
          const max = Math.max(mouseRounded, self.createStart)

          self.createEvent.start = min
          self.createEvent.end = max
          if (self.createEvent.planificacion) {
            self.createEvent.planificacion.horaInicio = new Date (self.createEvent.start);
            self.createEvent.planificacion.tiempoEstimadoCampo = (self.createEvent.end - self.createEvent.start)/60000;
          }
        }
      },
      endDrag () {
        var self = this;
        var lineaPlan = null;
        let enviar = true;
        if (self.dragEvent) {
          if (self.initialDragEvent && self.dragEvent.start == self.initialDragEvent.start && self.dragEvent.end == self.initialDragEvent.end) {
            enviar = false;
          }
          lineaPlan = self.dragEvent;
        } else if (self.createEvent) {
          lineaPlan = self.createEvent;
        }

        self.selectedEvent = lineaPlan;

        if (lineaPlan && lineaPlan.planificacion && lineaPlan.planificacion.id && enviar) {
          
          var data = {lineas: [lineaPlan.planificacion]};
  
          Planificacion.save({
            data: data,
            itemId: null
          }).then(function(res) {

          }).catch(function(e) {
            if (typeof e == "string") {
              e = {
                message: e
              };
            }
            app.dialog.alert(e.message);
            self.errors = e.errors || {};
          }).finally(function(res) {
              self.getPlanificaciones();
          });
        }
        self.dragTime = null
        self.dragEvent = null
        self.createEvent = null
        self.createStart = null
        self.extendOriginal = null
        self.clickedEvent = null
      },
      cancelDrag () {
        var self = this;
        if (self.createEvent) {
          if (self.extendOriginal) {
            self.createEvent.end = self.extendOriginal
            if (self.createEvent.planificacion) {
              self.createEvent.planificacion.tiempoEstimadoCampo = (self.extendOriginal - self.createEvent.start)/60000;
            }
          } else {
            const i = self.events.indexOf(self.createEvent)
            if (i !== -1) {
              self.events.splice(i, 1)
            }
          }
        }

        self.createEvent = null
        self.createStart = null
        self.dragTime = null
        self.dragEvent = null
        self.clickedEvent = null
      },
      roundTime (time, down = true) {
        const roundTo = 5 // minutes
        const roundDownTime = roundTo * 60 * 1000

        return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
      },
      toTime (tms) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
      },
      getEventColor (event) {
        return event ? event.color : '#fff';
      },
      getEvents ({ start, end }) {

        const self = this;

        if (self.inspectorFiltro == null || self.inspectorFiltro == undefined  || self.inspectorFiltro == 'undefined') {

          if (self.username == undefined || self.username == 'undefined') {
            self.inspectorFiltro = Utils.getUser().username;
            self.open("/calendario/" + self.inspectorFiltro + "/");
            return;
          } else {
            self.inspectorFiltro = location.href.split('/')[location.href.split('/').length-2];
          }
        }
        self.fechaDesdeFiltro = start.date;
        self.fechaHastaFiltro = end.date;

        self.getPlanificaciones();
        self.getDiarios();
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      rndElement (arr) {
        var self = this;
        return arr[self.rnd(0, arr.length - 1)]
      },
      esInspector(plani) {
        const self = this;
        return !!plani.inspectores.filter(i => i.username == self.inspectorFiltro && self.rolesInspectorSuma.includes(i.rol)).length;
      },
      esVersion(plani) {
        const self = this;
        return plani.tipo == 'LINEA' && plani.codigoExpediente && plani.codigoExpediente.split('/').length == 7;
      },
      horasFichajeDia: function(fecha) {
        var self = this;
        var total = 0;
        // vamos a filtrar todas las planificaciones tipo FICHAJE, sumando las horas de inicio entre ENTRADA y SALIDA consecutivas
        if (self.events && self.events.length > 0) {
          // filtramos los eventos de FICHAJE del día y los ordenamos por hora de inicio
          var eventosFichaje = self.events.filter(e => e.planificacion.tipo == 'FICHAJE' && (!fecha || moment(e.planificacion.horaInicio).isSame(fecha, 'day')))
                                          .sort((a, b) => moment(a.planificacion.horaInicio).isBefore(moment(b.planificacion.horaInicio)) ? -1 : 1);
           // si la lista de eventos de fichaje empieza por una salida, es que la entrada es del día anterior, así que meteremos al principio un evento de entrada falso con hora de inicio 00:00
          if (eventosFichaje.length > 0 && eventosFichaje[0].tipoFichaje == 'SALIDA') {
            eventosFichaje.unshift({planificacion: {horaInicio: moment(fecha).startOf('day').format('YYYY-MM-DDTHH:mm:ss')}});
          }
          // vamos sumando en total diferencias de horas de inicio entre eventos consecutivos SALIDA - ENTRADA (en horas y minutos) y si no hay salida, detenemos el proceso
          for (var i = 0; i < eventosFichaje.length; i++) {
            if (eventosFichaje[i].planificacion.tipoFichaje == 'ENTRADA') {
              if (i < eventosFichaje.length - 1 && eventosFichaje[i + 1].planificacion.tipoFichaje == 'SALIDA') {
                total += moment(eventosFichaje[i + 1].planificacion.horaInicio).diff(moment(eventosFichaje[i].planificacion.horaInicio), 'seconds');
              } else {
                break;
              }
            }
          }
        }
        // generamos un string con el total de horas y minutos en lugar de solo minutos, pondremos Xh Ym
        var horas = Math.floor(total / 3600);
        var minutos = Math.round((total % 3600) / 60);
        return horas + 'h ' + minutos + 'm';
      },
      totalImporteDia (dia) {
        var self = this;
        var total = 0;
        var desde = moment(dia).startOf("day");
        var hasta = moment(dia).endOf("day");
        let expedientesCargados = [];
        if (self.events && self.events.length > 0) {
          for (var j = 0; j < self.events.length; j++) {
            var plani = self.events[j].planificacion;
            if ((!plani.codigoExpediente || expedientesCargados.indexOf(plani.codigoExpediente) < 0) && self.esInspector(plani) && !self.esVersion(plani)) {
              if (plani && plani.totalSinIVASinTasasConDescuento && plani.horaInicio &&
                (plani.tipo != 'TAREA' || !plani.tipoTarea || (plani.tipoTarea.codigo != 'T0005' && plani.tipoTarea.codigo != 'T0040'))
              ) {
                if (plani.codigoExpediente) {
                  expedientesCargados.push(plani.codigoExpediente);
                }
                var horaPlani = moment(plani.horaInicio);
                if (horaPlani.isBetween(desde,hasta)) {
                  total+= plani.totalSinIVASinTasasConDescuento;
                }
              }
            }
          }
        }
        const formatter = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,      
          maximumFractionDigits: 2,
          useGrouping: false
        });
        return !total ? total : (formatter.format(total).toString().replace('.',',') + ' €');
      },
      totalImporteDiaFirma (dia, estado) {
        var self = this;
        var total = 0;
        var desde = moment(dia).startOf("day");
        var hasta = moment(dia).endOf("day");
        let expedientesCargados = [];
        if (self.events && self.events.length > 0) {
          for (var j = 0; j < self.events.length; j++) {
            var plani = self.events[j].planificacion;
            if ((!plani.codigoExpediente || expedientesCargados.indexOf(plani.codigoExpediente) < 0) && self.esInspector(plani) && !self.esVersion(plani)) {
              if (plani && plani.totalSinIVASinTasasConDescuento && plani.horaInicio &&
                (plani.tipo == 'TAREA' && plani.tipoTarea && plani.tipoTarea.codigo == 'T0040') &&
                (!estado || estado == plani.estado) &&
                (typeof plani.sumarImporte == 'undefined' || plani.sumarImporte)
              ) {
                if (plani.codigoExpediente) {
                  expedientesCargados.push(plani.codigoExpediente);
                }
                var horaPlani = moment(plani.horaInicio);
                if (horaPlani.isBetween(desde,hasta)) {
                  total+= plani.totalSinIVASinTasasConDescuento;
                }
              }
            }
          }
        }
        const formatter = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,      
          maximumFractionDigits: 2,
          useGrouping: false
        });
        return !total ? total : (formatter.format(total).toString().replace('.',',') + ' €');
      },
      totalKmDia (dia, particular) {
        var self = this;
        var total = 0;
        var desde = moment(dia).startOf("day");
        var hasta = moment(dia).endOf("day");
        if (self.events && self.events.length > 0) {
          for (var j = 0; j < self.events.length; j++) {
            if (self.events[j].planificacion && self.events[j].planificacion.tipo == 'DESPLAZAMIENTO' 
              && ((particular && self.events[j].planificacion.particular) || (!particular && !self.events[j].planificacion.particular))
            ) {
              var plani = self.events[j].planificacion;
              if (plani.horaInicio) {
                var horaPlani = moment(plani.horaInicio);
                if (horaPlani.isBetween(desde,hasta)) {
                  if (plani.moviloc) {
                    total += parseInt(plani.moviloc.distancia);
                  } else {
                    total+= plani.desplazamiento.distance.inMeters;
                  }
                }
              }
            }
          }
        }
        total = total / 1000;
        const formatter = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 0,      
          maximumFractionDigits: 0,
          useGrouping: false
        });
        if (!total) {
          return total;
        }
        return formatter.format(total).toString() + ' Km';
      },
      totalConsumoDia (dia, particular) {
        var self = this;
        var total = 0;
        var desde = moment(dia).startOf("day");
        var hasta = moment(dia).endOf("day");
        if (self.events && self.events.length > 0) {
          for (var j = 0; j < self.events.length; j++) {
            if (self.events[j].planificacion && self.events[j].planificacion.tipo == 'DESPLAZAMIENTO' 
              && ((particular && self.events[j].planificacion.particular) || (!particular && !self.events[j].planificacion.particular))
            ) {
              var plani = self.events[j].planificacion;
              if (plani.horaInicio) {
                var horaPlani = moment(plani.horaInicio);
                if (horaPlani.isBetween(desde,hasta)) {
                  if (plani.moviloc && plani.moviloc.consumo) {
                    total += parseFloat(plani.moviloc.consumo);
                  }
                }
              }
            }
          }
        }
        const formatter = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 1,      
          maximumFractionDigits: 1,
          useGrouping: false
        });
        if (!total) {
          return total;
        }
        return formatter.format(total).toString();
      },
      numEventosDia (dia, tipo) {
        var self = this;
        var total = 0;
        var desde = moment(dia).startOf("day");
        var hasta = moment(dia).endOf("day");
        if (self.events && self.events.length > 0) {
          for (var j = 0; j < self.events.length; j++) {
            if (self.events[j].planificacion) {
              var plani = self.events[j].planificacion;
              if (plani.horaInicio) {
                var horaPlani = moment(plani.horaInicio);
                if (horaPlani.isBetween(desde,hasta)) {
                  if (tipo == undefined || tipo == null || tipo == plani.tipo) {
                    total+=1;
                  }
                }
              }
            }
          }
        }
        return total;
      },
      getFormatImporte(importe) {
        const self = this;
        const formatter = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,      
          maximumFractionDigits: 2,
          useGrouping: false
        });
        return !importe ? importe : formatter.format(importe).toString().replace('.',',') + ' €';
      },
      totalImporte (estado) {
        const self = this;
        var totalTotal = 0;
        let expedientesCargados = [];
        if (self.events && self.events.length > 0) {
          for (var j = 0; j < self.events.length; j++) {
            if (self.events[j].planificacion && self.events[j].planificacion.totalSinIVASinTasasConDescuento) {
              if ((self.events[j].planificacion.tipo != 'TAREA' || !self.events[j].planificacion.tipoTarea || (self.events[j].planificacion.tipoTarea.codigo != 'T0005' && self.events[j].planificacion.tipoTarea.codigo != 'T0040')) 
                  && (!estado || estado == self.events[j].planificacion.estado)) {
                if ((!self.events[j].planificacion.codigoExpediente || expedientesCargados.indexOf(self.events[j].planificacion.codigoExpediente) < 0) && self.esInspector(self.events[j].planificacion) && !self.esVersion(self.events[j].planificacion)) {
                  console.log(self.events[j].planificacion.codigoExpediente);
                  console.log(self.events[j].planificacion.totalSinIVASinTasasConDescuento);
                  if (self.events[j].planificacion.codigoExpediente) {
                    expedientesCargados.push(self.events[j].planificacion.codigoExpediente);
                  }
                  totalTotal+= self.events[j].planificacion.totalSinIVASinTasasConDescuento;
                }
              }
            }
          }
        }
        const formatter = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,      
          maximumFractionDigits: 2,
          useGrouping: false
        });
        return !totalTotal ? totalTotal : formatter.format(totalTotal).toString().replace('.',',') + ' €';
      },
      totalImporteFirma (estado) {
        const self = this;
        var totalTotal = 0;
        let expedientesCargados = [];
        if (self.events && self.events.length > 0) {
          for (var j = 0; j < self.events.length; j++) {
            if (self.events[j].planificacion && self.events[j].planificacion.totalSinIVASinTasasConDescuento) {
              if ((self.events[j].planificacion.tipo == 'TAREA' && self.events[j].planificacion.tipoTarea && self.events[j].planificacion.tipoTarea.codigo == 'T0040') 
                && (!estado || estado == self.events[j].planificacion.estado) && ((typeof self.events[j].planificacion.sumarImporte == 'undefined' || self.events[j].planificacion.sumarImporte))) {
                if ((!self.events[j].planificacion.codigoExpediente || expedientesCargados.indexOf(self.events[j].planificacion.codigoExpediente) < 0) && self.esInspector(self.events[j].planificacion) && !self.esVersion(self.events[j].planificacion)) {
                  console.log(self.events[j].planificacion.codigoExpediente);
                  console.log(self.events[j].planificacion.totalSinIVASinTasasConDescuento);
                  if (self.events[j].planificacion.codigoExpediente) {
                    expedientesCargados.push(self.events[j].planificacion.codigoExpediente);
                  }
                  totalTotal+= self.events[j].planificacion.totalSinIVASinTasasConDescuento;
                }
              }
            }
          }
        }
        const formatter = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,      
          maximumFractionDigits: 2,
          useGrouping: false
        });
        return !totalTotal ? totalTotal : formatter.format(totalTotal).toString().replace('.',',') + ' €';
      },
      fieldCondition(field, valField, plani) {
        const self = this;
        if (!field) {
          return true;
        }
        if (!Array.isArray(field)) {
          field = [field];
        }
        if (!Array.isArray(valField)) {
          valField = [valField];
        }
          
        let res = true;
        field.forEach((f, idx) => {
          const esFalse = f.startsWith('!');
          if (esFalse) {
            f = f.substr(1);
          }
          if (valField[idx] == true || valField[idx] == false) {
            if (valField[idx] == true && (esFalse ? self.getValue(f, plani) : !self.getValue(f, plani))) {
              res = false;
              return;
            } else if (valField[idx] == false && (esFalse ? !self.getValue(f, plani) : self.getValue(f, plani))) {
              res = false;
              return;
            }
          } else if (esFalse ? self.getValue(f, plani) == valField[idx] : self.getValue(f, plani) != valField[idx]) {
            res = false;
            return;
          }
        })
        return res;
      },
      totalTiempoPorTipo (tipo = null, dia = null, field = null, valField = null, returnMinutes = null) {
        const self = this;
        let totalTotal = 0;
        const desde = dia ? moment(dia).startOf("day") : null;
        const hasta = dia ? moment(dia).endOf("day") : null;
        const hoy = moment().startOf("day");
        if (typeof tipo == 'string') {
          tipo = [tipo];
        }
        // tenemos que excluir los eventos de tipo FICHAJE
        const eventos = self.events?self.events.filter(e => e.planificacion && e.planificacion.tipo != 'FICHAJE'):null;
        if (eventos && eventos.length > 0) {

          for (var j = 0; j < eventos.length; j++) {
            let plani = eventos[j].planificacion;
            if (!self.esVersion(plani) && plani && plani.tiempoEstimadoCampo && self.fieldCondition(field, valField, plani)) {
              if (!tipo || tipo.includes(plani.tipo)) {
                if (!desde || !hasta || (plani.horaInicio && moment(plani.horaInicio).isBetween(desde, hasta))) {
                  // Si no viene tipo hay que ignorar los anteriores a hoy si son TPI planificado
                  if (tipo || plani.tipo != 'TAREA' || (plani.tipoTarea.codigo != 'T0005' && plani.tipoTarea.codigo != 'T0040') || plani.estado != 'PLANIFICADA' || moment(plani.horaInicio).isAfter(hoy)) {
                    totalTotal+= plani.tiempoEstimadoCampo;
                  }
                }
              }
            }
          }
        }
        if (!totalTotal || returnMinutes) {
          return totalTotal;
        }
        return self.getMinutesHumanize(totalTotal, false);
      },
      getValue: function(name, obj) {
        var namespaces = name.split(".");
        var context = obj;
        for (var i = 0; i < namespaces.length; i++) {
          if (typeof context == "undefined") {
            return "";
          }
          context = context[namespaces[i]];
        }
        return context;
      },
      totalKm(particular) {
        var self = this;
        var totalTotal = 0;
        if (self.events && self.events.length > 0) {
          for (var j = 0; j < self.events.length; j++) {
            if (self.events[j].planificacion && self.events[j].planificacion.tipo && self.events[j].planificacion.tipo == 'DESPLAZAMIENTO'
              && ((particular && self.events[j].planificacion.particular) || (!particular && !self.events[j].planificacion.particular))
            ) {
              if (self.events[j].planificacion.moviloc) {
                totalTotal += parseInt(self.events[j].planificacion.moviloc.distancia);
              } else {
                totalTotal+= self.events[j].planificacion.desplazamiento.distance.inMeters;
              }
            }
          }
        }
        totalTotal = totalTotal / 1000;
        const formatter = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 0,      
          maximumFractionDigits: 0,
          useGrouping: false
        });
        return formatter.format(totalTotal).toString() + ' Km';
      },
      totalConsumo(particular) {
        var self = this;
        var totalTotal = 0;
        if (self.events && self.events.length > 0) {
          for (var j = 0; j < self.events.length; j++) {
            if (self.events[j].planificacion && self.events[j].planificacion.tipo && self.events[j].planificacion.tipo == 'DESPLAZAMIENTO'
              && ((particular && self.events[j].planificacion.particular) || (!particular && !self.events[j].planificacion.particular))
            ) {
              if (self.events[j].planificacion.moviloc && self.events[j].planificacion.moviloc.consumo) {
                totalTotal += parseFloat(self.events[j].planificacion.moviloc.consumo);
              }
            }
          }
        }
        const formatter = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 1,      
          maximumFractionDigits: 1,
          useGrouping: false
        });
        return totalTotal == 0 ? totalTotal : formatter.format(totalTotal).toString();
      },
      onOpenCalcularRuta() {
        const self = this;
        const app = self.$f7;
        self.Planificacion
          .getDiario({
            data: {
              dia: self.diaSelected,
              inspector: self.inspectorFiltro
            }
          })
          .then(function(res) {
            if (res) {
              let data = self.$refs.rformCalucarRuta.formData;
              data.direccionInicio = res.origen;
              data.direccionFin = res.destino;
              if (res.calculadoMoviloc) {
                app.dialog.alert("Ya se ha realizado la importación de los datos de Moviloc, no se puede modificar la ruta.");
                if (self.$refs.popupCalcularRuta) {
                  self.$refs.popupCalcularRuta.close();
                }
              }
              // data.horaInicio = res.horaInicio;
            }
          })
          .catch(function(e) {
            app.preloader.hide();
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
            self.getPlanificaciones();
          });
        
      },
      onSaveCalcularRuta() {
        const self = this;
        var data = self.$refs.rformCalucarRuta.formData;

        // var dateInicio = new Date (data.dia);
        // var horaInicio = new Date (data.horaInicio);
        
        // dateInicio.setHours(horaInicio.getHours());
        // dateInicio.setMinutes(horaInicio.getMinutes());
        // dateInicio.setSeconds(horaInicio.getSeconds());
        // dateInicio.setMilliseconds(horaInicio.getMilliseconds());

        // data.horaInicio = dateInicio;

        self.calcularRuta(data);
      },
      calcularRuta(data) {
        const self = this;
        const app = self.$f7;
        app.preloader.show();
        self.Planificacion
          .calcularRuta({
            data: data
          })
          .then(function(res) {
            if (self.$refs.popupCalcularRuta) {
              self.$refs.popupCalcularRuta.close();
            }
            app.preloader.hide();
            self.getPlanificaciones();
          })
          .catch(function(e) {
            app.preloader.hide();
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
            self.getPlanificaciones();
          });
      },
      // loadInformesPdtes() {
      //   const self = this;
      //   self.LineaPedido.getList({
      //     filter: {
      //       withoutAggregations: false,
      //       onlyAggregations: true,
      //       activo: true,
      //       tpcLinea: "S",
      //       tpiLinea: "N",
      //       usuEjecucionLinea: self.inspectorFiltro,
      //       tipoLinea: 'LINEA',
      //       fechaContratacionDesde: '2021-01-01T00:00:00Z',
      //       estados: ['CREADO', 'EN_EJECUCION', 'PLANIFICADO', 'EJECUTADO', 'FACTURADO', 'CERRADO']
      //     }
      //   }).then(function(res) {
      //     self.infPdtes = res.totalCount;
      //   }).catch(function(e) {
      //     if (typeof e == "string") e = { error: e };
      //     let error = e.message || e.error || e.error_description;
      //     app.dialog.alert(error);
      //   });
      // }
    }
  }
</script>
<style>
.month-container {
  margin-top: 20px;
}
.calendar .calendar-header table th:hover {
    color: black;
}
.v-application {
  max-height:95% !important;
}
.v-application--wrap {
  min-height:0 !important;
}
.v-application .v-menu__content {
  position: fixed !important;
}
.expand-header-calendar .v-expansion-panel-header__icon {
  float: right;
}
.expand-header-calendar {
  width: 97%;
  margin-bottom: 0;
}
.borde-verde {
  border: 3px solid #5bc929;
  height: 100%;
  border-radius: 3px;
}
.borde-azul {
  border: 3px solid #2196f3;
  height: 100%;
  border-radius: 3px;
}
.borde-naranja {
  border: 3px solid #e78709;
  height: 100%;
  border-radius: 3px;
}
.borde-negro {
  border: 3px solid black;
  height: 100%;
  border-radius: 3px;
}
.borde-rojo {
  border: 3px solid red;
  height: 100%;
  border-radius: 3px;
}
.borde-amarillo {
  border: 3px solid yellow;
  height: 100%;
  border-radius: 3px;
}
.presente {
  color: #1976d2;
}
.importeTotal label {
  font-size: 20px;
}
.importeTotal .badge {
  font-size: 15px;
  height: 25px;
}
.kmTotal {
  margin-left: 5px;
}

@media only screen and (max-width: 1360px) {
  .importeTotal label {
    font-size: 12px;
  }
  .importeTotal .badge {
    font-size: 13px;
    height: 15px;
  }
  .selectoresCalendario {
    display: flex;
    max-width: 200px;
  }
}
.letrasNegras {
  color: black !important;
}
.selectoresCalendario {
  display: flex;
  max-width: 400px;
}
</style>
<style scoped>
@import '../../css/vuetify.css';

#popupSelectDate {
  width: 1179px;
}

.v-application .v-btn.btn-calendar {
  min-width: 10px;
  width: 10px;
}

.v-event-draggable {
  padding-left: 6px;
  height: 100%;
}

.v-event-summary {
  height: 100%;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;
}
.v-event-drag-bottom::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
}

.v-event-drag-bottom:hover::after {
    display: block;
}
</style>

