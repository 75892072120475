<template>
  <f7-row>
    <r-input
      class="Direccion"
      :class="classDireccion"
      floating-label
      :label="$t('componentes.direccion.direccion')"
      :form="form"
      type="text"
      :name="name + '.direccion'"
      :overrideReadonly="true"
      :readonly="form.readonly || readonly || readonlyField('direccion')"
    ></r-input>
    <r-input
      class="CP"
      :class="classCp"
      ref="codigopostal"
      floating-label
      :label="$t('componentes.direccion.codigo_postal')"
      :form="form"
      type="text"
      :name="name + '.cp'"
      :overrideReadonly="true"
      :readonly="form.readonly || readonly || readonlyField('codigo_postal')"
      @keyup.enter.native="onBlurCp"
      @blur="onBlurCp"
      @change="onChangeCp"
    ></r-input>
    <r-autocomplete
      :class="classPoblacion"
      ref="municipio"
      :dbAdapter="offline ? MunicipioOffline : Municipio"
      floating-label
      :label="$t('componentes.direccion.poblacion')"
      :form="form"
      :name="name + '.poblacion'"
      labelProp="poblacion"
      :idProp="'poblacion'"
      idFieldDetail="id"
      :overrideReadonly="true"
      :minLength="3"
      :showNavigationLink="false"
      :async="true"
      :openIn="offline ? 'popup' : 'dropdown'"
      :readonly="form.readonly || readonly || readonlyField('municipio') || calculatedReadOnlyMunicipio()"
      @change="changeMunicipio"
      :shownFields="['cps', 'codProvincia']"
      :defaultFilterSourceAsync="function() { return !!$refs.provincia.selected && !!$refs.provincia.selected.codigo ? {codProvincia: $refs.provincia.selected.codigo} : {}}"
    ></r-autocomplete>
    <r-autocomplete
      :class="classProvincia"
      ref="provincia"
      :dbAdapter="offline ? ProvinciaOffline : Provincia"
      floating-label
      :label="$t('componentes.direccion.provincia')"
      :form="form"
      :name="name + '.provincia'"
      labelProp="nombre"
      :idProp="guardarCodigos ? 'codigo' : 'nombre'"
      idFieldDetail="id"
      :overrideReadonly="true"
      :readonly="form.readonly || readonly || readonlyField('provincia') || calculatedReadOnlyProvincia()"
      :shownFields="['codigo','nombre']"
      @change="changeProvincia"
      :showItem="showItemProvincia"
      @loaded="loadedProvincia"
    ></r-autocomplete>
    <r-autocomplete
      :class="classComunidad"
      ref="comunidad"
      :dbAdapter="offline ? ComunidadOffline : Comunidad"
      floating-label
      :label="$t('componentes.direccion.comunidad')"
      :form="form"
      :name="name + '.comunidad'"
      labelProp="nombre"
      :idProp="guardarCodigos ? 'codigo' : 'nombre'"
      idFieldDetail="id"
      :overrideReadonly="true"
      :readonly="form.readonly || readonly || readonlyField('comunidad') || calculatedReadOnlyComunidad()"
      :shownFields="['codPais', 'codigo', 'provincias']"
      @change="changeComunidad"
    ></r-autocomplete>
    <r-autocomplete
      :class="classPais"
      ref="pais"
      :dbAdapter="offline ? PaisOffline : Pais"
      floating-label
      :label="$t('componentes.direccion.pais')"
      :form="form"
      :name="name + '.pais'"
      labelProp="nombre"
      :idProp="guardarCodigos ? 'codigo' : 'nombre'"
      idFieldDetail="id"
      :overrideReadonly="true"
      :readonly="form.readonly || readonly || readonlyField('pais') || calculatedReadOnlyPais()"
    ></r-autocomplete>
  </f7-row>

</template>
<style>
  div.list{
    list-style: none;
  }
  select[multiple] {
    height: 100px;
    margin-top: 10px;
  }
</style>
<script>
import rInput from "./rInput.vue";
import rAutocomplete from "./rAutocomplete.vue";
import Pais from "./../js/db/Pais.js";
import Provincia from "./../js/db/Provincia.js";
import Municipio from "./../js/db/Municipio.js";
import Comunidad from "./../js/db/Comunidad.js";
import PaisOffline from "./../js/db/PaisOffline.js";
import ProvinciaOffline from "./../js/db/ProvinciaOffline.js";
import MunicipioOffline from "./../js/db/MunicipioOffline.js";
import ComunidadOffline from "./../js/db/ComunidadOffline.js";
export default {
  name: "r-direccion",
  components: {
    rInput,
    rAutocomplete
  },
  data() {
    return {
      self: this,
      Pais,
      Provincia,
      Municipio,
      Comunidad,
      PaisOffline,
      ProvinciaOffline,
      MunicipioOffline,
      ComunidadOffline,
      history: [],
    };
  },
  methods: {
    loadedProvincia(data) {
      const self = this;
      if (self.form.formData[self.name] && self.form.formData[self.name].provincia) {
        self.changeProvincia();
      }
    },
    showItemProvincia(item) {
      const self = this;
      if (!!!item) {
        return true;
      }
      if(self.$refs.comunidad && self.$refs.comunidad.selected && self.$refs.comunidad.selected.provincias) {
        return self.$refs.comunidad.selected.provincias.indexOf(item.codigo) >= 0;
      } else {
        return true;
      }
    },
    showItemComunidad(item) {
      const self = this;
      if (!!!item) {
        return true;
      }
      if(self.$refs.pais && self.$refs.pais.selected && self.$refs.pais.selected.codigo) {
        return self.$refs.pais.selected.codigo == item.codPais;
      } else {
        return true;
      }
    },
    calculatedReadOnlyMunicipio() {
      const self = this;
      var returned = !!self.form && !!self.form.getValue(self.name + ".cp") && !!self.form.getValue(self.name + ".poblacion");
      return returned;
    },
    calculatedReadOnlyProvincia() {
      const self = this;
      var returned = !!self.form && !!self.form.getValue(self.name + ".poblacion") && !!self.form.getValue(self.name + ".provincia");
      return returned;
    },
    calculatedReadOnlyComunidad() {
      const self = this;
      var returned = !!self.form && !!self.form.getValue(self.name + ".provincia") && !!self.form.getValue(self.name + ".comunidad");
      return returned;
    },
    calculatedReadOnlyPais() {
      const self = this;
      var returned = !!self.form && !!self.form.getValue(self.name + ".comunidad") && !!self.form.getValue(self.name + ".pais");
      return returned;
    },
    readonlyField(field){
      const self = this;
      return self.readonlyFields.includes(field);
    },
    onBlurCp() {
      const self = this;
      var cp = self.$refs.codigopostal.getValue();
      if (!!cp) {
        self.$refs.municipio.setFilter({filter: {cp: cp}});
      }
    },
    onChangeCp() {
      const self = this;
      var cp = self.$refs.codigopostal.getValue();
      if (!!!cp) {
        self.$refs.municipio.setFilter(null);
      } else {
        self.$refs.municipio.setFilter({filter: {cp: cp}});
      }
    },
    changeComunidad() {
      const self = this;
      var comunidad = self.$refs.comunidad.selected;
      if (comunidad && comunidad.codPais) {
        self.$refs.pais.setFilter({filter: {codigo: comunidad.codPais}});
      } else {
        self.$refs.pais.setFilter(null);
      }
    },
    changeProvincia() {
      const self = this,
        provinciaComponent = self.$refs.provincia;
      var provincia = provinciaComponent.selected;
      var codProvincia = provincia ? provincia.codigo : null;
      if (!codProvincia && typeof provincia == "string") {
        const provincias = provinciaComponent.items,
          provinciasBuenas = provincias.filter(prov => prov.codigo == provincia);
        provincia = provinciasBuenas.shift();
        if(!provincia){
          return;
        }
      }
      self.setValue(provincia.codigo,self.name + '.provinciaCod');
      self.setValue(provincia.nombre,self.name + '.provinciaStr');
      codProvincia = provincia.codigo;

      if (codProvincia) {
        self.$refs.comunidad.setFilter({filter: {codProvincia: codProvincia}});
      } else {
        self.setValue(null,self.name + '.provinciaCod');
        self.setValue(null,self.name + '.provinciaStr');
        if (self.$refs.comunidad.getValue()) {
          self.$refs.comunidad.setFilter(null);
        }
      }
    },
    changeMunicipio() {
      const self = this;
      var municipio = self.$refs.municipio.selected;
      if (municipio && municipio.cps && municipio.cps.length && municipio.cps.length == 1 && !self.$refs.codigopostal.getValue()) {
        self.$refs.codigopostal.setValue(municipio.cps[0]);
      }
      if (municipio && municipio.codProvincia) {
        self.$refs.provincia.setFilter({filter: {codigo: municipio.codProvincia}});
      } else {
        self.$refs.provincia.setFilter(null);
      }
      
    },
    setValue: function(val, name = null) {
      var self = this;
      var namespaces = (name) ? name.split(".") : self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          self.$set(context, namespaces[i], val);
          self.selected = val;
          if (self.nameId || self.idProp) {
            var namespace = namespaces[i];
            var value = val;
            if (self.nameId) {
              namespace = self.nameId;
            }
            if (self.idProp) {
              value = val[self.idProp];
            }
            self.$set(context, namespace, value);
          }
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
          } else {
            self.$set(context, namespaces[i], {});
          }
        }
        context = context[namespaces[i]];
      }
    }
  },
  props: {
    name: {
      required: true,
      type: String
    },
    guardarCodigos: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    overrideReadonly: {
      type: Boolean,
      default: false
    },
    offline: {
      type: Boolean,
      default: false
    },
    form: {
      required: true
    },
    readonlyFields: {
      type: Array,
      default: function(){
        return [];
      }
    },
    classDireccion: {
      type: String,
      default: "col-100 medium-75"
    },
    classCp: {
      type: String,
      default: "col-100 medium-25"
    },
    classPoblacion: {
      type: String,
      default: "col-100 medium-25"
    },
    classProvincia: {
      type: String,
      default: "col-100 medium-25"
    },
    classComunidad: {
      type: String,
      default: "col-100 medium-25"
    },
    classPais: {
      type: String,
      default: "col-100 medium-25"
    }
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
  }
};
</script>