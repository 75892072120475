<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar title="Fichajes" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list 
        ref="rlist"
        :dbAdapter="Fichaje"
        :tableCode="'FICHAJE'"
        :tableMode="true"
        :virtualListParams="{ height: 32, rowsBefore: 60, rowsAfter: 60 }"
        class="card full-height data-table"
        classContent="card-content full-height"
        :massiveActions="false" 
        :controlsWidth="80"
        :shownFields="['eliminado']"
        @deleteItem="reload"
      >
        <template v-slot:optionsColumn="{ item, rlist }">
          <f7-link v-tooltip="$t('common.ver')" icon-f7="eye" color="green" :href="`/fichaje/view/${item.id}/`"></f7-link>
          <f7-link v-if="Utils.getUser() && Utils.getUser().username == item.username && !item.eliminado" v-tooltip="$t('common.editar')" icon-f7="square_pencil" color="green" :href="`/fichaje/edit/${item.id}/`"></f7-link>
          <f7-link v-if="Utils.getUser() && Utils.getUser().username == item.username && !item.eliminado" icon-f7="trash" color="red" @click="rlist.deleteItem(item.id, 'Si elimina un registro, se eliminará el rango completo de Entrada y Salida, ¿esta seguro?', false)"></f7-link>
        </template>
      </r-dynamic-list>
    </f7-page-content>

  </f7-page>
</template>
<style scoped>
.label-group {
  margin-top: 20px;
  margin-left: 20px;
  font-weight: bold;
}
</style>
<style>
.aurora .f7-icons,
.aurora .material-icons {
  font-size: 16px;
}
</style>
<script>
import rDynamicList from "../../components/rDynamicList.vue";
import Fichaje from "../../js/db/Fichaje.js";
import Utils from "../../js/Utils.js";
import { EventBus } from "../../js/EventBus";

export default {
  components: {
    rDynamicList,
  },
  data() {
    return {
      Fichaje,
      codigoFichaje: null,
      Utils,
    };
  },
  computed: {},
  methods: {
    filasSeleccionadas: function () {
      let filas = [...Object.values(this.$refs.rlist.selected)].map(selected => {
        return { id: selected.id };
      });
      return filas;
    },
    reload: function () {
      if (this.$refs.rlist) {
        this.$refs.rlist.reload();
      }
    },
  },
  mounted: function () {
    const self = this;
    EventBus.$on("fichajeGuardado", self.reload);
  }
};
</script>