<template>
  <div>
    <f7-list-input
      v-if="scrollPicker"
      :value="renderValue()"
      v-bind="{...$attrs,...$props}"
      autocomplete="off"
      :error-message-force="!!form.errors[name]"
      :error-message="form.errors[name]"
      :readonly="true"
      :class="{readonly:overrideReadonly ? readonly : readonly || form.readonly}"
      :clear-button="!(overrideReadonly ? readonly : readonly || form.readonly) && clearButton"
      @input:clear="setValue(null)"
    >
      <slot></slot>
      <slot name="media" slot="media"></slot>
      <slot name="inner-start" slot="inner-start"></slot>
      <slot name="root-end" slot="root-end"></slot>
    </f7-list-input>
    <li 
        v-else
        class="col-100 medium-50 has-tooltip">
      <div class="item-content item-input " :class="getClasses()">
        <div class="item-inner">
          <div class="item-title item-label item-floating-label">{{label}}</div>
          <div class="item-input-wrap">
            <input :readonly="overrideReadonly ? readonly : readonly || form.readonly" :class="{readonly: overrideReadonly ? readonly : (readonly || form.readonly)}" name="fechaVisita" type="text" autocomplete="no" v-mask="['##:##']" placeholder="HH:MM" @change="onChange($event.target.value ? $event.target.value.split(':') : null)" :value="renderValue()" v-bind="{...$attrs,...$props}">
            <span v-if="!(overrideReadonly ? readonly : readonly || form.readonly) && clearButton" class="input-clear-button" @click="setValue(null)"></span>
            <f7-link
              v-if="hasHistory && Utils.posterior2Dic2024(form.formData.fechaInsp)"
              class="input-suffix-btn r-input-little r-input-top history-link"
              :icon-f7="'archivebox'"
              v-tooltip="'Historico de valores'"
              @click="showHistory"
            ></f7-link>
          </div>
        </div>
      </div>
    </li>
    <span v-if="htmlError" v-tooltip="tooltipError" v-html="htmlError()" @click.prevent="mostrarError()" style="z-index: 100;"></span>
    
    <f7-popover class="history-popover" :closeByBackdropClick="false" :backdrop="true" closeOnEscape ref="popoverHistory" style="width: auto; max-width: 90%;">
      <div style="min-width: 250px;">
        <f7-navbar :title="$t('Historico de cambios')">
          <f7-nav-right>
            <f7-link popover-close icon-f7="multiply"></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <div class="scrollable-content">
            <table>
              <thead>
                <tr style="background-color: #22355b;color: #FFF;">
                  <th>Valor</th>
                  <th>Fecha</th>
                  <th>Usuario</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(itm, idx) in history" :key="'history-' + idx" :class="(idx%2?'fila-impar':'fila-par')">
                  <td class="datos-valor" :title="itm && itm.msgHistory?itm.msgHistory:''">{{itm.valor}}</td>
                  <td class="datos-auditoria">{{itm.date}}</td>
                  <td class="datos-auditoria">{{itm.username || ' - '}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </f7-block>
      </div>
    </f7-popover>

  </div>
</template>
<style scoped>
</style>
<style>
.readonly input,
.readonly select {
  pointer-events: none;
}
</style>
<script>
import DateUtils from "./../js/DateUtils";
import Utils from "./../js/Utils";
import EntityUtils from "./../js/EntityUtils";
import JSONPath from 'jsonpath';

export default {
  data() {
    return {
      self: this,
      picker: null,
      fechaBaseData: this.fechaBase,
      // invalidValue: false,
      history: [],
      DateUtils,
      EntityUtils,
      JSONPath,
      Utils
    };
  },
  computed: {
    hasHistory: function () {
      return this.form?.history && this.form?.history[this.name];
    },
  },
  methods: {
    mostrarError: function() {
      const self = this;
      var app = self.$f7;
      let expediente = self.form.formData;
      let htmlError = expediente.listErrorsForLabel.filter(e => e.valor == (self.altername || self.name))
                .flatMap(e => e.errores)
                .map(e => '<div class="regla regla-'+e.tipoRegla+'" ><span style="color: black;">'+e.nombre + '.- </span>' + e.error+'</div>').join(' ');
      let msg = '<div>' + htmlError + '</div>';
      app.dialog.alert(msg,'Errores del campo');
    },
    htmlError: function() {
      const self = this;
      let expediente = self.form.formData;
      let msgError;
      if(expediente.listErrorsForLabel && expediente.listErrorsForLabel.filter(e => e.valor == (self.altername || self.name)).length)
      {
        msgError = EntityUtils.formateoError(expediente, self.altername || self.name);
      }
      return msgError;
    },
    tooltipError: function() {
      const self = this;
      let expediente = self.form.formData;
      let msgError;
      if(expediente.listErrorsForLabel && expediente.listErrorsForLabel.filter(e => e.valor == (self.altername || self.name)).length)
      {
        msgError = EntityUtils.tooltipError(expediente, (self.altername ||self.name));
      }
      return msgError;
    },
    getClasses: function() {
      const self = this;
      return {
        'item-input-with-value': !!self.getValue(),
        'item-input-outline': self.$attrs["outline"] != undefined
      };
    },
    setFechaBase(fechaBaseChange) {
      const self = this;
      self.fechaBaseData = fechaBaseChange;
    },
    getValue: function() {
      const self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        context = context[namespaces[i]];
      }
      return context;
    },
    showHistory: function(event) {
      const self = this;
      self.history = self.form.history[self.name];
      // vamos a formatear los campos de fecha
      for (var i = 0; i < self.history.length; i++) {
        if(self.history[i].valor === undefined) {
          self.history[i].valor = '';
        }
        if(self.history[i].valorAnterior === undefined) {
          self.history[i].valorAnterior = '';
        }

        self.history[i].valor = DateUtils.isDate(self.history[i].valor)?self.history[i].valor:DateUtils.dateFormat(self.history[i].valor, 'HH:mm');
        self.history[i].valorAnterior = DateUtils.isDate(self.history[i].valorAnterior)?self.history[i].valorAnterior:DateUtils.dateFormat(self.history[i].valorAnterior, 'HH:mm');

        self.history[i].date = DateUtils.dateFormat(self.history[i].date, 'DD/MM/YYYY HH:mm:ss');
        self.history[i].username = self.history[i].username || '-';
      }
      if(event.target) self.$refs.popoverHistory.open(event.target);
    },
    renderValue: function() {
      var self = this;
      var context = self.getValue();
      if (context) {
        if (typeof context == "string") context = new Date(context);
        let value = [
          ("0" + context.getHours()).slice(-2),
          ("0" + context.getMinutes()).slice(-2)
        ];
        if (
          self.picker &&
          JSON.stringify(self.picker.getValue()) != JSON.stringify(value)
        ) {
          self.picker.setValue(value);
        }
        return value.join(":");
      } else {
        return null;
      }
    },
    setValue: function(val, a) {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          self.$set(context, namespaces[i], val);
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
          } else {
            self.$set(context, namespaces[i], {});
          }
        }
        context = context[namespaces[i]];
      }
    },
    onChange: function(value) {
      var self = this;
      let v = self.getValue();
      if (!!!value) {
        self.setValue(null);
        if (!self.scrollPicker) {
          self.$emit('change', value);
        }
        return;
      }
      if (v) {
        if (typeof v == "string") v = new Date(v);
      } else {
        v = self.fechaBaseData;
      }
      v.setHours(value[0]);
      v.setMinutes(value[1]);
      v = new Date(v);
      self.setValue(v, "calendar");
      if (!self.scrollPicker) {
        self.$emit('change', value.join(":"));
      }
    }
  },
  props: {
    fechaBase: {
      type: Date,
      default: function() {
        return new Date();
      }
    },
    name: {
      required: true,
      type: String
    },
    altername: {
      required: false,
      type: String,
      default: ''
    },
    form: {
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    overrideReadonly: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text"
    },
    label: {
      type: String,
      default: ""
    },
    clearButton: {
      type: Boolean,
      default: false
    },
    scrollPicker: {
      type: Boolean,
      default: false
    },
    possibleHours: {
      type: String,
      default: function() {
        return Array.from({ length: 24 }, (_, i) => ("0" + i).slice(-2)).join(" ");
      }
    },
    possibleMinutes: {
      type: String,
      default: function() {
        return Array.from({ length: 60 }, (_, i) => ("0" + i).slice(-2)).join(" ");
      }
    }
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
    var app = self.$f7;

    if(self.scrollPicker){
      self.picker = app.picker.create({
        inputEl: self.$$(self.$el).find("input")[0],
        rotateEffect: true,
        routableModals: false,
        toolbarCloseText: "Cerrar",
        cols: [
          {
            values: self.possibleHours.split(" ")
          },
          {
            divider: true,
            content: ":"
          },
          {
            values: self.possibleMinutes.split(" ")
          }
        ],
        on: {
          change: function(picker, value, displayValue) {
            self.onChange(value);
          }
        }
      });
    }
  }
};
</script>