import DatabaseModel from './DatabaseModel.js';

var Fichaje = Object.create(DatabaseModel);

Fichaje.model = 'fichaje';
Fichaje.modelFront = 'fichajes';

Fichaje.saveRango = function (params) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/rango/',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function (res) {
        return res;
    });
};

Fichaje.findLast = function() {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_movilidad + self.prefix + self.model + '/findLast/',
            method: 'GET',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else if(data.dto) {
                resolve(data.dto);
            } else {
                resolve(undefined);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                var obj = JSON.parse(xhr.response);
                reject(obj.message ? obj.message : obj.error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
};

export default Fichaje;